.found {

  &__content {
    display: flex;
    padding-left: calc((100% - 1168px) / 2);

    @media (max-width: 750px) {
      padding-left: 0;
      flex-direction: column;
      align-items: center;
    }

    img {
      width: calc(100% - clamp(20rem, calc(9.234rem + 22.97vw), 26rem));
      object-fit: cover;

        @media (max-width: 750px) {
          margin-top: 30px;
          min-width: 762px;
          width: 100%;
          object-position: center;
        }
    }
  }

  &__info {
    padding-left: 15px;
    width: clamp(20rem, calc(9.234rem + 22.97vw), 26rem);
    flex-shrink: 0;
    margin-top: 28px;

      @media (max-width: 750px) {
        width: 100%;
        padding: 0 15px;
      }

    h1 {
      margin-top: 20px;
      font-weight: 700;
      font-size: 32px;
      line-height: 104%;
      color: $darkGreen;
    }

    p {
      margin-top: 20px;
      max-width: 246px;
      font-weight: 500;
      font-size: 14px;
      line-height: 104%;
      color: $black;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

      li {
        &:not(:last-child) {
          a {
            &::after {
              content: '-';
              display: block;
            }
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        gap: 5px;

        font-weight: 400;
        font-size: 12px;
        line-height: 104%;
        color: #969fa6;
      }
  }

  &__content-b {
    margin-top: 30px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;

    @media (max-width: 1000px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 850px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 550px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

}
