.cart {
  position: relative;

  &__add-button {
    position: absolute;
    right: 0;
    top: 37%;
    background: url('../img/basket.png') no-repeat;
    background-size: cover;
    min-width: 122px;
    min-height: 178px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;

      @media (max-width: 1000px) {
        display: none;
      }

      h6 {
        font-weight: 500;
        font-size: 20px;
        line-height: 104%;
        color: #fff;
      }

      span {
        margin-top: 10px;
        font-weight: 500;
        font-size: 43px;
        line-height: 60%;
        color: #fff;
      }
  }


  // main

  &__href {
    margin-top: 28px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

     > li {
        position: relative;
        display: flex;
        align-items: center;
        gap: 5px;

        font-weight: 400;
        font-size: 12px;
        line-height: 104%;
        color: #969fa6;

      > a {
          font-weight: 400;
          font-size: 12px;
          line-height: 104%;
          color: #969fa6;
        }

        &:not(:last-child) {
          &::after {
            content: '-';
            display: block;
          }
        }

        &:not(:last-child, :first-child) {
         > a {
            display: flex;
            align-items: center;
            gap: 5px;
            &::after {
              content: '';
              display: block;
              background: url('../img/svg/arr.svg') no-repeat;
              background-size: contain;
              width: 11px;
              height: 6px;
              transform: rotate(-90deg);

              transition: all .3s ease-in-out;
            }
          }

          &:hover {
              > a {
                &::after {
                  transform: rotate(0);
                }
              }

                .cart__menu {
                  opacity: 1;
                  visibility: visible;
                  z-index: 3;
                  transform: translateX(-50%) translateY(0);
                }
          }

        }
      }

  }

  &__menu {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%) translateY(30px);
    transition: all .3s ease-in-out;

    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: $tagColor;
    padding: 10px 20px;
    min-width: 150px;
    width: 100%;
    border-radius: 10px;

    opacity: 0;
    visibility: hidden;

      li {
        width: 100%;
      }

      a {
        display: block;
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        line-height: 104%;
        color: $tagText;
      }
  }

  &__top {
    margin-top: 20px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    h1 {
      font-weight: 500;
      font-size: clamp(1.5rem, calc(1.406rem + 0.47vw), 1.75rem);
      line-height: 104%;
      color: $allegianceColor;
      max-width: 685px;
    }
  }

  &__favorite {
    display: flex;
    align-items: center;
    gap: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 104%;
    color: #9f9ea3;

      svg {
        fill: #9F9EA3;
      }

      @media (max-width: 750px) {
        display: none;
      }
  }

  &__top-rating {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 10px;

      a {
        &:nth-child(2) {
          font-weight: 500;
          font-size: 12px;
          line-height: 104%;
          color: #c0c0c0;
        }

        &:nth-child(3) {
          display: flex;
          align-items: center;
          gap: 5px;
          font-weight: 500;
          font-size: 12px;
          line-height: 104%;
          color: #009afc;
        }
      }
  }

  &__top-star {
    display: flex;
    align-items: center;
    gap: 4px;

    font-weight: 500;
    font-size: 12px;
    line-height: 104%;
    color: #525252;
  }

  &__main {
    margin-top: clamp(0.875rem, calc(0.45rem + 2.12vw), 2rem);
    display: flex;

    > img {
      max-width: clamp(21.063rem, calc(-6.174rem + 45.87vw), 27.313rem);
      width: 100%;
      height: 400px;
      object-fit: cover;

      @media (max-width: 950px) {
        max-width: 460px;
        margin: 0 auto;
      }
    }

    @media (max-width: 950px) {
      flex-direction: column;
    }
  }

  &__characteristics-block {
    position: relative;
    z-index: 2;
    margin-left: clamp(0.625rem, calc(-4.822rem + 9.17vw), 1.875rem);
    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 104%;
      color: $black;
    }

      @media (max-width: 950px) {
        display: none;
      }
  }

  &__characteristics {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    display: flex;
    gap: 5px;
    align-items: center;

    font-weight: 400;
    font-size: 16px;
    line-height: 98%;
    color: $dd;

      dd {
        margin: 0;
      }
  }

  &__manufacturer {
    margin-top: 20px;
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 104%;
      color: $black;
    }

    a {
      margin-top: 10px;
      display: block;
      width: fit-content;
    }
  }

  &__info-product {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transform: translate(clamp(0rem, calc(-19.065rem + 32.11vw), 4.375rem), -50px);

    @media (max-width: 950px) {
      height: 466px;
      transform: translate(0, -30px);
    }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        background: url('../img/dec-card.png') no-repeat;
        background-size: cover;
        width: 453px;
        height: 526px;
        z-index: -1;
      }

  }

  &__main-info {
    transform: translate(10px, 40px);
    width: clamp(17.5rem, calc(10.419rem + 11.93vw), 19.125rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

      > p {
        margin-top: 20px;
        font-weight: 700;
        font-size: 16px;
        line-height: 104%;
        color: #636363;
        text-align: center;
      }
  }

  &__info-p, &__weight, &__basket, &__balls {
    width: 100%;
  }

  &__info-p {
    h3 {
      font-weight: 400;
      font-size: 16px;
      line-height: 104%;
      color: #363636;
    }
    p {
      margin-top: 5px;
      font-weight: 700;
      font-size: 45px;
      line-height: 104%;
      color: #363636;

      span {
        &:nth-child(1) {
          font-weight: 400;
        }

        &:nth-child(2) {
          font-weight: 400;
          font-size: 32px;
          text-decoration: line-through;
          color: #006a61;
        }
      }
    }
  }

  &__weight {
    margin-top: 18px;
    h5 {
      font-weight: 400;
      font-size: 16px;
      line-height: 104%;
      color: #363636;
    }
  }

  &__buttons-top {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;

      button {
        border: 1px solid #525252;
        border-radius: 10px;
        max-width: 60px;
        width: 100%;
        height: 35px;
        background-color: #fff;

        font-weight: 500;
        font-size: 16px;
        line-height: 104%;
        color: #525252;
      }
  }

  &__basket {
    user-select: none;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 14px;

    padding: 10px;
    background-color: #00857b;
    font-weight: 700;
    font-size: 20px;
    line-height: 104%;
    color: #fff;
    border-radius: 10px;

    transition: all .3s ease-in-out;

      &:hover {
        background-color: #109a8e;
      }
  }

  &__counter {
    border: 1px solid #525252;
    border-radius: 10px;
    background-color: #fff;

    display: flex;
    align-items: center;
    max-width: 85px;
    width: 100%;
    height: 43px;

    justify-content: center;
    align-items: center;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 104%;
      color: #525252;
      min-width: 17px;
      text-align: center;
    }

    button {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 24px;
      line-height: 104%;
      color: #00857b;
    }
  }

  &__balls {
    margin-top: 10px;
    position: relative;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    border-radius: 10px 0;
    background-color: #636363;

    font-weight: 500;
    font-size: 12px;
    line-height: 104%;
    color: #fff;

      button {
        &:hover {
          + .cart__ball-die {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
          }
        }
      }
  }

  &__ball-die {
    width: 200px;
    position: absolute;
    left: 0;
    bottom: 30px;
    z-index: 3;

    font-weight: 400;
    font-size: 14px;
    line-height: 104%;
    color: #012321;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 10px;
    transition: all .3s ease-in-out;

    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
  }

  // descriptions

  &__description {
    margin-top: clamp(1.875rem, calc(1.38rem + 2.48vw), 3.188rem);
    h2 {
      font-weight: 700;
      font-size: clamp(1.5rem, calc(1.311rem + 0.94vw), 2rem);
      line-height: 104%;
      color: $darkGreen;
    }

    p {
      margin-top: clamp(1.25rem, calc(1.014rem + 1.18vw), 1.875rem);
      max-width: 971px;
      font-weight: 500;
      font-size: 18px;
      line-height: 104%;
      color: $allegianceColor;
    }
    button {
      display: none;
      margin-top: 10px;
      font-size: 18px;
      line-height: 104%;
      font-weight: 700;
      text-decoration: underline;
      text-decoration-skip-ink: none;
      color: #006a61;

        @media (max-width: 850px) {
          display: block;
        }
    }
  }

  // specifications

  &__specifications {
    margin-top: clamp(1.875rem, calc(1.403rem + 2.36vw), 3.125rem);

    h2 {
      font-weight: 700;
      font-size: clamp(1.5rem, calc(1.311rem + 0.94vw), 2rem);
      line-height: 104%;
      color: $darkGreen;
    }

  }

  &__specifications-block {
    margin-top: clamp(1.25rem, calc(1.014rem + 1.18vw), 1.875rem);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 11px;

    @media (max-width: 850px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
  }

  &__specifications-info {
    padding: 30px 20px 19px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: $backCard;
      transform: skewX(2deg);
      border-radius: 20px;

      @media (max-width: 850px) {
        transform: skewX(5deg);
      }
    }

      dl {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
  }

  &__specifications-item {
    display: grid;
    grid-template-columns: 0.5fr 1fr;

      dt {
        font-weight: 400;
        font-size: 16px;
        line-height: 98%;
        color: $black;
      }

      dd {
        font-weight: 400;
        font-size: 16px;
        line-height: 98%;
        color: $colorCart;
      }
  }

  // reviews

  &__reviews {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 2.4fr 1fr;
    gap: 27px;

    @media (max-width: 850px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__title-reviews {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: 850px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    h2 {
      display: flex;
      align-items: center;
      gap: 3px;

      font-weight: 700;
      font-size: clamp(1.5rem, calc(1.311rem + 0.94vw), 2rem);
      line-height: 104%;
      color: $darkGreen;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 104%;
          color: #525252;
        }
    }

    button {
      width: fit-content;
      padding: 17px 37px;
      border-radius: 10px;
      background-color: #006a61;
      font-weight: 500;
      font-size: 15px;
      line-height: 104%;
      text-align: center;
      color: #fff;

      transition: all .3s ease-in-out;

      &:hover {
        background-color: #109a8e;
      }
    }
  }

  &__reviews-block {
    margin-top: clamp(1rem, calc(0.599rem + 2vw), 2.063rem);
  }

  &__reviews-comments {
    margin-top: clamp(1.5rem, calc(0.887rem + 3.07vw), 3.125rem);
  }

  &__reviews-comment {
    position: relative;
    p {
      margin-top: 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 104%;
      color: $colorReviews;
      max-width: 720px;
    }

    &:not(:last-child) {
      padding-bottom: 20px;
    }

    &:not(:first-child) {
      padding-top: 20px;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        max-width: 540px;
        width: 100%;
        height: 1px;
        background-color: #9f9ea3;
      }

      @media (max-width: 850px) {
        &::after {
          max-width: 100%;
        }
      }
    }
  }

  &__user {
    h6 {
      font-weight: 500;
      font-size: 16px;
      line-height: 104%;
      color: $black;
    }
  }

  // right

  &__right-add {
    margin-top: clamp(0rem, calc(-10.859rem + 20.44vw), 4.063rem);
  }

  &__ratings {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 14px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 540px;
      width: 100%;
      height: 1px;
      background-color: #9f9ea3;
    }

    @media (max-width: 850px) {
      padding: 31px 0 20px;
      &::after {
        max-width: 100%;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        width: 100%;
        height: 1px;
        background-color: #9f9ea3;
      }
    }
  }

  &__rating-product {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__ratings {
    font-weight: 700;
    font-size: 22px;
    line-height: 104%;
    color: #000;
  }

  &__grades {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__grade {
    display: grid;
    grid-template-columns: 54px auto;
    align-items: center;
    gap: 10px;

    font-weight: 500;
    font-size: 13px;
    line-height: 104%;
    color: $colorAdd;

    &:nth-child(1) {
      .cart__grade-line {
        span {
          width: 36%;
        }
      }
    }

    &:nth-child(2) {
      .cart__grade-line {
        span {
          width: 25%;
        }
      }
    }

    &:nth-child(3) {
      .cart__grade-line {
        span {
          width: 8%;
        }
      }
    }
  }

  &__grade-line {
    position: relative;
    max-width: 228px;
    width: 100%;
    height: 7px;
    background-color: $line;
    border-radius: 100px;
    overflow: hidden;

      span {
        position: absolute;
        width: 0;
        height: 100%;
        background-color: #006a61;
        border-radius: 100px;
      }
  }

  // > append

  &__review-append {
    margin-top: clamp(1.938rem, calc(1.702rem + 1.18vw), 2.563rem);

    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 104%;
      color: $darkGreen;
    }

    form {
      margin-top: 21px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: 850px) {
        gap: 30px;
      }

      max-width: 300px;
      width: 100%;

      label {
        max-width: 300px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 104%;
          color: $darkGreen;
        }

        input {
          border-radius: 10px;
          padding: 12px;
          border: 1px solid #9f9ea3;
          background-color: $white;
          width: 100%;

          font-weight: 400;
          font-size: 14px;
          line-height: 104%;
          color: $black;
        }

        textarea {
          resize: none;
          width: 100%;
          height: 82px;
          border-radius: 10px;
          padding: 12px;
          border: 1px solid #9f9ea3;
          background-color: $white;

          font-weight: 400;
          font-size: 14px;
          line-height: 104%;
          color: $black;
        }

      }

      button {
        border-radius: 10px;
        background-color: #00746a;
        font-weight: 500;
        font-size: 15px;
        line-height: 104%;
        text-align: center;
        color: #fff;
        padding: 13px 21px;
        width: fit-content;

        transition: all .3s ease-in-out;

          &:hover {
            background-color: #109a8e;
          }
      }

    }
  }

  &__review-append-rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 104%;
      color: $darkGreen;
    }
  }

  &__review-append-stars {
    display: flex;
    align-items: center;

    svg {
      cursor: pointer;
      fill: #d8d8d8;
      transition: all .2s ease-in-out;
      width: fit-content;

        &:not(:last-child) {
          padding-right: 10px;
        }
    }

  }

  // similar

  &__similar {
    margin-top: clamp(1.813rem, calc(1.081rem + 3.66vw), 3.75rem);
    h2 {
      font-weight: 700;
      font-size: clamp(1.5rem, calc(1.311rem + 0.94vw), 2rem);
      line-height: 104%;
      color: $darkGreen;
    }
  }

  &__similar-cards {
    margin-top: clamp(1.25rem, calc(1.014rem + 1.18vw), 1.875rem);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    @media (max-width: 1000px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 850px) {
      grid-template-columns: repeat(2, 1fr);

          .card-product {
            &:not(:nth-child(-n+2)) {
              display: none;
            }
          }

    }

    @media (max-width: 550px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  // active classes

  .highlighted {
    fill: #006a61;
  }

  .activeFavirite {
    color: red;

      svg {
        fill: red;
      }
  }

  .activeWeight {
    border: 1px solid #ff7306;
    color: #ff7306;
  }

}
