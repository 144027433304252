.placement {
  margin-top: 50px;

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 104%;
    color: $titlePlacement;
  }

  &__main-content {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1.9fr 1fr;
    gap: clamp(1.25rem, calc(-7.771rem + 16.98vw), 4.625rem);

      @media (max-width: 850px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
      }
  }

  &__vhod-block {
    position: relative;
    height: 213px;

    @media (max-width: 850px) {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -35px;
      background: url('../img/vhod.png') no-repeat;
      background-size: cover;
      width: 792px;
      height: 213px;
      z-index: -1;

        @media (max-width: 850px) {
          background-position: center;
          max-width: 792px;
          left: inherit;
        }
    }

  }

  &__mob {
    display: none;

    @media (max-width: 850px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__vhod {
    display: flex;
    flex-direction: column;
    gap: 17px;

    height: 100%;
    width: fit-content;
    padding: 47px 0 0 30px;

    @media (max-width: 850px) {
     padding: 47px 0 0;
    }

    h2 {
      font-weight: 700;
      font-size: clamp(1.375rem, calc(1.139rem + 1.18vw), 2rem);
      line-height: 104%;
      color: #fff;
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    gap: clamp(0.313rem, calc(-0.277rem + 2.95vw), 1.875rem);

    a {
      display: flex;
      align-items: center;
      gap: 3px;

      padding: 10px clamp(1.063rem, calc(0.355rem + 3.54vw), 2.938rem);
      border-radius: 100px;
      background: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 104%;
      text-align: center;
      color: #000;
    }

    p {
      max-width: 412px;
      font-weight: 400;
      font-size: clamp(0.813rem, calc(0.742rem + 0.35vw), 1rem);
      line-height: 104%;
      color: #fff;
    }
  }

  &__left {
    @media (max-width: 850px) {
      order: 2;
    }
  }


  &__form {
    margin-top: 53px;

    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 104%;
      color: $black;
    }

    h3 {
      margin-top: 30px;
      font-weight: 700;
      font-size: 20px;
      line-height: 104%;
      color: $allegianceColor;
    }

  }

  &__inps-top {
    margin-top: 30px;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
    'one one two two three three'
    'four four four five five five'
    'six six six six six six'
    ;
    gap: 20px;

    @media (max-width: 850px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
      'one one'
      'two two'
      'three three'
      'four five'
      'six six'
      ;
    }

    input {
      display: block;
      border: 1px solid $activePanel;
      border-radius: 5px;
      background: $filterBack;

      font-weight: 400;
      font-size: 14px;
      line-height: 104%;
      color: $logoText;
      padding: 10px 20px;
      width: 100%;

        &:nth-child(1) {
          grid-area: one;
        }

        &:nth-child(2) {
          grid-area: two;
        }

        &:nth-child(3) {
          grid-area: three;
        }

        &:nth-child(4) {
          grid-area: four;
        }

        &:nth-child(5) {
          grid-area: five;
        }


        &::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 104%;
          color: $logoText;
        }
    }

    textarea {
      grid-area: six;
      resize: none;
      display: block;
      height: 88px;
      border: 1px solid $activePanel;
      border-radius: 5px;
      background: $filterBack;

      font-weight: 400;
      font-size: 14px;
      line-height: 104%;
      color: $logoText;
      padding: 10px 20px;
      width: 100%;

        &::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 104%;
          color: $logoText;
        }
    }
  }

  &__inps-bottom {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    input {
      display: block;
      border: 1px solid $activePanel;
      border-radius: 5px;
      background: $filterBack;

      font-weight: 400;
      font-size: 14px;
      line-height: 104%;
      color: $logoText;
      padding: 10px 20px;
      width: 100%;

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 104%;
        color: $logoText;
      }
    }

  }

  &__buttons {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 43px;

    @media (max-width: 850px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__pay, &__delivery {

    h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 104%;
      color: $allegianceColor;
    }

  }

  &__buttons-click {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
      border: 1px solid $activePanel;
      border-radius: 5px;
      background: $filterBack;
      color: $logoText;
      text-align: start;
      font-weight: 500;
      font-size: 16px;
      line-height: 104%;
      height: 80px;
      width: 100%;

      display: flex;
      align-items: center;
      gap: 30px;
      padding: 20px;

      @media (max-width: 850px) {
        max-width: 100%;
      }

        img {
          &:nth-child(2) {
            display: none;
          }
        }
    }
  }

  &__right {
    margin-top: 12px;
    padding: 20px 24px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    background: $orderPlacement;
    border-radius: 10px;
    height: fit-content;

    button {
      font-weight: 700;
      font-size: 16px;
      line-height: 104%;
      color: #fff;
      padding: 16px 0;
      width: 100%;
      background-color: #006a61;
      border-radius: 10px;

      transition: all .3s ease-in-out;

        &:hover {
          background-color: #109a8e;
        }
    }

    p {
      margin-top: 15px;
      font-weight: 400;
      font-size: 12px;
      line-height: 104%;
      color: $black;
      max-width: 243px;
    }
  }

  &__unavailable {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;

    font-weight: 400;
    font-size: 14px;
    line-height: 104%;
    color: $black;

    border-radius: 10px;
    background-color: $backPlacement;
    padding: 7px 20px;

      svg {
        fill: $svgPlacement;
      }
  }

  &__basket {
    position: relative;
    margin-top: 30px;
    padding-top: 30px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $darkGreen;
    }
  }

  &__top-basket {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    h5 {
      font-weight: 700;
      font-size: 18px;
      line-height: 104%;
      color: $black;
    }

    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 104%;
      color: $black;
    }
  }

  &__product {
    padding-top: 10px;
  }

  &__product, &__discoint {
    display: flex;
    align-items: center;
    justify-content: space-between;

      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 104%;
        color: $black;
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 104%;
        color: $black;
      }
  }

  &__discoint {
    margin-top: 5px;
      p {
        color: #f30c01;
      }
  }

  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
    margin-top: 20px;
    padding-top: 20px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $darkGreen;
    }

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 104%;
      color: $black;
    }

    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 104%;
      color: $black;
    }
  }

  .activeButtonPlacement {
    border: 1px solid #ff7306;
  }

}
