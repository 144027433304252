.home {
  margin-top: 35px;

    &__advertisement {
      max-width: 1168px;
      width: 100%;
      margin: 0 auto;
      padding: clamp(0rem, calc(-0.943rem + 4.72vw), 2.5rem);
      display: flex;
      flex-direction: column;
      padding-top: 50px;

      background: url('../img/home_dec.png') no-repeat;
      background-position: 60% center;
      background-size: cover;
      width: 100%;
      height: 533px;

      img {
        width: clamp(14.5rem, calc(12.165rem + 11.67vw), 20.688rem);
      }

      h1 {
        margin-top: 40px;
        font-weight: 500;
        font-size: clamp(1.938rem, calc(1.395rem + 2.71vw), 3.375rem);
        line-height: 104%;
        color: #22303e;
      }

      p {
        margin-top: 12px;
        font-weight: 500;
        font-size: clamp(1.313rem, calc(0.912rem + 2vw), 2.375rem);
        line-height: 104%;
        color: #22303e;
      }

    }

    &__action {
      margin-top: clamp(2.563rem, calc(2.209rem + 1.77vw), 3.5rem);
      h2 {
        font-weight: 700;
        font-size: clamp(1.5rem, calc(1.288rem + 1.06vw), 2.063rem);
        line-height: 104%;
        color: $darkGreen;
      }
    }

    &__cards {
      margin-top: 25px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 15px;

        @media (max-width: 1000px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 530px) {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
        }
    }

    &__cosmetics {
      position: relative;
      width: clamp(15rem, calc(13.774rem + 6.13vw), 18.25rem);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (max-width: 1020px) {
        right: 0;
        margin: 0 auto;
      }

      @media (max-width: 530px) {
        height: 390px;
      }

      &::after {
        content: '';
        position: absolute;
        top: -25px;
        right: 10px;
        background: url('../img/cosmeticsBack.png') no-repeat;
        background-size: cover;
        width: 100%;
        height: clamp(24.25rem, calc(22.599rem + 8.25vw), 28.625rem);
        z-index: -1;

          @media (max-width: 1020px) {
            right: 0;
          }

          @media (max-width: 540px) {
            top: 0;
          }
      }

      h4 {
        max-width: 166px;
        font-weight: 500;
        font-size: 27px;
        line-height: 104%;
        color: #fff;
      }
    }

    &__info-cos {
      padding-left: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (max-width: 540px) {
        padding-left: 50px;
      }

        img {
          width: clamp(5.625rem, calc(4.496rem + 3.34vw), 6.938rem);
        }
    }

}

.card-product {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: clamp(13.938rem, calc(-3.548rem + 27.98vw), 16.875rem);
  border: 2px solid #dde9e8;
  border-radius: 5px;
  background-color: $white;

  @media (max-width: 1000px) {
    width: 100%;
  }

  img {
    height: 172px;
    display: block;
    object-fit: cover;
  }

  &__info {
    width: 100%;
    padding: 11px clamp(0.75rem, calc(0.679rem + 0.35vw), 0.938rem);
    margin-top: -15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__rating {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 5px;
    width: 41px;
    height: 17px;

    font-weight: 500;
    font-size: 12px;
    line-height: 104%;
    color: #525252;
    background-color: #f5f5f5;
  }

  &__top {

    h3 {
      margin-top: 10px;

      a {
        display: block;
        width: fit-content;
        font-weight: 500;
        font-size: 16px;
        line-height: 104%;
        color: $darkBlue;
      }
    }

  }

  &__bottom {
    margin-top: auto;
    p {
      margin-top: 10px;
      font-weight: 400;
      font-size: 10px;
      line-height: 104%;
      color: #012321;
    }
  }

  &__buttons {
    margin-top: 9px;
    display: flex;
    align-items: center;
    gap: 5px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 5px;
      max-width: 53px;
      width: 100%;
      height: 17px;
      font-weight: 500;
      font-size: 10px;
      line-height: 104%;
      color: #fff;
      background-color: #c0c0c0;
    }
  }

  &__price-block {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 5px;
    span {
      &:nth-child(1) {
        font-weight: 500;
        font-size: 18px;
        line-height: 104%;
        color: #fff;
        background: #006a61;
        border-radius: 5px;
        padding: 4px 10px;
      }
      &:nth-child(2) {
        font-weight: 500;
        font-size: 18px;
        line-height: 104%;
        text-decoration: line-through;
        color: #c0c0c0;
      }
    }
  }

  &__ball {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    span {
      font-weight: 500;
      font-size: 10px;
      line-height: 104%;
      text-align: right;
      color: #c0c0c0;
    }

    svg {
      cursor: pointer;
    }
  }

  &__hint {
    width: 200px;
    position: absolute;
    left: -140px;
    bottom: 20px;
    z-index: 3;

    font-weight: 400;
    font-size: 14px;
    line-height: 104%;
    color: #012321;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 10px;
    transition: all .3s ease-in-out;

    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
  }

  &__svg {
    &:hover + .card-product__hint {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__basket {
    margin-top: 15px;
    background-color: #006a61;
    border-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 104%;
    text-align: center;
    color: #fff;
    padding: 12px 0;
    width: 100%;

    transition: all .3s ease-in-out;

      &:hover {
        background-color: #109a8e;
      }
  }

  &__discount {
    position: absolute;
    top: 0;
    left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 5px 5px;
    min-width: 41px;
    height: 17px;
    background-color: #f30c01;
    font-weight: 500;
    font-size: 12px;
    line-height: 104%;
    color: #fff;
  }

  &__discount-t {
    padding: 4px 10px;
    position: absolute;
    top: 0;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0 0 5px 5px;
    height: 21px;
    background-color: #f30c01;
    font-weight: 500;
    font-size: 12px;
    line-height: 104%;
    color: #fff;
  }

  &__suitability {
    padding: 4px 10px;
    position: absolute;
    top: 0;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0 0 5px 5px;
    height: 21px;
    background-color: #fe8b2c;
    font-weight: 500;
    font-size: 12px;
    line-height: 104%;
    color: #fff;
  }

  &__stock {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    border-radius: 0 0 15px 15px;
    width: calc(100% - 30px);
    height: 34px;
    background-color: #808080;
    font-weight: 500;
    font-size: 16px;
    line-height: 104%;
    color: #fff;
  }

}

.card-product .activeCardButton {
  background-color: #fe8b2c;
}
.nostock  {
  .activeCardButton {
    border: 1px solid #ff7306;
    background-color: $white;
    color: $darkBlue;
  }

  .card-product__basket {
    background-color: #808080;
  }

}
