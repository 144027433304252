.exclusives {
  margin-top: clamp(2.5rem, calc(2.217rem + 1.42vw), 3.25rem);

  h2 {
    font-weight: 700;
    font-size: clamp(1.5rem, calc(1.288rem + 1.06vw), 2.063rem);
    line-height: 104%;
    color: $darkGreen;
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;

      button {
        background: $white;
        padding: 11px clamp(1rem, calc(0.882rem + 0.59vw), 1.313rem);
        border-radius: 10px;

        font-weight: 500;
        font-size: clamp(0.813rem, calc(0.695rem + 0.59vw), 1.125rem);
        line-height: 104%;
        color: $black;
        background: $whiteButton;
      }
  }

  &__cards {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;

      @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 530px) {
        grid-template-columns: repeat(1, 1fr);
      }
  }

  .activeButtons {
    background: $lightWhiteButton;
  }

  // two


  &__discount {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 70px 10px 70px clamp(0.625rem, calc(-3.217rem + 7.23vw), 2.063rem);
    background: url('../img/exclusives__discount.png') no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 395px;

    @media (max-width: 1168px) {
      width: calc(100% + 30px);
      margin-left: -15px;
    }
  }

  &__left {
    p {
      font-weight: 500;
      font-size: 15px;
      line-height: 104%;
      color: #fff;
    }
    h4 {
      margin-top: 10px;
      font-weight: 700;
      font-size: clamp(2rem, calc(1.434rem + 2.83vw), 2.938rem);
      line-height: 104%;
      text-transform: uppercase;
      color: #fff;
      max-width: 400px;
    }

    a {
      margin-top: 31px;
      display: block;
      width: fit-content;
      padding: 17px 36px;
      font-weight: 700;
      font-size: 15px;
      line-height: 104%;
      text-align: center;
      color: #0d736b;
      border-radius: 15px;
      background-color: #ebf6fe;

      transition: background-color .3s ease-in-out;

        &:hover {
          background-color: #f0eeee;
        }
    }
  }

  &__right {
    font-weight: 400;
    font-size: 22px;
    line-height: 104%;
    color: #fff;
    max-width: 407px;

    @media (max-width: 850px) {
      display: none;
    }
  }

  // three

  &__survey {
    margin-top: clamp(1.188rem, calc(0.999rem + 0.94vw), 1.688rem);
    padding: 50px clamp(0.625rem, calc(-4.387rem + 9.43vw), 2.5rem);
    background: #fafafb;
    border-radius: 40px;
  }

  &__top-survey {
    display: flex;
    justify-content: space-between;
    align-items: end;

      h5 {
        font-weight: 500;
        font-size: 33px;
        line-height: 104%;
        color: #000;
      }
  }

  &__flex {
    margin-top: clamp(1.375rem, calc(0.715rem + 3.3vw), 3.125rem);
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    img {
      justify-self: end;
    }

      @media (max-width: 850px) {
        display: flex;
        flex-direction: column-reverse;
      }
  }

  &__hint {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;

    font-weight: 400;
    font-size: 16px;
    line-height: 104%;
    text-align: right;
    color: #000;

    @media (max-width: 850px) {
      display: none;
    }

      svg {
        cursor: pointer;
        &:hover + .exclusives__hint-block {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
  }

  &__hint-block {
    width: 200px;
    position: absolute;
    left: 5px;
    bottom: 20px;
    z-index: 3;

    font-weight: 400;
    font-size: 14px;
    line-height: 104%;
    color: #012321;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 10px;
    text-align: start;
    transition: all .3s ease-in-out;

    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
  }

  &__survey-block {
    display: flex;
    flex-direction: column;
    gap: 25px;

      label {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 9px;

        font-weight: 400;
        font-size: 21px;
        line-height: 104%;
        color: #1f1f1f;

          input {
            display: none;

            &:checked + span {
              border: 1px solid #006A61;
              background-color: transparent;

                &::after {
                  opacity: 1;
                  visibility: visible;
                }
            }
          }
      }

      button {
        padding: 16px 26px;
        background-color: #006a61;
        border-radius: 15px;
        font-weight: 500;
        font-size: 19px;
        line-height: 104%;
        color: #fff;
        width: fit-content;

        transition: all .3s ease-in-out;

          &:hover {
            background-color: #109a8e;
          }
      }
  }


  &__radio-custom {
    display: inline-block;
    position: relative;
    min-width: 25px;
    height: 25px;
    background-color: #91C8C4;
    border: 1px solid #91C8C4;
    border-radius: 50%;
    vertical-align: sub;

      &::after {
        content: '';
        min-width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: #006A61;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        opacity: 0;
        visibility: hidden;
      }
  }

  // four

  &__advantages-block {
    margin-top: clamp(0.625rem, calc(0.13rem + 2.48vw), 1.938rem);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 26px;

    @media (max-width: 950px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__advantages {
    display: flex;

      @media (max-width: 950px) {
        display: grid;
        grid-template-columns: 127px 1fr;
      }

      img {
        position: relative;
        z-index: -1;
        height: fit-content;
      }
  }

  &__advantages-info {
    position: relative;
    padding: 20px;
    background: #f1f1f1;
    border-radius: 15px;

      &::after {
        content: '';
        position: absolute;
        left: -25px;
        top: 60%;
        border: 20px solid transparent;
        border-right: 20px solid #f1f1f1;
        border-bottom: 20px solid #f1f1f1;
        transform: translateY(-50%);
        z-index: -1;
      }

      h6 {
        font-weight: 700;
        font-size: 15px;
        line-height: 104%;
        color: #006a61;
      }

      p {
        margin-top: 16px;
        font-weight: 400;
        font-size: 12px;
        line-height: 110%;
        letter-spacing: 0.06em;
        color: #012321;
      }

  }

}
