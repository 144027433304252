.outlet {
  margin-top: 28px;

  h1 {
    margin-top: 20px;
    font-weight: 500;
    font-size: 32px;
    line-height: 104%;
    color: $titleoutlet;
  }

  &__banner {
    background: url('../img/outlet.png') no-repeat;
    background-size: cover;
    background-position: center;
    max-width: 1131px;
    width: 100%;
    height: 398px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px auto 0;
    padding: 29px;

    h2 {
      font-weight: 700;
      font-size: 47px;
      line-height: 104%;
      text-transform: uppercase;
      color: #fff;
    }

    p {
      margin-top: 18px;
      font-weight: 400;
      font-size: 22px;
      line-height: 104%;
      color: #fff;
      max-width: 690px;
    }

  }

}
