.tags {
  margin-top: 50px;

  &__top {
    display: flex;
    align-items: end;
    justify-content: space-between;
    gap: 10px;

    h1 {
      font-weight: 500;
      font-size: clamp(1.5rem, calc(1.311rem + 0.94vw), 2rem);
      line-height: 104%;
      color: $darkBlack;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 104%;
      color: $black;

      @media (max-width: 550px) {
        display: none;
      }
    }
  }

  &__slider-block {
    margin-top: 10px;
    padding: 8px clamp(0.938rem, calc(0.702rem + 1.18vw), 1.563rem);
    border-radius: 10px;
    background-color: $tagBack;

    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__slider {
    cursor: pointer;
    overflow: hidden;
  }


  &__content &__slide {
    width: auto;
    display: block;
    padding: 7px 10px;
    border-radius: 100px;
    background-color: $tagColor;
    font-weight: 400;
    font-size: 12px;
    line-height: 104%;
    color: $tagText;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: clamp(1.25rem, calc(1.038rem + 1.06vw), 1.813rem);

      button {
        svg {
          fill: black;
        }

        &.swiper-button-disabled {
          svg {
            fill: #9f9ea3;
          }
        }

        &:last-child {
          svg {
            transform: rotate(180deg);
          }
        }
      }
  }

  &__banner {
    margin-top: clamp(0.938rem, calc(0.584rem + 1.77vw), 1.875rem);
    border-radius: 10px;
    width: 100%;
  }

}

.filters {
  margin-top: 30px;

  h2 {
    font-weight: 500;
    font-size: clamp(1.25rem, calc(1.156rem + 0.47vw), 1.5rem);
    line-height: 104%;
    color: $titleFilter;
  }

  &__filter-block {
    margin-top: clamp(0.938rem, calc(0.82rem + 0.59vw), 1.25rem);
  }

  &__top {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    position: relative;
    z-index: 5;

    @media (max-width: 750px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
      > .filters__filter {
         &:not(:nth-child(1), :nth-child(2)) {
           display: none;
         }
       }
     }

     @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: repeat(9, 1fr);

      @media (max-width: 1140px) {
        grid-template-columns: repeat(auto-fill, minmax(206px, 1fr));
      }

      @media (max-width: 750px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
        margin-top: 5px;
       > .filters__filter {
          &:not(:nth-child(1), :nth-child(2)) {
            display: none;
          }
        }
      }

      @media (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
      }
  }

  &__filter {
    position: relative;

    button {
      display: flex;
      padding: 24px 10px;
      border: 1px solid $borderFilter;
      background-color: $filterBack;
      font-weight: 400;
      font-size: 13px;
      line-height: 104%;
      color: $black;
      text-align: start;

      span {
        display: block;
        background: url('../img/svg/arr.svg') no-repeat;
        width: 11px;
        height: 6px;
        transition: all .3s ease-in-out;
      }

      &::first-letter {
        text-transform: capitalize;
      }
    }

    > button:first-of-type {
        position: relative;
        z-index: 2;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        min-width: 100%;
        width: max-content;
        overflow: hidden;
    }

  }

  &__filter-info {
    z-index: 1;
    transition: all .3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    transform: translateY(-50px);
  }

  &__product-button {
    padding: 22px 10px;
    border: 1px solid #00857b;
    border-radius: 5px;
    background-color: #00857b;
    font-weight: 500;
    font-size: 15px;
    line-height: 104%;
    color: #fff;

    transition: all .3s ease-in-out;

      &:hover {
        background-color: #109a8e;
      }

      @media (max-width: 750px) {
        display: none;
      }
  }

  &__button-mob {
    display: none;
    margin-top: 5px;
    padding: 22px 10px;
    border: 1px solid #00857b;
    border-radius: 5px;
    background-color: #00857b;
    font-weight: 500;
    font-size: 15px;
    line-height: 104%;
    color: #fff;
    width: 100%;

    transition: all .3s ease-in-out;

      &:hover {
        background-color: #109a8e;
      }

      @media (max-width: 750px) {
        display: block;
      }
  }

  .activeFilter {
    z-index: 10;
    > button:first-of-type {
      span {
        transform: rotate(-180deg);
      }
    }

    .filters__filter-info {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  // cards

  &__cards-buttons {
    margin-top: clamp(0.938rem, calc(0.348rem + 2.95vw), 2.5rem);
    display: flex;
    align-items: center;
    gap: 15px;
    overflow: auto;

      button {
        border-radius: 10px;
        padding: 10px 20px;
        background: $filterBack;
        font-weight: 500;
        font-size: 18px;
        line-height: 104%;
        text-align: center;
        color: $black;
        min-width: fit-content;
      }
  }
  &__cards-content {
    margin-top: 21px;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;

    @media (max-width: 1000px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 850px) {
      grid-template-columns: repeat(2, 1fr);

          .card-product {
            &:not(:nth-child(-n+4)) {
              display: none;
            }
          }

    }

    @media (max-width: 550px) {
      grid-template-columns: repeat(1, 1fr);
    }

      &:nth-child(3) {
        margin-top: clamp(2rem, calc(1.693rem + 1.53vw), 2.813rem);
      }
  }

  .activeButtonCard {
    background-color: $cardBack;
  }

}
