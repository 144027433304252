.header {
  background: $white;
  padding: 6px 0 17px 0;
  box-shadow: 0 -5px 19px 0 rgba(0, 0, 0, 0.25);

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 105px;
    height: 64px;

    img {
      width: 100%;
      height: clamp(1.875rem, calc(-6.625rem + 42.5vw), 4rem);
    }

    @media (max-width: 650px) {
      display: flex;
      align-items: center;
    }
  }

  &__form {
    margin-left: clamp(1.25rem, calc(-5.313rem + 11.67vw), 3.438rem);

    @media(max-width: 650px) {
      margin-left: 10px;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.10);
    width: clamp(15rem, calc(-25.5rem + 72vw), 28.5rem);
    height: 47px;
    background: $headerColor;
    padding: 13px clamp(0.625rem, calc(0.019rem + 3.03vw), 1.25rem);

    input {
      width: 100%;
      border: 0;
      font-weight: 400;
      font-size: 16px;
      color: #9f9ea3;
    }

    @media (max-width: 900px) {
      margin-right: 10px;
    }

    @media (max-width: 650px) {
      width: clamp(2.5rem, calc(1.591rem + 4.55vw), 3.438rem);
      display: block;

      input {
        display: none;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin-left: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.10);
    height: 47px;
    background: $headerColor;
    padding: 6px clamp(0.313rem, calc(0.009rem + 1.52vw), 0.625rem) 5px
      clamp(0.313rem, calc(0.009rem + 1.52vw), 0.625rem);

    @media (max-width: 900px) {
      margin-left: auto;
    }
  }

  &__locality {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      width: max-content;
      font-weight: 400;
      font-size: clamp(0.625rem, calc(0.443rem + 0.91vw), 0.813rem);
      color: #9f9ea3;
      transition: all 0.3s ease-in-out;
    }

    svg {
      transition: all 0.3s ease-in-out;
      fill: #9f9ea3;
    }

    &:hover {
      span {
        color: #ff7306;
      }

      svg {
        fill: #ff7306;
      }
    }
  }

  &__basket {
    svg {
      width: clamp(0.938rem, calc(0.634rem + 1.52vw), 1.25rem);
      transition: all 0.3s ease-in-out;
      fill: #9f9ea3;
    }

    &:hover {
      svg {
        fill: #ff7306;
      }
    }
  }

  &__favorites {
    svg {
      width: clamp(0.688rem, calc(0.445rem + 1.21vw), 0.938rem);
      transition: all 0.3s ease-in-out;
      fill: #9f9ea3;
    }

    &:hover {
      svg {
        fill: #ff7306;
      }
    }
  }

  &__line {
    width: 1px;
    height: 15px;
    background-color: #dadada;
    margin: 0 clamp(0.625rem, calc(0.322rem + 1.52vw), 0.938rem);
  }

  &__person {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 278px;
    border-radius: 10px;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.10);
    margin-left: auto;
    background: $headerColorTwo;
    height: 47px;
    padding: 7px 10px;

    @media (max-width: 900px) {
      display: none;
    }
  }

  &__reg {
    max-width: 200px;
    width: 100%;
    color: $lightGreen;
    height: 15px;
    transition: all .3s ease-in-out;

    &:hover {
      color: #ff7306;
    }
  }

  &__sale {
    max-width: 200px;
    width: 100%;
    color: $lightGreen;
    height: 15px;
  }

  &__reg {
    max-width: max-content;
    font-weight: 700;
    font-size: 14px;

    display: flex;
    gap: 5px;
    align-items: baseline;
    transition: all .3s ease-in-out;

    svg {
      transition: all .3s ease-in-out;
      fill: $lightGreen;

      @media (max-width: 700px) {
        display: none;
      }
    }

    &:hover {
      color: #ff7306;
      svg {
        fill: #ff7306;
      }
    }
  }

  &__sale {
    font-weight: 400;
    font-size: 13px;
  }



  &__person-block,
  &__mobile-block {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 10px;
    width: clamp(2.313rem, calc(1.706rem + 3.03vw), 2.938rem);
    height: 47px;

    background: $headerColorThree;
    box-shadow: -8px 0 8px -8px rgba(76, 185, 132, 0.17);
  }

  &__mobile {
    display: none;
    margin-left: auto;
    padding-left: 10px;

    @media (max-width: 900px) {
      display: block;
    }
  }

  &__mobile-block {
    position: relative;
    box-shadow: 0 1px 8px 0 rgba(1, 35, 33, 0.1);
  }

  &__bottom {
    display: flex;
    align-items: center;
    margin-top: 18px;

    @media (max-width: 900px) {
      display: none;
    }
  }

  &__btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 23px 12px 10px;
    border-radius: 10px;

    span {
      font-weight: 500;
      font-size: 13px;
      color: $darkGreen;
      transition: all 0.3s ease-in-out;
    }

  }

  &__icon {
    width: 15px;
    height: 15px;
    display: flex;
    gap: 1px;
    flex-wrap: wrap;
  }

  &__icon-block {
    width: 7px;
    height: 7px;
    transition: all .3s ease-in-out;
    background: $darkGreen;
  }

  &__nav {
    display: flex;
    width: 100%;
    justify-content: space-between;

    ul {
      display: flex;
      gap: 15px;
    }
  }

  &__nav-link {
    font-weight: 500;
    font-size: 16px;
    color: $headerLink;
    transition: all .3s ease-in-out;

    &:hover {
      color: #4cb984;
    }
  }

  &__stock {
    font-weight: 500;
    font-size: 16px;
    color: #ff7306;

    span {
      font-weight: 500;
      font-size: 16px;
      color: #e3a360;
    }
  }
}

.menu {
  display: flex;
  position: absolute;
  top: 110px;
  opacity: 0;
  visibility: hidden;
  left: 0;
  box-shadow: 3px 4px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  transition: all 0.3s ease-in-out;
  height: 530px;
  z-index: -100;

  &__tabs {
    background: $menu;
    border-radius: 9px 0 0 9px;
  }

  &__nav {
    width: clamp(12.5rem, calc(0.313rem + 21.67vw), 16.563rem);
  }

  &__tab-btn {
    cursor: pointer;
    padding: 14px 19px;

    button {
      font-weight: 500;
      font-size: 15px;
      color: $logoText;
      text-align: left;
    }

    &:first-child {
      border-radius: 9px 0 0 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 0 9px 9px 0;
  }

  &__top {
    width: clamp(37.188rem, calc(-18.313rem + 98.67vw), 55.688rem);
    display: flex;
  }

  &__content-box {
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &__content-nav {
    ul {
      width: clamp(13.125rem, calc(3rem + 18vw), 16.5rem);
    }
  }

  &__box-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 19px;
    font-weight: 500;
    font-size: 15px;
    color: $logoText;
    text-align: left;

    a {
      width: 100%;
      color: $logoText;
      transition: color .3s ease-in-out;
      pointer-events: none;

      &:hover {
        color: #4cb984;
      }
    }

    svg {
      fill: #525252;
      transition: color .3s ease-in-out;
    }

    span {
      transition: color .3s ease-in-out;
    }
  }

  &__next {
    cursor: pointer;
    &:hover {
      a {
        color: #4cb984;
      }

      span {
        color: #4cb984;
      }

      svg {
        fill: #428b80;
      }
    }
  }

  &__img {
    padding: 0 16px 7px 16px;
    margin-top: auto;
    img {
      width: 777px;
      height: 152px;
    }
  }

  &__links {
    display: flex;
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
    transition: opacity .3s ease-in-out;

    &:nth-child(odd) {
      transform: translateY(47px);
    }

    &:nth-child(even) {
      transform: translateY(98px);
    }

    ul {
      width: clamp(9.375rem, calc(-1.688rem + 19.67vw), 13.063rem);
      li {
        padding: 14px 19px;
        height: 50.5px;

        a {
          display: block;
          width: 100%;
          font-weight: 500;
          font-size: clamp(0.813rem, calc(0.438rem + 0.67vw), 0.938rem);
          color: #acacac;
          transition: color .3s ease-in-out;
          pointer-events: none;
          &:hover {
            color: #4cb984;
          }
        }
      }
    }
  }
}

.burger {
  display: none;

  @media (max-width: 900px) {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__content {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 23px 12px 10px;
    border-radius: 10px;

    span {
      font-weight: 500;
      font-size: 13px;
      color: $darkGreen;
      transition: all 0.3s ease-in-out;
    }
  }

  &__icon {
    width: 15px;
    height: 15px;
    display: flex;
    gap: 1px;
    flex-wrap: wrap;
  }

  &__icon-block {
    width: 7px;
    height: 7px;
    transition: all .3s ease-in-out;
    background: $darkGreen;
  }

  &__menu {
    position: relative;
  }

  &__spans {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    gap: 6px;
    width: 47px;
    height: 47px;
    padding-right: 10px;
    border-radius: 10px;
    background: $headerColor;
    box-shadow: 0 1px 8px 0 rgba(1, 35, 33, 0.1);
    z-index: 100;

    span {
      background-color: $darkGreen;
      height: 3px;
      border-radius: 10px;

      &:nth-child(1) {
        width: 19px;
      }
      &:nth-child(2) {
        width: 26px;
      }
      &:nth-child(3) {
        width: 9px;
      }
    }
  }

  &__nav {
    position: absolute;
    width: 308px;
    background: $menu;
    top: 45px;
    opacity: 0;
    visibility: 0;
    transition: all 0.3s ease-in-out;
    right: -14px;
    box-shadow: -3px -4px 15px 0 rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    z-index: -100;
    overflow: hidden;

    ul {
      padding-bottom: 30px;
      li {
        padding: 15px 20px;
        text-align: end;

        a {
          display: block;
          width: 100%;
          font-weight: 500;
          font-size: 16px;
          color: $headerLink;
          transition: all .3s ease-in-out;

          &:hover {
            color: #006a61;
          }

          pointer-events: none;
        }
      }
    }
  }

  &__exit {
    text-align: end;
    padding: 15px 20px;
    background: $headerColorThree;

    span {
      font-weight: 500;
      font-size: 16px;
      text-align: right;
      color: #ff7306;
    }
  }

  &__catalog {
    position: absolute;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    top: 45px;
    background: $menu;
    border-radius: 10px;
    z-index: 900;
    box-shadow: -3px -4px 15px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.3s ease-in-out;
  }

  &__ul {
    width: 300px;
    padding-bottom: 40px;

    li {
      max-width: 275px;
      width: 100%;
      padding: 15px 20px;

      button {
        text-align: start;
        font-weight: 500;
        font-size: 16px;
        color: $headerLink;
      }
    }
  }

  &__content-tab {
    background-color: $headerColorThree;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all .3s ease-in-out;
  }

  &__content-ul {
    li {
      padding-left: 40px;

      a {
        font-weight: 500;
        font-size: 16px;
        color: $headerLink;
      }
    }
  }
}

.activeMenu {
  opacity: 1;
  visibility: visible;
  top: 126px;
  z-index: 1000;

  .menu__box-link {
    a {
      pointer-events: auto;
    }
  }

  .menu__links {
    ul {
      a {
        pointer-events: auto;
      }
    }
  }
}

.activeBtn {
  background: #ff7306;
  span {
    color: #fff;
  }

  .header__icon-block,
  .burger__icon-block {
    background: #fff;
  }
}

.activeTab {
  background-color: #fff;

  button {
    color: #525252;
  }
}

.openMenu {
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: fit-content;
}

.openLi {
  span {
    color: #4cb984;
  }

  svg {
    fill: #428b80;
  }
}

.btnNext {
  span {
    color: #4cb984;
  }

  svg {
    fill: #428b80;
  }
}

.showColumns {
  opacity: 1;
  width: 100%;
  height: fit-content;
  visibility: visible;
}

.burgerActive {
  opacity: 1;
  visibility: visible;
  top: 65px;
  z-index: 1000;

  .burger__link {
    pointer-events: auto;
  }
}

.activeHref {
  background: $headerColorThree;

  .burger__link {
    color: $darkGreen;
  }
}

.activeContent {
  .burger__tab-btn {
    background: $headerColorThree;
    max-width: 100%;

    button {
      color: $darkGreen;
    }
  }

  .burger__content-tab {
    opacity: 1;
    visibility: visible;
    max-height: 500px;
  }
}
