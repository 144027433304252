.cabinet {
  width: 100%;

  &__content {
    margin-top: clamp(0rem, calc(-6.264rem + 10.55vw), 1.438rem);
  }

  &__personal {
    @media (max-width: 950px) {
      margin-top: 30px;
    }

    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 104%;
      color: $cabinetTitle;
    }

  }

  &__top-i {
    margin-top: 25px;
    display: flex;
    gap: 20px;

      > .cabinet__input {
        &:nth-child(-n+2) {
            flex: 1 1 195px;
            min-width: 150px;
        }
        &:nth-child(3) {
          flex: 1 1 416px;
          min-width: 300px;
        }
      }

      @media (max-width: 950px) {
        margin-top: 20px;
        flex-direction: column;
        gap: 15px;

        > .cabinet__input {
            flex: auto !important;
        }
      }
  }

  &__bottom-i {
    margin-top: 30px;
    display: flex;
    gap: 20px;

    > .cabinet__input {
      &:nth-child(1) {
        flex: 1 1 100px;
        min-width: 70px;
      }
      &:nth-child(2) {
        flex: 1 1 127px;
        min-width: 69px;
      }
      &:nth-child(3) {
        flex: 1 1 143px;
        min-width: 70px;
      }
      &:nth-child(5) {
        flex: 1 1 416px;
        min-width: 300px;
      }
    }

    @media (max-width: 950px) {
      margin-top: 15px;
      flex-direction: column;
      gap: 15px;

      > .cabinet__input {
          &:nth-child(-n+3) {
            display: none;
          }
          width: 100%;
          flex: auto !important;
      }
    }

  }

  &__content &__mob-inp {
    display: none;
    @media (max-width: 950px) {
      display: flex;
    }

  }

  &__input {
    display: flex;
    flex-direction: column;
    gap: 5px;
    label {
      font-weight: 700;
      font-size: 16px;
      line-height: 104%;
      color: $darkGreen;
    }

    input {
      border: 1px solid #e1e1e1;
      border-radius: 10px;
      padding: 15px;
      background-color: $tagBack;
      font-weight: 400;
      font-size: 16px;
      line-height: 104%;
      color: $black;
      width: 100%;
    }
  }

  // info

  &__info-block {
    margin-top: clamp(1.875rem, calc(0.605rem + 6.35vw), 4.375rem);
  }

  &__info {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

      > .cabinet__program {
        display: block;

          @media (max-width: 950px) {
            display: none;
          }
      }
  }

  &__info-block {
    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 104%;
      color: $cabinetTitle;
    }
  }

  &__program {
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 104%;
      color: $cabinetTitle;
    }
  }

  &__content &__mob-p {
    display: none;

    @media (max-width: 950px) {
      display: block;
    }
  }

  &__card-program {
    height: 129px;
    margin-top: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 15px;
    box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.3);
    background-color: $tagBack;
    z-index: 1;
    overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: url('../img/card.png') no-repeat;
        box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.3);
        background-size: cover;
        width: 100%;
        height: 127px;
        z-index: -1;

        @media (max-width: 420px) {
         opacity: .4;
        }
      }
  }

  &__card-c {
    align-self: flex-end;
    > p {
      display: block;
      font-weight: 500;
      font-size: 16px;
      line-height: 104%;
      color: $black;

        &:nth-child(2) {
          margin-top: 5px;
        }
    }

    div {
      max-width: 201px;
      margin-top: 15px;
      font-weight: 400;
      font-size: 13px;
      line-height: 104%;
      color: $black;

      span {
        color: $activePanel;
      }
    }
  }

  &__pets {
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 104%;
      color: $cabinetTitle;
    }
  }

  &__flex {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__pet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: url('../img/decPet.png') no-repeat;
    background-size: contain;
    max-width: 128px;
    width: 100%;
    height: clamp(6.188rem, calc(-1.983rem + 13.76vw), 8.063rem);

    @media (max-width: 950px) {
      max-width: 100%;
      width: clamp(5.313rem, calc(3.503rem + 9.05vw), 8.875rem);
      height: clamp(5.313rem, calc(3.503rem + 9.05vw), 8.875rem);
    }

      img {
        width: clamp(1.875rem, calc(-5.206rem + 11.93vw), 3.5rem);

          @media (max-width: 950px) {
            width: clamp(2.5rem, calc(1.802rem + 3.49vw), 3.875rem);
          }
      }

      h4 {
        margin-top: 14px;
        font-weight: 500;
        font-size: 16px;
        line-height: 104%;
        color: #2b2b2b;
      }
  }

}
