.basket {
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease-in-out;

  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;

  display: flex;
  justify-content: end;

  &__modal {
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease-in-out;
    transform: translateX(100px);

    max-width: clamp(20rem, calc(16.651rem + 16.75vw), 28.875rem);
    width: 100%;
    padding:
    clamp(4.063rem, calc(3.473rem + 2.95vw), 5.625rem)
    clamp(1.125rem, calc(0.96rem + 0.83vw), 1.563rem);
    border-radius: clamp(0.938rem, calc(0.82rem + 0.59vw), 1.25rem);
    background: $white;

      h2 {
        font-weight: 700;
        font-size: clamp(1.063rem, calc(0.897rem + 0.83vw), 1.5rem);
        line-height: 104%;
        color: $black;
      }

      form {
        display: flex;
        align-items: center;
        border-radius: 11px;

        margin-top: clamp(1.25rem, calc(1.061rem + 0.94vw), 1.75rem);
        background: $basket1;

          input {
            border: 0;
            padding: 9px 9px 9px 22px;
            background: transparent;

            font-weight: 500;
            font-size: clamp(0.75rem, calc(0.656rem + 0.47vw), 1rem);
            line-height: 104%;
            color: $black;
            max-width: 186px;
            width: 100%;
          }

          button {
            background-color: #8b8b8b;
            font-weight: 500;
            font-size: clamp(0.75rem, calc(0.656rem + 0.47vw), 1rem);
            line-height: 104%;
            color: #fff;
            padding: 9px 22px;
            border-radius: 11px;
            width: 100%;
            text-align: start;
            transition: all .4s ease-in-out;

              &:hover {
                background-color: #bebebe;
              }
          }

      }
  }

  &__cards {
    margin-top: clamp(1.375rem, calc(1.186rem + 0.94vw), 1.875rem);

    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    height: calc(100% - 100px);

      @media (max-width: 480px) {
        height: calc(100% - 130px);
      }
  }

  &__card {
    display: flex;
    gap: 8px;

    img {
      width: clamp(7.813rem, calc(6.704rem + 5.54vw), 10.75rem);
      height: clamp(7.813rem, calc(6.704rem + 5.54vw), 10.75rem);
      object-fit: cover;
      display: block;
    }
  }

  &__info {
    a {
      display: block;
      font-weight: 500;
      font-size: clamp(0.813rem, calc(0.695rem + 0.59vw), 1.125rem);
      line-height: 104%;
      color: $black;
      text-align: start;
    }

    p {
      margin-top: clamp(0.75rem, calc(0.656rem + 0.47vw), 1rem);
      font-weight: 500;
      font-size: 15px;
      line-height: 104%;
      color: $black;
    }
  }

  &__flex {
    margin-top: clamp(0.688rem, calc(0.57rem + 0.59vw), 1rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    > span {
      font-weight: 700;
      font-size: clamp(1.063rem, calc(0.897rem + 0.83vw), 1.5rem);
      line-height: 104%;
      color: $black;
    }
  }

  &__counter {
    border-radius: 10px;
    width: clamp(4.75rem, calc(4.066rem + 3.42vw), 6.563rem);
    height: 33px;
    background-color: $counter;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: 500;
      font-size: clamp(0.688rem, calc(0.57rem + 0.59vw), 1rem);
      line-height: 104%;
      text-align: center;
      color: $black;
      min-width: 17px;
    }

      button {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-weight: 400;
        font-size: clamp(1.125rem, calc(0.983rem + 0.71vw), 1.5rem);
        line-height: 104%;
        text-align: center;
        color: $counterText;
      }
  }

  &__buttons {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    height: clamp(1.063rem, calc(0.685rem + 1.89vw), 2.063rem);

    @media (max-width: 480px) {
      height: auto;
      flex-direction: column;
    }
    > button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      @media (max-width: 480px) {
        padding: 10px 40px !important;
        width: 100%;
      }

      &:nth-child(1) {
        padding: 0 clamp(0.75rem, calc(0.42rem + 1.65vw), 1.625rem);
        background-color: #00746a;
        border-radius: 10px;

        font-weight: 500;
        font-size: clamp(0.5rem, calc(0.335rem + 0.83vw), 0.938rem);
        line-height: 104%;
        color: #fff;

        transition: all .3s ease-in-out;

        &:hover {
          background-color: #109a8e;
        }
      }
      &:nth-child(2) {
        padding: 8px clamp(0.75rem, calc(0.42rem + 1.65vw), 1.625rem);
        border: 1px solid #006a61;
        border-radius: 10px;

        font-weight: 500;
        font-size: clamp(0.5rem, calc(0.335rem + 0.83vw), 0.938rem);
        line-height: 104%;
        color: $black;

        transition: all .3s ease-in-out;

        &:hover {
          background-color: #006a61;
          color: white;
        }
      }
    }
  }

  &.activeBasket {
    opacity: 1;
    visibility: visible;

      .basket__modal {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }
  }

}
