*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
}

img {
  max-width: 100%;
}

video {
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
}

body {
  height: 100%;
  position: relative;
  line-height: 1.5;
  min-width: 320px;
  font-family: 'PF DinDisplay Pro';
  background-color: $mainColor;

  transition: all .3s ease-in-out;
}

button {
  outline: none;
  border: 0;
  cursor: pointer;
  background: 0;
  font-family: 'PF DinDisplay Pro';
}

input, textarea {
  background-color: transparent;
  outline: none;
  font-family: 'PF DinDisplay Pro';
}

ul,
ol {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  font-family: 'PF DinDisplay Pro';
}

button {
  padding: 0;
  cursor: pointer;
  border: 0;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

* {
  -webkit-tap-highlight-color: transparent;
}

::selection {
  color: white;
  background-color: #4abde3;
}

:root {
  scroll-behavior: smooth;
}
