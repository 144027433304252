.panel {
  display: block;
  border-radius: 10px;
  background: $panel;
  padding: 0 7px 15px 7px;
  height: fit-content;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.10);

  @media (max-width: 950px) {
    display: none;
  }

  &__top {
    border-radius: 100%;
    width: 215px;
    height: 215px;
  }

  &__list {
    padding: 23px 17px 0;

      li {
        display: flex;
        &:not(:last-child, :first-child) {
          margin-top: 15px;
        }
        &:last-child {
          margin-top: 67px;
        }
      }

      a {
        font-weight: 500;
        font-size: 18px;
        line-height: 104%;
        color: $listCabinetA;
        transition: all .3s ease-in-out;

          &:hover {
            color: $activePanel;
          }
      }

  }

  .activeHref {
    color: $activePanel;
  }

}
