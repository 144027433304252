.contacts {
  margin-top: 50px;

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 104%;
    color: $titleoutlet;
  }

  &__main {
    margin-top: clamp(1.063rem, calc(0.284rem + 3.89vw), 3.125rem);

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

      @media (max-width: 850px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 27px;
      }
  }

  &__ofice,  &__warehouse  {
    display: flex;
    gap: clamp(0.375rem, calc(0.045rem + 1.65vw), 1.25rem);
    img {
      display: block;
      height: fit-content;
      &:nth-child(2) {
        display: none;
      }
    }
  }

  &__info {
      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 104%;
        color: $allegianceColor;

          span {
            font-weight: 700;
          }
      }

      a {
        display: block;
        margin-top: 9px;
        font-weight: 500;
        font-size: 18px;
        line-height: 104%;
        color: $allegianceColor;
      }
  }

  &__content &__top {
    margin-top: 20px;
  }

  &__map {
    margin-top: 30px;
  }

}
