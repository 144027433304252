.allegiance {

  &__banner {
    margin: 0 auto;
    background: url('../img/program.png') no-repeat;
    background-size: cover;
    background-position: center;
    max-width: 1200px;
    width: 100%;
    height: 375px;
  }

  &__banner-content {
    padding-top: 53px;
    h1 {
      font-weight: 700;
      font-size: clamp(2.188rem, calc(1.904rem + 1.42vw), 2.938rem);
      line-height: 104%;
      text-transform: uppercase;
      color: #fff;
    }
    p {
      margin-top: clamp(0.813rem, calc(0.695rem + 0.59vw), 1.125rem);
      font-weight: 400;
      font-size: clamp(1rem, calc(0.858rem + 0.71vw), 1.375rem);
      line-height: 104%;
      color: #fff;
      max-width: 400px;
    }
  }

  &__balls {
    margin-top: 53px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 104%;
      color: $allegianceColor;
      text-align: center;


        @media (max-width: 750px) {
          max-width: 298px;
        }
    }
  }

  &__cards {
    margin-top: 39px;
    display: flex;
    justify-content: center;

      @media (max-width: 950px) {
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: clamp(0.125rem, calc(-0.129rem + 1.27vw), 0.625rem);
      }
  }

  &__card {

    h3 {
      max-width: 275px;
      font-weight: 700;
      font-size: 24px;
      line-height: 104%;
      color: #fff;
    }

    p {
      max-width: 275px;
      margin-top: 15px;
      font-weight: 500;
      font-size: 20px;
      line-height: 104%;
      color: #fff;
    }

    padding: 52px 32px;

    &:nth-child(1) {
      background: url('../img/kashBack.png') no-repeat;
      background-size: cover;
      background-position: center;
      width: clamp(23.75rem, calc(4.685rem + 32.11vw), 28.125rem);
      height: 218px;
    }

    &:nth-child(2) {
      background: url('../img/pay.png') no-repeat;
      background-size: cover;
      background-position: center;
      width: clamp(18.125rem, calc(-5.298rem + 39.45vw), 23.5rem);
      height: 198px;
    }

    &:nth-child(3) {
      background: url('../img/ball.png') no-repeat;
      background-size: cover;
      background-position: center;
      width: clamp(18.125rem, calc(-4.753rem + 38.53vw), 23.375rem);
      height: 198px;
    }

    @media (max-width: 950px) {
      &:nth-child(1) {
        max-width: 489px;
        width: 100%;
        height: 236px;
      }

      &:nth-child(2) {
        max-width: 482px;
        width: 100%;
        height: 253px;
      }

      &:nth-child(3) {
        max-width: 482px;
        width: 100%;
        height: 255px;
      }
    }

  }

  &__participation {
    margin: 0 auto;
    padding: 0 clamp(1.375rem, calc(-6.475rem + 16.75vw), 5.75rem) 0
    clamp(0.75rem, calc(0.278rem + 2.36vw), 2rem);
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: url('../img/where.png') no-repeat;
    background-size: cover;
    background-position: 30% center;
    max-width: 1162px;
    width: 100%;
    height: 194px;

    @media (max-width: 950px) {
      flex-direction: column;
      justify-content: center;
      align-items: start;
      gap: 39px;
      height: 297px;
    }

      a {
        padding:
        clamp(0.938rem, calc(0.867rem + 0.35vw), 1.125rem)
        clamp(2.313rem, calc(2.124rem + 0.94vw), 2.813rem);
        font-weight: 500;
        font-size: 15px;
        line-height: 104%;
        color: #000;
        background-color: #fff;
        border-radius: 10px;
      }
  }

  &__text {
    display: flex;
    align-items: center;

      span {
        font-weight: 500;
        font-size: clamp(5rem, calc(4.693rem + 1.53vw), 5.813rem);
        line-height: 104%;
        color: #fff;
      }
  }

  &__texts {
    display: flex;
    flex-direction: column;
    span {
      &:nth-child(1) {
        font-weight: 400;
        font-size: clamp(2.188rem, calc(2.07rem + 0.59vw), 2.5rem);
        line-height: 104%;
        text-transform: uppercase;
        color: #fff;
      }
      &:nth-child(2) {
        font-weight: 500;
        font-size: clamp(1.625rem, calc(1.507rem + 0.59vw), 1.938rem);
        line-height: 104%;
        color: #fff;
      }
    }
  }

  &__cards-b {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 17px;

      @media (max-width: 950px) {
        grid-template-columns: repeat(1, 1fr);
      }
  }

  &__card-b {
    display: flex;
    align-items: center;
    gap: clamp(0.625rem, calc(0.177rem + 2.24vw), 1.813rem);

    &:nth-child(1) {
      padding-left: 10px;
      background: url('../img/card1.png') no-repeat;
      background-size: cover;
      height: 208px;
      justify-content: end;

      @media (max-width: 950px) {
        height: 170px;
        border-radius: 10px;
      }

        img {
          width: clamp(6.875rem, calc(5.366rem + 7.55vw), 10.875rem);
        }
    }

    &:nth-child(3) {
      padding-left: 22px;
      background: url('../img/card2.png') no-repeat;
      background-size: cover;
      height: 208px;
      flex-direction: row-reverse;
      justify-content: start;

      @media (max-width: 950px) {
        border-radius: 10px;
        height: 170px;
      }

        img {
          width: clamp(6.25rem, calc(4.717rem + 7.67vw), 10.313rem);
        }
    }

  }

  &__card-info {
    h3 {
      font-weight: 700;
      font-size: 26px;
      line-height: 104%;
      color: #fff;
    }

    p {
      margin-top: 10px;
      font-weight: 500;
      font-size: 18px;
      line-height: 104%;
      color: #fff;
      max-width: 195px;
    }
  }


  &__status {
    margin-top: 71px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    @media (max-width: 950px) {
      display: none;
    }

        img {
          height: fit-content;
        }
  }

  &__status-top {
    h5 {
      font-weight: 700;
      font-size: 32px;
      line-height: 104%;
      color: $textStatus;
    }

    p {
      margin-top: 12px;
      font-weight: 500;
      font-size: 24px;
      line-height: 104%;
      color: $allegianceColor;

        span {
          color: #006a61;
        }
    }
  }

  &__status-bottom {
    margin-top: clamp(1.25rem, calc(0.896rem + 1.77vw), 2.188rem);
    h6 {
      font-weight: 500;
      line-height: 104%;
      font-size: 32px;
      color: #006a61;
    }
    p {
      font-weight: 500;
      font-size: 24px;
      line-height: 104%;
      color: $allegianceColor;

        span {
          color: #006a61;
        }
    }
  }

  &__left {
    text-align: right;
  }

  &__right {
    text-align: left;
    h5 {
      color: #ffb023;
    }

    h6 {
      color: #ffb023;
    }

    p {
      span {
        color: #ffb023;
      }
    }
  }

  &__mob {
    display: none;

    @media (max-width: 950px) {
      display: block;
      text-align: left;
    }
  }

}
