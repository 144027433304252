.myorder {
  margin-top: clamp(1.813rem, calc(1.341rem + 2.36vw), 3.063rem);

  &__table {
    display: grid;
    grid-template-columns: 13.08% 41.20% 12.02% 11.58% 10.70% 11.41%;

    background-color: $tagBack;
    border: 1px solid #fff;

    @media (max-width: 950px) {
      grid-template-columns: repeat(3, 1fr);
      .myorder__cell {
        &:not(:nth-child(-n+3)) {
          display: none;
        }
      }
    }

    &:nth-child(1) {
      border-radius: 10px 10px 0 0;
      background-color: #008d83;
      border: 0;

        .myorder__cell {
          color: white;

          &::after {
            background-color: #fff;
          }
        }
    }

  }

  &__cell {
    position: relative;
    min-width: 100%;
    padding: clamp(0.625rem, calc(0.308rem + 1.59vw), 1.25rem);
    font-weight: 500;
    font-size: clamp(0.875rem, calc(0.748rem + 0.63vw), 1.125rem);
    line-height: 104%;
    color: $colorTable;

      &:not(:last-child) {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: black;
          min-width: 1.0px;
          height: calc(100% - 7px);
        }
      }

      @media (max-width: 950px) {
        &:nth-child(n+3) {
          &::after {
            background-color: transparent;
            min-width: 0;
          }
        }
      }



  }

  a {
    display: block;
    width: 100%;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 104%;
    text-align: center;
    color: $buttonColorTable;
    background-color: $buttonTable;
  }

}
