.modal-basket {
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease-in-out;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;

  overflow: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  &__modal {
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease-in-out;
    transform: translateY(-50px);

    padding:
    clamp(0.625rem, calc(0.153rem + 2.36vw), 1.875rem)
    clamp(0.313rem, calc(-0.348rem + 3.3vw), 2.063rem);
    border-radius: 20px;
    background: $white;
    max-width: 833px;
    width: 100%;
    margin: 0 15px;

    h2 {
      font-weight: 700;
      font-size: clamp(0.75rem, calc(0.467rem + 1.42vw), 1.5rem);
      line-height: 104%;
      color: $black;
    }

    @media (max-width: 480px) {
     max-height: 400px;
      overflow: auto;
    }

  }

  &__product {
    margin-top: clamp(1rem, calc(0.623rem + 1.89vw), 2rem);
    display: flex;
    gap: 11px;

    @media (max-width: 480px) {
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
    }

    img {
      display: block;
      max-width: clamp(5.563rem, calc(3.605rem + 9.79vw), 10.75rem);
      height: clamp(5.563rem, calc(3.605rem + 9.79vw), 10.75rem);
      object-fit: cover;
    }
  }

  &__info {
    a {
      display: block;
      font-weight: 500;
      font-size: clamp(0.563rem, calc(0.35rem + 1.06vw), 1.125rem);
      line-height: 104%;
      color: $black;
    }

    > span {
      display: block;
      margin-top: clamp(0.75rem, calc(0.443rem + 1.53vw), 1.563rem);
      font-weight: 700;
      font-size: clamp(0.75rem, calc(0.467rem + 1.42vw), 1.5rem);
      line-height: 104%;
      color: $black;
    }

    > p {
      margin-top: clamp(0.938rem, calc(0.584rem + 1.77vw), 1.875rem);
      font-weight: 500;
      font-size: clamp(0.5rem, calc(0.311rem + 0.94vw), 1rem);
      line-height: 104%;
      color: $black;
    }
  }

  &__buttons {
    margin-top: clamp(0.625rem, calc(0.389rem + 1.18vw), 1.25rem);
    display: flex;
    gap: 10px;
    align-items: center;
    height: clamp(1.063rem, calc(0.685rem + 1.89vw), 2.063rem);

    @media (max-width: 480px) {
      height: auto;
      flex-direction: column;
    }
    > button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      @media (max-width: 480px) {
        padding: 10px 40px !important;
        width: 100%;
      }

      &:nth-child(2) {
        padding: 0 clamp(0.75rem, calc(0.42rem + 1.65vw), 1.625rem);
        background-color: #00746a;
        border-radius: 10px;

        font-weight: 500;
        font-size: clamp(0.5rem, calc(0.335rem + 0.83vw), 0.938rem);
        line-height: 104%;
        color: #fff;

        transition: all .3s ease-in-out;

        &:hover {
          background-color: #109a8e;
        }
      }
      &:nth-child(3) {
        padding: 8px clamp(0.75rem, calc(0.42rem + 1.65vw), 1.625rem);
        border: 1px solid #006a61;
        border-radius: 10px;

        font-weight: 500;
        font-size: clamp(0.5rem, calc(0.335rem + 0.83vw), 0.938rem);
        line-height: 104%;
        color: $black;

        transition: all .3s ease-in-out;

        &:hover {
          background-color: #006a61;
          color: white;
        }
      }
    }
  }

  &__counter {
    border-radius: 10px;
    width: clamp(3.375rem, calc(2.172rem + 6.01vw), 6.563rem);
    height: 33px;
    background-color: $counter;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 480px) {
     width: 105px;
    }

    span {
      font-weight: 500;
      font-size: clamp(0.5rem, calc(0.311rem + 0.94vw), 1rem);
      line-height: 104%;
      text-align: center;
      color: $black;
      min-width: 17px;
    }

      button {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-weight: 400;
        font-size: clamp(0.75rem, calc(0.467rem + 1.42vw), 1.5rem);
        line-height: 104%;
        text-align: center;
        color: $counterText;
      }
  }

  &__recommendations {
    margin-top: clamp(1.563rem, calc(0.973rem + 2.95vw), 3.125rem);

    h4 {
      font-weight: 700;
      font-size: clamp(0.75rem, calc(0.467rem + 1.42vw), 1.5rem);
      line-height: 104%;
      color: $black;
    }
  }

  &__products {
    margin-top: clamp(0.938rem, calc(0.584rem + 1.77vw), 1.875rem);

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;

      @media (max-width: 750px) {
        grid-template-columns: repeat(1, 1fr);
      }
  }

  &__product-r {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: $orderPlacement;
    border-radius: 10px;

    display: flex;
    overflow: hidden;

    img {
      max-width: clamp(3.688rem, calc(2.367rem + 6.6vw), 7.188rem);
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
      display: block;
    }
  }

  &__product-r-info {
    padding: 10px 8px 13px 15px;
    flex-grow: 2;

    > span {
      font-weight: 700;
      font-size: clamp(0.625rem, calc(0.389rem + 1.18vw), 1.25rem);
      line-height: 104%;
      color: $black;
    }

    > p {
      font-weight: 400;
      font-size: clamp(0.438rem, calc(0.272rem + 0.83vw), 0.875rem);
      line-height: 104%;
      color: #c0c0c0;

      span {
        text-decoration: line-through;
      }
    }

  }

  &__bottom {
    margin-top: 4px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    gap: 34px;
    width: 100%;

    button {
      border-radius: 100%;
      background-color: #fe8b2c;
      min-width: 34px;
      height: 34px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__left {
    p {
      font-weight: 400;
      font-size: clamp(0.5rem, calc(0.311rem + 0.94vw), 1rem);
      line-height: 104%;
      color: $black;
      max-width: 110px;
    }
  }

  &__favotite {
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 3px;
  }

  &.activeModalBasket {
    opacity: 1;
    visibility: visible;

      .modal-basket__modal {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
  }

}

