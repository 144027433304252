.orders {
  margin-top: clamp(0rem, calc(-6.264rem + 10.55vw), 1.438rem);

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 104%;
    color: $cabinetTitle;
  }

  &__buttons {
    margin-top: 30px;

    display: flex;
    align-items: center;
    gap: 15px;

    button {
      border: 1px solid #c0c0c0;
      border-radius: 10px;
      padding: 10px clamp(0.125rem, calc(-0.3rem + 2.12vw), 1.25rem);
      background-color: $orderButton;
      font-weight: 400;
      font-size: 16px;
      line-height: 104%;
      color: $black;
    }
  }

  &__table-block {
    margin-top: 17px;

      @media (max-width: 650px) {
        display: none;
      }
  }

  &__content &__top {
      border-radius: 10px 10px 0 0;
      background-color: #008d83;
      border: 0;

        .orders__cell {
          color: white;

          &::after {
            background-color: #fff;
          }
        }
  }

  &__accardion {
    border: 1px solid #fff;

    > .orders__table {
      cursor: pointer;
    }
  }

  &__table, &__accardion-table {
    display: grid;
    grid-template-columns: 15.64% 46.68% 13.74% 12.44% 11.49%;

    background-color: $tagBack;
  }

  &__accardion-content {
    overflow: hidden;
    max-height: 0;

    transition: all .3s ease-in-out;

    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: $tagBack;
  }

  &__cell {
    position: relative;
    min-width: 100%;
    padding: 20px 9px;
    font-weight: 500;
    font-size: clamp(0.75rem, calc(-0.884rem + 2.75vw), 1.125rem);
    line-height: 104%;
    color: $colorTable;

      &:not(:last-child) {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: black;
          min-width: 1.0px;
          height: calc(100% - 7px);
        }
      }
  }

  &__accardion-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 9px;

    min-width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 104%;
    color: $colorTable;

      span {
        width: 100%;
      }
  }

  .activeOrderButton {
    border: 1px solid #008d83;
    background-color: #008d83;
    color: #fafafa;
  }

  // mob

  &__accardion-mob {
    margin-top: 30px;
    display: none;
    flex-direction: column;
    gap: 30px;

      @media (max-width: 650px) {
        display: flex;
      }
  }


  &__accardion-table-mob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 17px 10px;

    background-color: $mobAccardion;
    border-radius: 10px;

      h4 {
        display: flex;
        font-weight: 400;
        font-size: 13px;
        line-height: 104%;
        color: $black;

          span {
            display: none;
          }
      }

      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 104%;
        color: $black;
      }

      a {
        text-decoration: underline;
        font-weight: 400;
        font-size: 13px;
        line-height: 104%;
        color: $black;
      }
  }

  &__accardion-product-mob {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 14px;

      img {
        width: 52px;
        height: 70px;
        object-fit: cover;
      }

      h6 {
        font-weight: 500;
        font-size: 16px;
        line-height: 104%;
        color: $colorTable;
      }
  }

  &__accardion-info-mob {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

      h5 {
        font-weight: 700;
        font-size: 13px;
        line-height: 104%;
        color: $black;
      }

      span {
        font-weight: 700;
        font-size: 13px;
        line-height: 104%;
        text-align: right;
        color: #006a61;
      }
  }

  &__accardion-content-mob {
    background-color: $mobAccardion;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;

    max-height: 0;
    transition: all .3s ease-in-out;
    overflow: hidden;
  }

  .hiddenBottom {
    display: none;
  }

  .activeRepeat {
    border-radius: 10px 10px 0 0;


      h4 {
        span {
          display: block;
        }
      }
  }

}
