.feedback {
  margin-top: 47px;

  h2 {
    font-weight: 700;
    font-size: clamp(1.5rem, calc(1.288rem + 1.06vw), 2.063rem);
    line-height: 104%;
    color: $darkGreen;
  }

  &__cards {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media (max-width: 850px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 550px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__card {
    min-height: 408px;

    display: flex;
    flex-direction: column;

    border: 1px solid #dde9e8;
    border-radius: 5px;
    box-shadow: 0 4px 11px 0 rgba(0, 106, 97, 0.04);

      &:nth-child(3) {
        @media (max-width: 850px) {
          display: none;
        }
      }

      &:nth-child(2) {
        @media (max-width: 550px) {
          display: none;
        }
      }
  }

  &__top {
    position: relative;
    background-color: $white;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-height: 200px;
    height: 100%;
  }

  &__rating {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 3px;
    position: absolute;
    bottom: 0;
    left: 15px;
    background-color: $lightFeedback;
    border-radius: 5px 5px 0 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 19px 15px;
    background-color: $lightFeedback;
    flex-grow: 2;

      h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 104%;
        color: $darkBlue;
      }

      p {
        margin-top: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 104%;
        color: $darkBlue;
      }

      span {
        margin-top: auto;
        font-weight: 700;
        font-size: 14px;
        line-height: 104%;
        color: $darkBlue;
      }
  }

}

.partners {
  margin-top: clamp(1.25rem, calc(0.778rem + 2.36vw), 2.5rem);

  h2 {
    font-weight: 700;
    font-size: clamp(1.5rem, calc(1.288rem + 1.06vw), 2.063rem);
    line-height: 104%;
    color: $darkGreen;
  }

  &__logo {
    margin-top: clamp(0.813rem, calc(0.246rem + 2.83vw), 2.313rem);
    padding: 26px 0;
    background-color: #fffdfe;
    display: flex;
    overflow: auto;
  }

  &__form {
    display: flex;
    align-items: center;
    justify-content: center;

    background: url('../img/form.png') no-repeat;
    background-size: cover;
    background-position: center;
    max-width: 1187px;
    width: 100%;
    height: 175px;
    margin: 29px auto 0;

    @media (max-width: 850px) {
      height: 278px;
    }
  }

  &__form-content {
    padding: 0 clamp(0.688rem, calc(-9.169rem + 18.55vw), 4.375rem) 15px clamp(0rem, calc(-8.353rem + 15.72vw), 3.125rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1187px;
    width: 100%;
    height: 175px;

      @media (max-width: 850px) {
        flex-direction: column;
        justify-content: center;
        align-items: start;
        height: 278px;
        gap: 19px;
      }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: start;

    p {
      display: flex;
      margin-top: 5px;
      font-weight: 400;
      font-size: 13px;
      line-height: 104%;
      text-align: center;
      color: #fff;

      @media (max-width: 850px) {
        flex-direction: column;
      }
    }

    a {
      display: block;
      font-weight: 400;
      font-size: 13px;
      line-height: 104%;
      text-align: center;
      color: #fff;
      text-decoration: underline;
      text-align: start;
    }
  }

  &__left {
    h4 {
      font-weight: 500;
      font-size: 30px;
      line-height: 104%;
      text-transform: uppercase;
      color: #fff;
    }

    p {
      font-weight: 500;
      font-size: 22px;
      line-height: 104%;
      color: #fff;
    }
  }

  &__form-block {
    display: flex;
    gap: 15px;
    max-width: 455px;
    width: 100%;

      input {
        border-radius: 10px;
        width: 100%;
        border: 0;
        background-color: #fff;

        padding: 15px 17px;
        font-weight: 500;
        font-size: 15px;
        line-height: 104%;
        color: #6a6a6a;

          &::placeholder {
            font-weight: 500;
            font-size: 15px;
            line-height: 104%;
            color: #6a6a6a;
          }
      }

      button {
        padding: 18px clamp(0.875rem, calc(0.403rem + 2.36vw), 2.125rem);
        font-weight: 500;
        font-size: 15px;
        line-height: 104%;
        text-align: center;
        color: #000;
        background: #fff;
        border-radius: 10px;

        transition: background-color .3s ease-in-out;

          &:hover {
            background-color: #f0eeee;
          }
      }
  }

}
