.footer {
  margin-top: 20px;

  &__content {
    padding: 34px clamp(0rem, calc(-2.243rem + 4.78vw), 1.25rem) 10px
      clamp(0rem, calc(-2.243rem + 4.78vw), 1.25rem);
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    width: 87px;
    height: 58px;
    img {
      width: 100%;
      height: 100%;

        &:nth-child(2) {
          display: none;
        }
    }
  }

  &__btn {
    padding: 16px clamp(0.938rem, calc(-0.682rem + 6.91vw), 2.125rem);
    border-radius: 10px;
    background: $btnNone;
    border: 1px solid;
    border-color: $btnNone;

    font-weight: 700;
    font-size: 13px;
    line-height: 104%;
    color: $black;
    margin-right: clamp(0rem, calc(-7rem + 16vw), 2.5rem);

    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: $btnNoneHover;
    }
  }

  &__nav {
    display: flex;
    padding: 30px 0;

    @media (max-width: 950px) {
      justify-content: space-between;
    }

    @media (max-width: 650px) {
      padding: 30px 0 0 0;
    }
  }

  &__list {
    display: flex;
    gap: clamp(1.25rem, calc(-9.372rem + 17.89vw), 3.688rem);

    @media (max-width: 950px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px clamp(1.25rem, calc(-17.5rem + 40vw), 6.25rem);
    }

    @media (max-width: 650px) {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      gap: clamp(1.25rem, calc(-0.028rem + 5.45vw), 2.188rem)
        clamp(2.5rem, calc(-1.761rem + 18.18vw), 5.625rem);
    }
  }

  &__block {
    transition: all 0.3s ease-in-out;
    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 104%;
      color: $spanFooter;

      @media (max-width: 650px) {
        color: $black;
      }
    }

    svg {
      visibility: hidden;
      width: 0;
      height: 0;
      transition: all 0.3s ease-in-out;
      fill: $arrowAcc;

      @media (max-width: 650px) {
        visibility: visible;
        width: 11px;
        height: 6px;
      }
    }

    ul {
      max-width: clamp(9.125rem, calc(3.678rem + 9.17vw), 10.375rem);
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 21px;

      li {
        a {
          display: flex;
          width: 100%;
          font-weight: 400;
          font-size: 15px;
          line-height: 104%;
          color: $black;

          transition: all 0.3s ease-in-out;

          @media (max-width: 650px) {
            font-size: 12px;
          }

          &:hover {
            color: #4cb984;
          }
        }
      }

      @media (max-width: 950px) {
        max-width: none;
        width: 170px;
      }

      @media (max-width: 650px) {
        margin-top: 15px;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        transition: all 0.3s ease-in-out;
      }
    }

    @media (max-width: 650px) {
      height: 30px;
    }
  }

  &__accordion {
    @media (max-width: 650px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__info {
    margin-left: clamp(1.25rem, calc(-17.271rem + 31.19vw), 5.5rem);
    margin-top: 5px;

    @media (max-width: 950px) {
      margin-left: 0;
      margin-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media (max-width: 650px) {
      display: none;
    }
  }

  &__blocks {
    &:nth-child(2) {
      margin-top: 33px;
    }
  }

  &__text {
    display: flex;
    gap: 8px;
    margin-bottom: 10px;
    p {
      font-weight: 700;
      font-size: 13px;
      line-height: 104%;
      color: $darkBlue;
    }

    span {
      font-weight: 500;
      font-size: 13px;
      line-height: 104%;
      color: $textFooter;
    }
  }

  &__text-v {
    margin-bottom: 8px;
    p {
      font-weight: 700;
      font-size: 13px;
      line-height: 104%;
      color: $darkBlack;
    }

    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 104%;
      color: $textFooter;
    }
  }

  &__numbers {
    display: flex;
    gap: 12px;

    span {
      display: block;
      width: 1px;
      background-color: #dadada;
      height: 15px;
    }
    a {
      display: flex;
      width: max-content;
      font-weight: 400;
      font-size: 15px;
      line-height: 104%;
      color: $tel;

      transition: all 0.3s ease-in-out;

      &:hover {
        color: #4cb984;
      }
    }
  }

  &__button-end {
    padding: 16px 26px;
    border-radius: 10px;
    background: $btnEnd;
    margin-top: 30px;

    font-weight: 700;
    font-size: 13px;
    line-height: 104%;
    color: $lightGreen;

    transition: all .3s ease-in-out;

    &:hover {
      background: $btnEndHover;
    }
  }

  &__bottom {
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: #e7e8e9;
    }

    @media (max-width: 650px) {
      display: none;
    }
  }

  &__all {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
  }

  &__logos {
    display: flex;
    gap: clamp(0.625rem, calc(-1.057rem + 3.59vw), 1.563rem);
  }

  &__logo-block {
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all .3s ease-in-out;

    p {
      width: 100%;
      font-weight: 500;
      font-size: 13px;
      color: $logoText;
    }

    img {
      width: 22px;
      height: 22px;
    }
  }

  &__block-about {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: clamp(1.813rem, calc(0.063rem + 4vw), 2.438rem);
    height: clamp(1.813rem, calc(0.063rem + 4vw), 2.438rem);
    background-color: white;
    border-radius: 10px;
    border: 1px solid white;

    transition: all .3s ease-in-out;
    svg {
      width: clamp(0.938rem, calc(0.063rem + 2vw), 1.25rem);
      height: clamp(0.938rem, calc(0.063rem + 2vw), 1.25rem);
      transition: all .3s ease-in-out;
      fill: #006A61;

    }

    &:nth-child(1) {
      &:hover {
        svg {
          fill: red;
        }
      }
    }

    &:nth-child(2) {
      &:hover {
        svg {
          fill: #62A2FB;
        }
      }
    }

    &:nth-child(3) {
      &:hover {
        svg {
          fill: #62A2FB;
        }
      }
    }

    &:nth-child(4) {
      &:hover {
        svg {
          fill: rgb(173, 171, 21);
        }
      }
    }

    &:nth-child(5) {
      &:hover {
        svg {
          fill: #37BBFE;
        }
      }
    }

    &:nth-child(6) {
      &:hover {
        svg {
          fill: green;
        }
      }
    }

    &:nth-child(7) {
      &:hover {
        svg {
          fill: black;
        }
      }
    }

  }
}

.activeAccord {
  height: 100%;

  .footer__accordion {
    svg {
      transform: rotate(180deg);
    }
  }

  ul {
    visibility: visible;
    opacity: 1;
    max-height: 100%;
  }
}
