.obtaining {
  margin-top: clamp(1.25rem, calc(0.542rem + 3.54vw), 3.125rem);

  h2 {
    font-weight: 700;
    font-size: clamp(1.5rem, calc(1.288rem + 1.06vw), 2.063rem);
    line-height: 104%;
    color: $darkGreen;
  }

  &__map {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 40px;
    overflow: hidden;
    height: 100%;

      @media (max-width: 1000px) {
        flex-direction: column;

        iframe {
          width: 100%;
        }
      }
  }

  &__top {
    padding: 50px 58px 15px 30px;

    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 104%;
      color: #006a61;
    }

    ul {
      max-width: 282px;
      margin-top: 18px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      li {
        font-weight: 400;
        font-size: 13px;
        line-height: 104%;
        color: #006a61;
      }

      a {
        display: inline-block;
        font-size: 13px;
        line-height: 104%;
        color: #006a61;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }

  &__bottom {
    padding: 15px 58px 50px 30px;
    border-top: 1px solid #006a61;

    @media (max-width: 1000px) {
      padding: 15px 58px 39px 30px;
    }

    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 104%;
      color: #006a61;
    }

    p {
      max-width: 282px;
      margin-top: 18px;
      font-weight: 400;
      font-size: 13px;
      line-height: 104%;
      color: #006a61;
    }

    a {
      margin-top: 10px;
      display: inline-block;
      font-size: 13px;
      line-height: 104%;
      color: #006a61;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  &__map-block {
    min-height: 100%;

      iframe {
        height: 100%;
      }
  }

}
