.noPage {
  display: block;
  background: url('../img/404.jpg') no-repeat;
  background-size: cover;
  width: 100%;
  height: 477px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

    p {
      font-weight: 500;
      font-size: 25px;
      line-height: 104%;
      color: #fff;

      &:nth-child(3) {
        max-width: 394px;
        font-weight: 500;
        font-size: 23px;
        line-height: 104%;
        text-align: center;
        color: #fff;

        a {
          text-decoration: underline;
          font-weight: 500;
          font-size: 23px;
          line-height: 104%;
          text-align: center;
          color: #fff;
        }
      }
    }

    h1 {
      font-weight: 500;
      font-size: 180px;
      line-height: 104%;
      color: #fff;
    }

    form {
      margin-top: 36px;
      display: flex;
      gap: 11px;
      max-width: 392px;
      width: 100%;

        input {
          padding: 14px 27px;
          border-radius: 12px;
          background-color: #fff;
          font-weight: 500;
          font-size: 13px;
          line-height: 104%;
          color: #525252;
          border: 0;
          max-width: 231px;
          width: 100%;
        }

        button {
          border-radius: 12px;
          background-color: #01847c;
          font-weight: 500;
          font-size: 13px;
          line-height: 104%;
          color: #fff;
          padding: 14px 33px;

          transition: all .3s ease-in-out;

          &:hover {
            background-color: #109a8e;
          }
        }

    }

}
