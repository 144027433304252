@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/main/PFDinDisplayPro-LightItalic.eot');
  src: local('PFDinDisplayPro-LightItalic'),
      url('../fonts/main/PFDinDisplayPro-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-LightItalic.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-LightItalic.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/main/PFDinDisplayPro-Medium.eot');
  src: local('PFDinDisplayPro-Medium'),
      url('../fonts/main/PFDinDisplayPro-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-Medium.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-Medium.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PF DinDisplay Pro ExtraThin';
  src: url('../fonts/main/PFDinDisplayPro-ExtraThinItalic.eot');
  src: local('PFDinDisplayPro-ExtraThinItalic'),
      url('../fonts/main/PFDinDisplayPro-ExtraThinItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-ExtraThinItalic.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-ExtraThinItalic.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-ExtraThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/main/PFDinDisplayPro-Bold.eot');
  src: local('PFDinDisplayPro-Bold'),
      url('../fonts/main/PFDinDisplayPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-Bold.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-Bold.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/main/PFDinDisplayPro-ThinItalic.eot');
  src: local('PFDinDisplayPro-ThinItalic'),
      url('../fonts/main/PFDinDisplayPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-ThinItalic.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-ThinItalic.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/main/PFDinDisplayPro-Black.eot');
  src: local('PFDinDisplayPro-Black'),
      url('../fonts/main/PFDinDisplayPro-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-Black.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-Black.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/main/PFDinDisplayPro-BlackItalic.eot');
  src: local('PFDinDisplayPro-BlackItalic'),
      url('../fonts/main/PFDinDisplayPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-BlackItalic.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-BlackItalic.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/main/PFDinDisplayPro-MediumItalic.eot');
  src: local('PFDinDisplayPro-MediumItalic'),
      url('../fonts/main/PFDinDisplayPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-MediumItalic.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-MediumItalic.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/main/PFDinDisplayPro-Light.eot');
  src: local('PFDinDisplayPro-Light'),
      url('../fonts/main/PFDinDisplayPro-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-Light.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-Light.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/main/PFDinDisplayPro-Thin.eot');
  src: local('PFDinDisplayPro-Thin'),
      url('../fonts/main/PFDinDisplayPro-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-Thin.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-Thin.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/main/PFDinDisplayPro-Italic.eot');
  src: local('PFDinDisplayPro-Italic'),
      url('../fonts/main/PFDinDisplayPro-Italic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-Italic.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-Italic.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/main/PFDinDisplayPro-Regular.eot');
  src: local('PFDinDisplayPro-Regular'),
      url('../fonts/main/PFDinDisplayPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-Regular.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-Regular.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/main/PFDinDisplayPro-BoldItalic.eot');
  src: local('PFDinDisplayPro-BoldItalic'),
      url('../fonts/main/PFDinDisplayPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-BoldItalic.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-BoldItalic.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'PF DinDisplay Pro ExtraThin';
  src: url('../fonts/main/PFDinDisplayPro-ExtraThin.eot');
  src: local('PFDinDisplayPro-ExtraThin'),
      url('../fonts/main/PFDinDisplayPro-ExtraThin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/main/PFDinDisplayPro-ExtraThin.woff2') format('woff2'),
      url('../fonts/main/PFDinDisplayPro-ExtraThin.woff') format('woff'),
      url('../fonts/main/PFDinDisplayPro-ExtraThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

