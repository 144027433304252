.about {

  &__banner {
    position: relative;
    background: url('../img/about.png') no-repeat;
    background-size: cover;
    background-position: center;
    max-width: 1200px;
    height: 375px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-left: clamp(0.938rem, calc(-2.07rem + 5.66vw), 2.063rem);
    z-index: 2;

      h1 {
        font-weight: 700;
        font-size: 40px;
        line-height: 104%;
        color: #fff;
      }

      p {
        margin-top: 15px;
        font-weight: 400;
        font-size: 20px;
        line-height: 104%;
        color: #fff;
        max-width: 652px;
      }

      img {
        position: absolute;
        right: clamp(1.313rem, calc(-7.04rem + 15.72vw), 4.438rem);
        bottom: clamp(1.188rem, calc(-0.483rem + 3.14vw), 1.813rem);
        z-index: -1;
      }
  }

  &__info {
    margin-top: clamp(1.875rem, calc(1.333rem + 2.71vw), 3.313rem);

      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 104%;
        text-align: center;
        color: $allegianceColor;
      }

      p {
        margin-top: clamp(2.563rem, calc(2.327rem + 1.18vw), 3.188rem);
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        color: $allegianceColor;
      }
  }

  &__flex {
    margin-top: 42px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    gap: 10px;

      @media (max-width: 850px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 560px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
      }
  }

  &__company {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;

    &:nth-child(1) {
      gap: 21px;
    }

    &:nth-child(3) {
      gap: 34px;
      @media (max-width: 850px) {
        display: none;
      }
    }

    img {
      display: block;
    }

    p {
      margin-top: auto;
      font-weight: 500;
      font-size: clamp(1rem, calc(0.953rem + 0.24vw), 1.125rem);
      line-height: 104%;
      color: $aboutLogo;
    }
  }

  &__group {
    margin-top: clamp(2.188rem, calc(1.834rem + 1.77vw), 3.125rem);
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 104%;
      text-align: center;
      color: $allegianceColor;
    }
  }

  &__company-group {
    margin-top: clamp(0.938rem, calc(0.348rem + 2.95vw), 2.5rem);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 62px;
    align-items: center;

    @media (max-width: 850px) {
      grid-template-columns: repeat(4, 1fr);
      img {
        &:nth-child(+n+5) {
          display: none;
        }
      }
    }

    @media (max-width: 550px) {
      grid-template-columns: repeat(2, 1fr);
      img {
        &:nth-child(+n+3) {
          display: none;
        }
      }
    }
  }

}
