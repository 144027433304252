.notfound {

  &__content {
    display: flex;
    padding-left: calc((100% - 1168px) / 2);

    @media (max-width: 750px) {
      padding-left: 0;
      flex-direction: column;
      align-items: center;
    }

    img {
      width: calc(100% - clamp(23.188rem, calc(11.973rem + 23.92vw), 29.438rem));
      object-fit: cover;

        @media (max-width: 750px) {
          margin-top: 30px;
          min-width: 762px;
          width: 100%;
          object-position: center;
        }
    }
  }

  &__info {
    padding-left: 15px;
    width: clamp(23.188rem, calc(11.973rem + 23.92vw), 29.438rem);
    flex-shrink: 0;
    margin-top: 28px;

      @media (max-width: 750px) {
        width: 100%;
        padding: 0 15px;
      }

    h1 {
      margin-top: 20px;
      font-weight: 700;
      font-size: 32px;
      line-height: 104%;
      color: $darkGreen;
    }

    p {
      margin-top: 20px;
      max-width: 264px;
      font-weight: 500;
      font-size: 18px;
      line-height: 104%;
      color: $black;
    }

  }

  &__top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

      li {
        &:not(:last-child) {
          a {
            &::after {
              content: '-';
              display: block;
            }
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        gap: 5px;

        font-weight: 400;
        font-size: 12px;
        line-height: 104%;
        color: #969fa6;
      }
  }

  &__href {
    display: block;
    margin-top: clamp(0.938rem, calc(0.631rem + 1.53vw), 1.75rem);
    border-radius: 5px;
    padding: 12px 18px;
    font-weight: 500;
    font-size: 14px;
    line-height: 104%;
    text-align: center;
    color: #fff;
    background-color: #006a61;
    width: fit-content;

    transition: all .3s ease-in-out;

      &:hover {
        background-color: #109a8e;
      }
  }

}
