.loyalty {
  margin-top: clamp(0rem, calc(-6.264rem + 10.55vw), 1.438rem);

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 104%;
    color: $cabinetTitle;
  }

  &__line-block {
    margin-top: 9px;
  }

  &__ongoing {
    margin-top: 56px;
    position: relative;
    border-radius: 20px;
    background-color: #00a18b;

    max-width: 820px;
    width: 100%;
    height: 168px;
    padding:
    15px clamp(0.625rem, calc(-0.625rem + 6.25vw), 3.938rem)
    0 clamp(0.625rem, calc(-0.059rem + 3.42vw), 2.438rem);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 20px;
        background: #b4d9d7;
        transform: rotate(4deg);
        width: 100%;
        height: 100%;
        border-radius: 20px;
        z-index: -1;
      }

    h2 {
      font-weight: 500;
      font-size: clamp(0.625rem, calc(0.389rem + 1.18vw), 1.25rem);
      line-height: 104%;
      text-align: center;
      color: #fff;
    }
  }

  &__line {
    position: relative;
    background: linear-gradient(360deg, #c0d7e1 0%, #adcad8 100%);
    height: 28px;
    width: 100%;
    border-radius: 100px;
    overflow: hidden;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 24.62%;
        height: 100%;
        background: linear-gradient(360deg, #36d235 1.5%, #52df48 100%);

        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #94c4c1;
        border-radius: 100px;
        font-weight: 500;
        font-size: clamp(0.5rem, calc(0.311rem + 0.94vw), 1rem);
        line-height: 104%;
        color: #fff;
      }
  }

  &__info-line {
    margin-top: 11px;
    display: grid;
    grid-template-columns: repeat(3, 11fr);
    gap: 10px;

    h3 {
      font-weight: 500;
      font-size: clamp(0.813rem, calc(0.695rem + 0.59vw), 1.125rem);
      line-height: 104%;
      color: #fff;
    }
  }

  &__title {
    p {
      margin-top: 5px;
      font-weight: 500;
      font-size: clamp(0.625rem, calc(0.531rem + 0.47vw), 0.875rem);
      line-height: 104%;
      color: #fff;
    }
  }

  &__cards {
    margin-top: clamp(3.063rem, calc(2.85rem + 1.06vw), 3.625rem);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

      @media (max-width: 850px) {
        grid-template-columns: repeat(1, 1fr);
      }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 850px) {
      align-items: start;
    }

    h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 104%;
      color: $black;
    }

    p {
      height: 100%;
      margin-top: 7px;
      font-weight: 500;
      font-size: 16px;
      line-height: 104%;
      color: $black;
      padding: 20px 32px 20px 20px;
      background-color: $loyaltyCard;
    }
  }

}
