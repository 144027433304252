.news {
  margin-top: 50px;

  h1 {
    font-weight: 700;
    font-size: clamp(1.5rem, calc(1.288rem + 1.06vw), 2.063rem);
    line-height: 104%;
    color: $darkGreen;
  }

  .shares {
    margin-top: 23px;
  }

  &__top {
    display: none;
  }

  &__filters {
    margin-top: clamp(1.25rem, calc(0.708rem + 2.71vw), 2.688rem);
    padding: 30px 18px;
    border-radius: 15px;
    background-color: $backNews;
    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 104%;
      color: $titleFilter;
    }
  }

  &__buttons {
    margin-top: 20px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

      @media (max-width: 750px) {
        grid-template-columns: repeat(2, 1fr);
      }

    button {
      display: block;
      padding: 23px 10px;
      border: 1px solid #e1e1e1;
      background-color: $buttonNews;

      font-weight: 400;
      font-size: 13px;
      line-height: 104%;
      color: $black;

      @media (max-width: 750px) {
        &:nth-child(3) {
          grid-column: 1 / 3;
        }
      }
    }

  }

  &__cards {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__card {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    position: relative;

      img {
        display: block;
      }

      span {
        position: absolute;
        bottom: 15px;
        right: clamp(1.125rem, calc(0.842rem + 1.42vw), 1.875rem);

        border-radius: 100px;
        font-weight: 500;
        font-size: 13px;
        line-height: 104%;
        color: #000;
        background: #fff;
        padding: 6px 16px;
      }
  }

  &__info {
    width: calc(100% - clamp(1.25rem, calc(-11.447rem + 23.9vw), 6rem));
    background: $buttonNews;
    border-radius: 0 0 15px 15px;

    @media (max-width: 850px) {
      background: transparent;
      width: 100%;
    }

      span {
        display: block;
        width: fit-content;
        position: relative;
        font-weight: 500;
        font-size: clamp(0.625rem, calc(0.507rem + 0.59vw), 0.938rem);
        line-height: 104%;
        color: #fff;
        background: #fe8b2c;
        border-radius: 10px;
        padding: 7px 20px;
        margin-top: -21px;
        margin-left: 31px;
      }

      h3 {
        margin-top: clamp(0.688rem, calc(0.287rem + 2vw), 1.75rem);
        font-weight: 500;
        font-size: clamp(0.875rem, calc(0.639rem + 1.18vw), 1.5rem);
        line-height: 104%;
        color: $darkBlue;
        padding: 0 37px 15px;
      }
  }

  &__form {
    margin-top: clamp(1.75rem, calc(1.255rem + 2.48vw), 3.063rem);
  }

  .activeButton {
    border: 1px solid #f9d359;
  }

}

