:root {
  --headerColor: #f9f9fb;
  --headerColorTwo: #eaf8ef;
  --headerColorThree: #fff;
  --headerLink: #525252;
  --menu: #ebebeb;
  --white: white;
  --darkBlue: #012321;
  --mainColor: #ffffff;
  --darkGreen: #006a61;
  --whiteButton: white;
  --black: black;
  --lightWhiteButton: #efefef;
  --lightFeedback: #f5f5f5;
  --darkBlack: #3e3e3e;
  --tagColor: white;
  --tagText: #969fa6;
  --tagBack: #f7f8f9;
  --titleFilter: #404040;
  --borderFilter: #e1e1e1;
  --filterBack: white;
  --cardBack: #efefef;
  --listCabinetA: #012321;
  --panel: linear-gradient(180deg, #fafafc 0%, #fff 100%);
  --activePanel: #006a61;
  --cabinetTitle: #2a2a2a;
  --colorTable: #626262;
  --buttonTable: #d1d4cd;
  --buttonColorTable: #553331;
  --orderButton: #f7f8f9;
  --mobAccardion: #f6f8fa;
  --loyaltyCard: #e8e8e8;
  --buttonNews: white;
  --backNews: #fafafa;
  --lightGreen: #4cb984;
  --allegianceColor: #525252;
  --textStatus: #6b6b6b;
  --titleoutlet: #2d2d2d;
  --payBack: #6ab9b4;
  --payB: #fafafc;
  --colorPay: #525252;
  --colorCart: #606060;
  --backCard: #f5f5f5;
  --colorReviews: #525252;
  --colorAdd: #525252;
  --line: #e5eaee;
  --dd: #525252;
  --btnNone: #f2f1f5;
  --btnNoneHover: #f0eeee;
  --btnBorder: none;
  --spanFooter: #5e5e5e;
  --tel: #012321;
  --textFooter: #989898;
  --btnEnd: #eaf8ef;
  --btnEndHover: #d3fce8;
  --lightGreen: #4cb984;
  --logoText: #525252;
  --aboutLogo: #525252;
  --titlePlacement: #2d2d2d;
  --backPlacement: #f6f8fb;
  --orderPlacement: white;
  --svgPlacement: #9da8b4;
  --arrowAcc: black;
  --counter: #f7f7f7;
  --counterText: #7c8f7c;
  --basket1: #eee;
}

$basket1: var(--basket1);
$counterText: var(--counterText);
$counter: var(--counter);
$svgPlacement: var(--svgPlacement);
$orderPlacement: var(--orderPlacement);
$backPlacement: var(--backPlacement);
$titlePlacement: var(--titlePlacement);
$aboutLogo: var(--aboutLogo);
$headerColor: var(--headerColor);
$headerColorTwo: var(--headerColorTwo);
$headerColorThree: var(--headerColorThree);
$headerLink: var(--headerLink);
$menu: var(--menu);
$dd: var(--dd);
$line: var(--line);
$colorAdd: var(--colorAdd);
$colorReviews: var(--colorReviews);
$backCard: var(--backCard);
$colorCart: var(--colorCart);
$colorPay: var(--colorPay);
$payB: var(--payB);
$payBack: var(--payBack);
$titleoutlet: var(--titleoutlet);
$textStatus: var(--textStatus);
$allegianceColor: var(--allegianceColor);
$backNews: var(--backNews);
$buttonNews: var(--buttonNews);
$loyaltyCard: var(--loyaltyCard);
$mobAccardion: var(--mobAccardion);
$orderButton: var(--orderButton);
$buttonColorTable: var(--buttonColorTable);
$buttonTable: var(--buttonTable);
$white: var(--white);
$darkBlue: var(--darkBlue);
$mainColor: var(--mainColor);
$darkGreen: var(--darkGreen);
$whiteButton: var(--whiteButton);
$black: var(--black);
$lightWhiteButton: var(--lightWhiteButton);
$lightFeedback: var(--lightFeedback);
$darkBlack: var(--darkBlack);
$tagColor: var(--tagColor);
$tagText: var(--tagText);
$tagBack: var(--tagBack);
$titleFilter: var(--titleFilter);
$borderFilter: var(--borderFilter);
$filterBack: var(--filterBack);
$cardBack: var(--cardBack);
$listCabinetA: var(--listCabinetA);
$panel: var(--panel);
$activePanel: var(--activePanel);
$cabinetTitle: var(--cabinetTitle);
$colorTable: var(--colorTable);
$btnNone: var(--btnNone);
$btnNoneHover: var(--btnNoneHover);
$btnBorder: var(--btnBorder);
$spanFooter: var(--spanFooter);
$tel: var(--tel);
$textFooter: var(--textFooter);
$btnEnd: var(--btnEnd);
$btnEndHover: var(--btnEndHover);
$lightGreen: var(--lightGreen);
$logoText: var(--logoText);
$arrowAcc: var(--arrowAcc);

.dark {
  color-scheme: dark;

  --basket1: #444;
  --counterText: #c5c5c5;
  --counter: #444;
  --svgPlacement: #c5c5c5;
  --orderPlacement: #444;
  --backPlacement: #272727;
  --titlePlacement: white;
  --aboutLogo: #bbb;
  --headerColor: linear-gradient(180deg, #0b0b0b 0%, #111 100%);
  --headerColorTwo: linear-gradient(180deg, #0b0b0b 0%, #111 100%);
  --headerColorThree: linear-gradient(180deg, #292929 0%, #3c3c3c 100%);
  --headerLink: #808080;
  --menu: linear-gradient(180deg, #1a1a1a 0%, #111 100%);
  --dd: #d4d4d4;
  --line: #b7b7b7;
  --colorAdd: #e0e0e0;
  --colorReviews: #d7d7d7;
  --payB: #1a1a1a;
  --payBack: #d0d0d0;
  --white: #1a1a1a;
  --darkBlue: white;
  --mainColor: #030000;
  --darkGreen: white;
  --whiteButton: linear-gradient(180deg, #222 0%, #4b4b4b 100%);
  --black: white;
  --lightWhiteButton: linear-gradient(180deg, #8b8b8b 0%, #4b4b4b 100%);
  --lightFeedback: #343434;
  --darkBlack: white;
  --tagColor: #454545;
  --tagText: #e8e8e8;
  --tagBack: #303030;
  --titleFilter: white;
  --borderFilter: #636363;
  --filterBack: #303030;
  --cardBack: #454545;
  --listCabinetA: #f8f8f8;
  --panel: linear-gradient(180deg, #0b0b0b 0%, #101010 100%);
  --activePanel:  #b5b5b5;
  --cabinetTitle: white;
  --colorTable: white;
  --buttonTable: #212121;
  --buttonColorTable: white;
  --orderButton: #2f2f2f;
  --mobAccardion: #303030;
  --loyaltyCard: #303030;
  --buttonNews: #2f2f2f;
  --backNews: #303030;
  --allegianceColor: white;
  --textStatus: #c3c3c3;
  --titleoutlet: white;
  --colorPay: black;
  --colorCart: #d0d0d0;
  --backCard: #1a1a1a;
  --btnNone: none;
  --btnNoneHover: #6e6e6e;
  --btnBorder: #6a6a6a;
  --spanFooter: #8e8e8e;
  --tel: white;
  --textFooter: #ababab;
  --btnEnd: linear-gradient(180deg, #1b1b1b 0%, #1c1c1c 100%);
  --btnEndHover: linear-gradient(180deg, #1d382f 0%, #1d382f 100%);
  --lightGreen: white;
  --logoText: white;
  --arrowAcc: white;

  .header__logo, .footer__logo {
    img {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: block;
      }
    }
  }

    .cabinet__card-program {
      box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.3);
        &::after {
          box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.3);
          mix-blend-mode: color;
        }
    }

    .delivery__pay {
      img {
        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2) {
          display: block;
        }
      }
    }

    .contacts__ofice,  .contacts__warehouse  {
      img {
        &:nth-child(2) {
          display: block;
        }

        &:nth-child(1) {
          display: none;
        }
      }
    }

    .placement__buttons-click {
      button {
        img {
          &:nth-child(1) {
            display: none;
          }

          &:nth-child(2) {
            display: block;
          }
        }
      }
    }

}
