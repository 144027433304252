.delivery {

  &__banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: clamp(0.938rem, calc(-0.064rem + 2.91vw), 2.063rem);

    background: url('../img/delivery.png') no-repeat;
    background-size: cover;
    background-position: center;
    max-width: 1200px;
    width: 100%;
    height: 375px;

    h1 {
      font-weight: 700;
      font-size: 40px;
      line-height: 104%;
      color: #fff;
    }

    p {
      margin-top: 15px;
      font-weight: 400;
      font-size: 20px;
      line-height: 104%;
      color: #fff;
      max-width: clamp(20.375rem, calc(2.242rem + 52.75vw), 40.75rem);
    }
  }

  &__content {
    p {
      margin-top: 49px;
      max-width: 971px;
      font-weight: 500;
      font-size: 16px;
      line-height: 104%;
      color: $black;

        @media (max-width: 850px) {
          margin-top: 30px;
        }
    }
  }

  &__tabs-content {
    display: flex;
    flex-direction: column;

    @media (max-width: 850px) {
      margin-top: 30px;
      gap: 10px;
    }
  }

  &__tab-content-block {

    button {
      display: none;

      @media (max-width: 850px) {
        display: flex;
        width: 100%;
      }
    }

  }

  &__way {
    margin-top: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 104%;
      color: $allegianceColor;
    }
  }

  &__buttons {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 9px;

    @media (max-width: 850px) {
      display: none;
    }
  }

  &__button-tab {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 17px;
    padding: 7px;
    text-align: start;

    border: 1px solid #626262;
    background: #fff;
    border-radius: 10px;
    height: 102px;
    color: $colorPay;
    font-weight: 400;
    font-size: 16px;
    line-height: 104%;
  }

  &__price {
    margin-top: 30px;
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 104%;
      color: $allegianceColor;
    }
  }

  &__table-block {
    @media (max-width: 420px) {
      overflow: auto;
      width: 100%;
    }
  }

  &__table {
    margin-top: 30px;
    border-collapse: collapse;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    width: 100%;

    @media (max-width: 420px) {
      width: 600px;
    }

    tr {
      transition: all .2s ease-in-out;
      &:nth-child(1) {
        background-color: #ebd6b5;
        th {
          padding: 11px clamp(0.313rem, calc(-0.522rem + 2.43vw), 1.25rem);
          border: 0;
          font-weight: 700;
          font-size: 16px;
          line-height: 104%;
          color: #6b6a67;
        }
      }

      &:hover {
        .delivery__white {
          background-color: #ddeeed;
        }

        .delivery__green {
          background-color: #69c36a;
        }

        .delivery__yellow {
          background-color: #dfd980;
        }

        .delivery__gray {
          background-color: #b5c8c1;;
        }
      }

      td, th {
        padding: 12px clamp(0.313rem, calc(-0.522rem + 2.43vw), 1.25rem);
        border: 1px solid #7c7c7c;
        font-weight: 500;
        font-size: 15px;
        line-height: 104%;
        color: #000;
      }
    }

  }

  &__white {
    background-color: white;
    transition: all .2s ease-in-out;
  }

  &__green {
    background-color: #7bcd67;
    transition: all .2s ease-in-out;
  }

  &__yellow {
    background-color: #ffe782;
    transition: all .2s ease-in-out;
  }

  &__gray {
    transition: all .2s ease-in-out;
    background-color: #d1d4cd;
  }

  &__map {
    margin-top: 29px;

    @media (max-width: 1100px) {
      width: calc(100% + 30px);
      margin-left: -15px;
    }
  }

  &__pay-p {
    margin-top: 30px;

      h5 {
        font-weight: 500;
        font-size: 24px;
        line-height: 104%;
        color: $allegianceColor;
      }
  }

  &__pay-block {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 28px;

    @media (max-width: 850px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__pay {
    display: flex;
    align-items: center;
    gap: 30px;
    border-radius: 10px;

    font-weight: 500;
    font-size: 16px;
    line-height: 104%;
    color: $allegianceColor;
    padding: 23px 20px;

    border: 1px solid $payBack;
    background-color: $payB;

      img {
        &:nth-child(2) {
          display: none;
        }
      }
  }

  .activeTab {
    border: 2px solid #006a61;
  }

  .hiddenTab {
    display: none;
  }

}
