@import "./vars";
@import "./global/global";
@import "./fonts";

// main

.container {
  max-width: 1168px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.main {
  flex: 1 1 auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100%;
}


 // main
 @import './components/header';
 @import './components/footer';

 @import './components/basketModal';
 @import './components/basket';

 // blocks

 @import './components/mainPage/home';
 @import './components/mainPage//shares';
 @import './components/mainPage/exclusives';
 @import './components/mainPage/feedback';
 @import './components/mainPage/obtaining';


 // catalogPage

 @import './components/catalogPage/tags';


// cabinet

.personal-cabinet {
  margin-top: 50px;
  &__main {
    display: flex;
    gap: 54px;
  }
}

@import './components/panel/panel';
@import './components/cabinetPage/cabinetPage';
@import './components/cabinetPage/myorder';


// ordersPage

@import './components/ordersPage/orders';


// loyaltyPage

@import './components/loyaltyPage/loyaltyPage';

// newsPage

@import './components/newsPage/newsPage';

// foundPage

@import './components/foundPage/foundPage';

// notfoundPage

@import './components/notfoundPage/notfoundPage';

// allegiancePage

@import './components/allegiancePage/allegiancePage';

// outletPage

@import './components/outletPage/outletPage';

// deliveryPage

@import './components/deliveryPage/deliveryPage';

// cartPage

@import './components/cartPage/cartPage';

// contactsPage

@import './components/contactPage/contactPage';

// aboutPage

@import './components/aboutPage/aboutPage';


// designPage

@import './components/placementPage/placementPage';


// 404

@import './components/404Page/404Page';
