:root {
  --headerColor: #f9f9fb;
  --headerColorTwo: #eaf8ef;
  --headerColorThree: #fff;
  --headerLink: #525252;
  --menu: #ebebeb;
  --white: white;
  --darkBlue: #012321;
  --mainColor: #ffffff;
  --darkGreen: #006a61;
  --whiteButton: white;
  --black: black;
  --lightWhiteButton: #efefef;
  --lightFeedback: #f5f5f5;
  --darkBlack: #3e3e3e;
  --tagColor: white;
  --tagText: #969fa6;
  --tagBack: #f7f8f9;
  --titleFilter: #404040;
  --borderFilter: #e1e1e1;
  --filterBack: white;
  --cardBack: #efefef;
  --listCabinetA: #012321;
  --panel: linear-gradient(180deg, #fafafc 0%, #fff 100%);
  --activePanel: #006a61;
  --cabinetTitle: #2a2a2a;
  --colorTable: #626262;
  --buttonTable: #d1d4cd;
  --buttonColorTable: #553331;
  --orderButton: #f7f8f9;
  --mobAccardion: #f6f8fa;
  --loyaltyCard: #e8e8e8;
  --buttonNews: white;
  --backNews: #fafafa;
  --lightGreen: #4cb984;
  --allegianceColor: #525252;
  --textStatus: #6b6b6b;
  --titleoutlet: #2d2d2d;
  --payBack: #6ab9b4;
  --payB: #fafafc;
  --colorPay: #525252;
  --colorCart: #606060;
  --backCard: #f5f5f5;
  --colorReviews: #525252;
  --colorAdd: #525252;
  --line: #e5eaee;
  --dd: #525252;
  --btnNone: #f2f1f5;
  --btnNoneHover: #f0eeee;
  --btnBorder: none;
  --spanFooter: #5e5e5e;
  --tel: #012321;
  --textFooter: #989898;
  --btnEnd: #eaf8ef;
  --btnEndHover: #d3fce8;
  --lightGreen: #4cb984;
  --logoText: #525252;
  --aboutLogo: #525252;
  --titlePlacement: #2d2d2d;
  --backPlacement: #f6f8fb;
  --orderPlacement: white;
  --svgPlacement: #9da8b4;
  --arrowAcc: black;
  --counter: #f7f7f7;
  --counterText: #7c8f7c;
  --basket1: #eee;
}

.dark {
  color-scheme: dark;
  --basket1: #444;
  --counterText: #c5c5c5;
  --counter: #444;
  --svgPlacement: #c5c5c5;
  --orderPlacement: #444;
  --backPlacement: #272727;
  --titlePlacement: white;
  --aboutLogo: #bbb;
  --headerColor: linear-gradient(180deg, #0b0b0b 0%, #111 100%);
  --headerColorTwo: linear-gradient(180deg, #0b0b0b 0%, #111 100%);
  --headerColorThree: linear-gradient(180deg, #292929 0%, #3c3c3c 100%);
  --headerLink: #808080;
  --menu: linear-gradient(180deg, #1a1a1a 0%, #111 100%);
  --dd: #d4d4d4;
  --line: #b7b7b7;
  --colorAdd: #e0e0e0;
  --colorReviews: #d7d7d7;
  --payB: #1a1a1a;
  --payBack: #d0d0d0;
  --white: #1a1a1a;
  --darkBlue: white;
  --mainColor: #030000;
  --darkGreen: white;
  --whiteButton: linear-gradient(180deg, #222 0%, #4b4b4b 100%);
  --black: white;
  --lightWhiteButton: linear-gradient(180deg, #8b8b8b 0%, #4b4b4b 100%);
  --lightFeedback: #343434;
  --darkBlack: white;
  --tagColor: #454545;
  --tagText: #e8e8e8;
  --tagBack: #303030;
  --titleFilter: white;
  --borderFilter: #636363;
  --filterBack: #303030;
  --cardBack: #454545;
  --listCabinetA: #f8f8f8;
  --panel: linear-gradient(180deg, #0b0b0b 0%, #101010 100%);
  --activePanel: #b5b5b5;
  --cabinetTitle: white;
  --colorTable: white;
  --buttonTable: #212121;
  --buttonColorTable: white;
  --orderButton: #2f2f2f;
  --mobAccardion: #303030;
  --loyaltyCard: #303030;
  --buttonNews: #2f2f2f;
  --backNews: #303030;
  --allegianceColor: white;
  --textStatus: #c3c3c3;
  --titleoutlet: white;
  --colorPay: black;
  --colorCart: #d0d0d0;
  --backCard: #1a1a1a;
  --btnNone: none;
  --btnNoneHover: #6e6e6e;
  --btnBorder: #6a6a6a;
  --spanFooter: #8e8e8e;
  --tel: white;
  --textFooter: #ababab;
  --btnEnd: linear-gradient(180deg, #1b1b1b 0%, #1c1c1c 100%);
  --btnEndHover: linear-gradient(180deg, #1d382f 0%, #1d382f 100%);
  --lightGreen: white;
  --logoText: white;
  --arrowAcc: white;
}
.dark .header__logo img:first-child, .dark .footer__logo img:first-child {
  display: none;
}
.dark .header__logo img:last-child, .dark .footer__logo img:last-child {
  display: block;
}
.dark .cabinet__card-program {
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.3);
}
.dark .cabinet__card-program::after {
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.3);
  mix-blend-mode: color;
}
.dark .delivery__pay img:nth-child(1) {
  display: none;
}
.dark .delivery__pay img:nth-child(2) {
  display: block;
}
.dark .contacts__ofice img:nth-child(2), .dark .contacts__warehouse img:nth-child(2) {
  display: block;
}
.dark .contacts__ofice img:nth-child(1), .dark .contacts__warehouse img:nth-child(1) {
  display: none;
}
.dark .placement__buttons-click button img:nth-child(1) {
  display: none;
}
.dark .placement__buttons-click button img:nth-child(2) {
  display: block;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
}

img {
  max-width: 100%;
}

video {
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
}

body {
  height: 100%;
  position: relative;
  line-height: 1.5;
  min-width: 320px;
  font-family: "PF DinDisplay Pro";
  background-color: var(--mainColor);
  transition: all 0.3s ease-in-out;
}

button {
  outline: none;
  border: 0;
  cursor: pointer;
  background: 0;
  font-family: "PF DinDisplay Pro";
}

input, textarea {
  background-color: transparent;
  outline: none;
  font-family: "PF DinDisplay Pro";
}

ul,
ol {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  font-family: "PF DinDisplay Pro";
}

button {
  padding: 0;
  cursor: pointer;
  border: 0;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

* {
  -webkit-tap-highlight-color: transparent;
}

::selection {
  color: white;
  background-color: #4abde3;
}

:root {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("../fonts/main/PFDinDisplayPro-LightItalic.eot");
  src: local("PFDinDisplayPro-LightItalic"), url("../fonts/main/PFDinDisplayPro-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-LightItalic.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-LightItalic.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("../fonts/main/PFDinDisplayPro-Medium.eot");
  src: local("PFDinDisplayPro-Medium"), url("../fonts/main/PFDinDisplayPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-Medium.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-Medium.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "PF DinDisplay Pro ExtraThin";
  src: url("../fonts/main/PFDinDisplayPro-ExtraThinItalic.eot");
  src: local("PFDinDisplayPro-ExtraThinItalic"), url("../fonts/main/PFDinDisplayPro-ExtraThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-ExtraThinItalic.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-ExtraThinItalic.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-ExtraThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("../fonts/main/PFDinDisplayPro-Bold.eot");
  src: local("PFDinDisplayPro-Bold"), url("../fonts/main/PFDinDisplayPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-Bold.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-Bold.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("../fonts/main/PFDinDisplayPro-ThinItalic.eot");
  src: local("PFDinDisplayPro-ThinItalic"), url("../fonts/main/PFDinDisplayPro-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-ThinItalic.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-ThinItalic.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("../fonts/main/PFDinDisplayPro-Black.eot");
  src: local("PFDinDisplayPro-Black"), url("../fonts/main/PFDinDisplayPro-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-Black.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-Black.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("../fonts/main/PFDinDisplayPro-BlackItalic.eot");
  src: local("PFDinDisplayPro-BlackItalic"), url("../fonts/main/PFDinDisplayPro-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-BlackItalic.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-BlackItalic.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("../fonts/main/PFDinDisplayPro-MediumItalic.eot");
  src: local("PFDinDisplayPro-MediumItalic"), url("../fonts/main/PFDinDisplayPro-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-MediumItalic.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-MediumItalic.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("../fonts/main/PFDinDisplayPro-Light.eot");
  src: local("PFDinDisplayPro-Light"), url("../fonts/main/PFDinDisplayPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-Light.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-Light.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("../fonts/main/PFDinDisplayPro-Thin.eot");
  src: local("PFDinDisplayPro-Thin"), url("../fonts/main/PFDinDisplayPro-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-Thin.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-Thin.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("../fonts/main/PFDinDisplayPro-Italic.eot");
  src: local("PFDinDisplayPro-Italic"), url("../fonts/main/PFDinDisplayPro-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-Italic.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-Italic.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("../fonts/main/PFDinDisplayPro-Regular.eot");
  src: local("PFDinDisplayPro-Regular"), url("../fonts/main/PFDinDisplayPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-Regular.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-Regular.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PF DinDisplay Pro";
  src: url("../fonts/main/PFDinDisplayPro-BoldItalic.eot");
  src: local("PFDinDisplayPro-BoldItalic"), url("../fonts/main/PFDinDisplayPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-BoldItalic.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-BoldItalic.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "PF DinDisplay Pro ExtraThin";
  src: url("../fonts/main/PFDinDisplayPro-ExtraThin.eot");
  src: local("PFDinDisplayPro-ExtraThin"), url("../fonts/main/PFDinDisplayPro-ExtraThin.eot?#iefix") format("embedded-opentype"), url("../fonts/main/PFDinDisplayPro-ExtraThin.woff2") format("woff2"), url("../fonts/main/PFDinDisplayPro-ExtraThin.woff") format("woff"), url("../fonts/main/PFDinDisplayPro-ExtraThin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
.container {
  max-width: 1168px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.main {
  flex: 1 1 auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100%;
}

.header {
  background: var(--white);
  padding: 6px 0 17px 0;
  box-shadow: 0 -5px 19px 0 rgba(0, 0, 0, 0.25);
}
.header__content {
  position: relative;
  display: flex;
  flex-direction: column;
}
.header__top {
  display: flex;
  align-items: center;
}
.header__logo {
  width: 105px;
  height: 64px;
}
.header__logo img {
  width: 100%;
  height: clamp(1.875rem, -6.625rem + 42.5vw, 4rem);
}
@media (max-width: 650px) {
  .header__logo {
    display: flex;
    align-items: center;
  }
}
.header__form {
  margin-left: clamp(1.25rem, -5.313rem + 11.67vw, 3.438rem);
}
@media (max-width: 650px) {
  .header__form {
    margin-left: 10px;
  }
}
.header__search {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
  width: clamp(15rem, -25.5rem + 72vw, 28.5rem);
  height: 47px;
  background: var(--headerColor);
  padding: 13px clamp(0.625rem, 0.019rem + 3.03vw, 1.25rem);
}
.header__search input {
  width: 100%;
  border: 0;
  font-weight: 400;
  font-size: 16px;
  color: #9f9ea3;
}
@media (max-width: 900px) {
  .header__search {
    margin-right: 10px;
  }
}
@media (max-width: 650px) {
  .header__search {
    width: clamp(2.5rem, 1.591rem + 4.55vw, 3.438rem);
    display: block;
  }
  .header__search input {
    display: none;
  }
}
.header__info {
  display: flex;
  align-items: center;
  margin-left: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
  height: 47px;
  background: var(--headerColor);
  padding: 6px clamp(0.313rem, 0.009rem + 1.52vw, 0.625rem) 5px clamp(0.313rem, 0.009rem + 1.52vw, 0.625rem);
}
@media (max-width: 900px) {
  .header__info {
    margin-left: auto;
  }
}
.header__locality {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header__locality span {
  width: max-content;
  font-weight: 400;
  font-size: clamp(0.625rem, 0.443rem + 0.91vw, 0.813rem);
  color: #9f9ea3;
  transition: all 0.3s ease-in-out;
}
.header__locality svg {
  transition: all 0.3s ease-in-out;
  fill: #9f9ea3;
}
.header__locality:hover span {
  color: #ff7306;
}
.header__locality:hover svg {
  fill: #ff7306;
}
.header__basket svg {
  width: clamp(0.938rem, 0.634rem + 1.52vw, 1.25rem);
  transition: all 0.3s ease-in-out;
  fill: #9f9ea3;
}
.header__basket:hover svg {
  fill: #ff7306;
}
.header__favorites svg {
  width: clamp(0.688rem, 0.445rem + 1.21vw, 0.938rem);
  transition: all 0.3s ease-in-out;
  fill: #9f9ea3;
}
.header__favorites:hover svg {
  fill: #ff7306;
}
.header__line {
  width: 1px;
  height: 15px;
  background-color: #dadada;
  margin: 0 clamp(0.625rem, 0.322rem + 1.52vw, 0.938rem);
}
.header__person {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 278px;
  border-radius: 10px;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  background: var(--headerColorTwo);
  height: 47px;
  padding: 7px 10px;
}
@media (max-width: 900px) {
  .header__person {
    display: none;
  }
}
.header__reg {
  max-width: 200px;
  width: 100%;
  color: var(--lightGreen);
  height: 15px;
  transition: all 0.3s ease-in-out;
}
.header__reg:hover {
  color: #ff7306;
}
.header__sale {
  max-width: 200px;
  width: 100%;
  color: var(--lightGreen);
  height: 15px;
}
.header__reg {
  max-width: max-content;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  gap: 5px;
  align-items: baseline;
  transition: all 0.3s ease-in-out;
}
.header__reg svg {
  transition: all 0.3s ease-in-out;
  fill: var(--lightGreen);
}
@media (max-width: 700px) {
  .header__reg svg {
    display: none;
  }
}
.header__reg:hover {
  color: #ff7306;
}
.header__reg:hover svg {
  fill: #ff7306;
}
.header__sale {
  font-weight: 400;
  font-size: 13px;
}
.header__person-block, .header__mobile-block {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
  width: clamp(2.313rem, 1.706rem + 3.03vw, 2.938rem);
  height: 47px;
  background: var(--headerColorThree);
  box-shadow: -8px 0 8px -8px rgba(76, 185, 132, 0.17);
}
.header__mobile {
  display: none;
  margin-left: auto;
  padding-left: 10px;
}
@media (max-width: 900px) {
  .header__mobile {
    display: block;
  }
}
.header__mobile-block {
  position: relative;
  box-shadow: 0 1px 8px 0 rgba(1, 35, 33, 0.1);
}
.header__bottom {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
@media (max-width: 900px) {
  .header__bottom {
    display: none;
  }
}
.header__btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 23px 12px 10px;
  border-radius: 10px;
}
.header__btn span {
  font-weight: 500;
  font-size: 13px;
  color: var(--darkGreen);
  transition: all 0.3s ease-in-out;
}
.header__icon {
  width: 15px;
  height: 15px;
  display: flex;
  gap: 1px;
  flex-wrap: wrap;
}
.header__icon-block {
  width: 7px;
  height: 7px;
  transition: all 0.3s ease-in-out;
  background: var(--darkGreen);
}
.header__nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.header__nav ul {
  display: flex;
  gap: 15px;
}
.header__nav-link {
  font-weight: 500;
  font-size: 16px;
  color: var(--headerLink);
  transition: all 0.3s ease-in-out;
}
.header__nav-link:hover {
  color: #4cb984;
}
.header__stock {
  font-weight: 500;
  font-size: 16px;
  color: #ff7306;
}
.header__stock span {
  font-weight: 500;
  font-size: 16px;
  color: #e3a360;
}

.menu {
  display: flex;
  position: absolute;
  top: 110px;
  opacity: 0;
  visibility: hidden;
  left: 0;
  box-shadow: 3px 4px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  transition: all 0.3s ease-in-out;
  height: 530px;
  z-index: -100;
}
.menu__tabs {
  background: var(--menu);
  border-radius: 9px 0 0 9px;
}
.menu__nav {
  width: clamp(12.5rem, 0.313rem + 21.67vw, 16.563rem);
}
.menu__tab-btn {
  cursor: pointer;
  padding: 14px 19px;
}
.menu__tab-btn button {
  font-weight: 500;
  font-size: 15px;
  color: var(--logoText);
  text-align: left;
}
.menu__tab-btn:first-child {
  border-radius: 9px 0 0 0;
}
.menu__content {
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: 0 9px 9px 0;
}
.menu__top {
  width: clamp(37.188rem, -18.313rem + 98.67vw, 55.688rem);
  display: flex;
}
.menu__content-box {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  transition: opacity 0.3s ease-in-out;
}
.menu__content-nav ul {
  width: clamp(13.125rem, 3rem + 18vw, 16.5rem);
}
.menu__box-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 19px;
  font-weight: 500;
  font-size: 15px;
  color: var(--logoText);
  text-align: left;
}
.menu__box-link a {
  width: 100%;
  color: var(--logoText);
  transition: color 0.3s ease-in-out;
  pointer-events: none;
}
.menu__box-link a:hover {
  color: #4cb984;
}
.menu__box-link svg {
  fill: #525252;
  transition: color 0.3s ease-in-out;
}
.menu__box-link span {
  transition: color 0.3s ease-in-out;
}
.menu__next {
  cursor: pointer;
}
.menu__next:hover a {
  color: #4cb984;
}
.menu__next:hover span {
  color: #4cb984;
}
.menu__next:hover svg {
  fill: #428b80;
}
.menu__img {
  padding: 0 16px 7px 16px;
  margin-top: auto;
}
.menu__img img {
  width: 777px;
  height: 152px;
}
.menu__links {
  display: flex;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  transition: opacity 0.3s ease-in-out;
}
.menu__links:nth-child(odd) {
  transform: translateY(47px);
}
.menu__links:nth-child(even) {
  transform: translateY(98px);
}
.menu__links ul {
  width: clamp(9.375rem, -1.688rem + 19.67vw, 13.063rem);
}
.menu__links ul li {
  padding: 14px 19px;
  height: 50.5px;
}
.menu__links ul li a {
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: clamp(0.813rem, 0.438rem + 0.67vw, 0.938rem);
  color: #acacac;
  transition: color 0.3s ease-in-out;
  pointer-events: none;
}
.menu__links ul li a:hover {
  color: #4cb984;
}

.burger {
  display: none;
}
@media (max-width: 900px) {
  .burger {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}
.burger__content {
  display: flex;
  align-items: center;
  position: relative;
}
.burger__btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 23px 12px 10px;
  border-radius: 10px;
}
.burger__btn span {
  font-weight: 500;
  font-size: 13px;
  color: var(--darkGreen);
  transition: all 0.3s ease-in-out;
}
.burger__icon {
  width: 15px;
  height: 15px;
  display: flex;
  gap: 1px;
  flex-wrap: wrap;
}
.burger__icon-block {
  width: 7px;
  height: 7px;
  transition: all 0.3s ease-in-out;
  background: var(--darkGreen);
}
.burger__menu {
  position: relative;
}
.burger__spans {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  gap: 6px;
  width: 47px;
  height: 47px;
  padding-right: 10px;
  border-radius: 10px;
  background: var(--headerColor);
  box-shadow: 0 1px 8px 0 rgba(1, 35, 33, 0.1);
  z-index: 100;
}
.burger__spans span {
  background-color: var(--darkGreen);
  height: 3px;
  border-radius: 10px;
}
.burger__spans span:nth-child(1) {
  width: 19px;
}
.burger__spans span:nth-child(2) {
  width: 26px;
}
.burger__spans span:nth-child(3) {
  width: 9px;
}
.burger__nav {
  position: absolute;
  width: 308px;
  background: var(--menu);
  top: 45px;
  opacity: 0;
  visibility: 0;
  transition: all 0.3s ease-in-out;
  right: -14px;
  box-shadow: -3px -4px 15px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  z-index: -100;
  overflow: hidden;
}
.burger__nav ul {
  padding-bottom: 30px;
}
.burger__nav ul li {
  padding: 15px 20px;
  text-align: end;
}
.burger__nav ul li a {
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: var(--headerLink);
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}
.burger__nav ul li a:hover {
  color: #006a61;
}
.burger__exit {
  text-align: end;
  padding: 15px 20px;
  background: var(--headerColorThree);
}
.burger__exit span {
  font-weight: 500;
  font-size: 16px;
  text-align: right;
  color: #ff7306;
}
.burger__catalog {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  top: 45px;
  background: var(--menu);
  border-radius: 10px;
  z-index: 900;
  box-shadow: -3px -4px 15px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.3s ease-in-out;
}
.burger__ul {
  width: 300px;
  padding-bottom: 40px;
}
.burger__ul li {
  max-width: 275px;
  width: 100%;
  padding: 15px 20px;
}
.burger__ul li button {
  text-align: start;
  font-weight: 500;
  font-size: 16px;
  color: var(--headerLink);
}
.burger__content-tab {
  background-color: var(--headerColorThree);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s ease-in-out;
}
.burger__content-ul li {
  padding-left: 40px;
}
.burger__content-ul li a {
  font-weight: 500;
  font-size: 16px;
  color: var(--headerLink);
}

.activeMenu {
  opacity: 1;
  visibility: visible;
  top: 126px;
  z-index: 1000;
}
.activeMenu .menu__box-link a {
  pointer-events: auto;
}
.activeMenu .menu__links ul a {
  pointer-events: auto;
}

.activeBtn {
  background: #ff7306;
}
.activeBtn span {
  color: #fff;
}
.activeBtn .header__icon-block,
.activeBtn .burger__icon-block {
  background: #fff;
}

.activeTab {
  background-color: #fff;
}
.activeTab button {
  color: #525252;
}

.openMenu {
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: fit-content;
}

.openLi span {
  color: #4cb984;
}
.openLi svg {
  fill: #428b80;
}

.btnNext span {
  color: #4cb984;
}
.btnNext svg {
  fill: #428b80;
}

.showColumns {
  opacity: 1;
  width: 100%;
  height: fit-content;
  visibility: visible;
}

.burgerActive {
  opacity: 1;
  visibility: visible;
  top: 65px;
  z-index: 1000;
}
.burgerActive .burger__link {
  pointer-events: auto;
}

.activeHref {
  background: var(--headerColorThree);
}
.activeHref .burger__link {
  color: var(--darkGreen);
}

.activeContent .burger__tab-btn {
  background: var(--headerColorThree);
  max-width: 100%;
}
.activeContent .burger__tab-btn button {
  color: var(--darkGreen);
}
.activeContent .burger__content-tab {
  opacity: 1;
  visibility: visible;
  max-height: 500px;
}

.footer {
  margin-top: 20px;
}
.footer__content {
  padding: 34px clamp(0rem, -2.243rem + 4.78vw, 1.25rem) 10px clamp(0rem, -2.243rem + 4.78vw, 1.25rem);
}
.footer__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer__logo {
  width: 87px;
  height: 58px;
}
.footer__logo img {
  width: 100%;
  height: 100%;
}
.footer__logo img:nth-child(2) {
  display: none;
}
.footer__btn {
  padding: 16px clamp(0.938rem, -0.682rem + 6.91vw, 2.125rem);
  border-radius: 10px;
  background: var(--btnNone);
  border: 1px solid;
  border-color: var(--btnNone);
  font-weight: 700;
  font-size: 13px;
  line-height: 104%;
  color: var(--black);
  margin-right: clamp(0rem, -7rem + 16vw, 2.5rem);
  transition: all 0.3s ease-in-out;
}
.footer__btn:hover {
  background-color: var(--btnNoneHover);
}
.footer__nav {
  display: flex;
  padding: 30px 0;
}
@media (max-width: 950px) {
  .footer__nav {
    justify-content: space-between;
  }
}
@media (max-width: 650px) {
  .footer__nav {
    padding: 30px 0 0 0;
  }
}
.footer__list {
  display: flex;
  gap: clamp(1.25rem, -9.372rem + 17.89vw, 3.688rem);
}
@media (max-width: 950px) {
  .footer__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px clamp(1.25rem, -17.5rem + 40vw, 6.25rem);
  }
}
@media (max-width: 650px) {
  .footer__list {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: clamp(1.25rem, -0.028rem + 5.45vw, 2.188rem) clamp(2.5rem, -1.761rem + 18.18vw, 5.625rem);
  }
}
.footer__block {
  transition: all 0.3s ease-in-out;
}
.footer__block span {
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  color: var(--spanFooter);
}
@media (max-width: 650px) {
  .footer__block span {
    color: var(--black);
  }
}
.footer__block svg {
  visibility: hidden;
  width: 0;
  height: 0;
  transition: all 0.3s ease-in-out;
  fill: var(--arrowAcc);
}
@media (max-width: 650px) {
  .footer__block svg {
    visibility: visible;
    width: 11px;
    height: 6px;
  }
}
.footer__block ul {
  max-width: clamp(9.125rem, 3.678rem + 9.17vw, 10.375rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 21px;
}
.footer__block ul li a {
  display: flex;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 104%;
  color: var(--black);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 650px) {
  .footer__block ul li a {
    font-size: 12px;
  }
}
.footer__block ul li a:hover {
  color: #4cb984;
}
@media (max-width: 950px) {
  .footer__block ul {
    max-width: none;
    width: 170px;
  }
}
@media (max-width: 650px) {
  .footer__block ul {
    margin-top: 15px;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease-in-out;
  }
}
@media (max-width: 650px) {
  .footer__block {
    height: 30px;
  }
}
@media (max-width: 650px) {
  .footer__accordion {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.footer__info {
  margin-left: clamp(1.25rem, -17.271rem + 31.19vw, 5.5rem);
  margin-top: 5px;
}
@media (max-width: 950px) {
  .footer__info {
    margin-left: 0;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 650px) {
  .footer__info {
    display: none;
  }
}
.footer__blocks:nth-child(2) {
  margin-top: 33px;
}
.footer__text {
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
}
.footer__text p {
  font-weight: 700;
  font-size: 13px;
  line-height: 104%;
  color: var(--darkBlue);
}
.footer__text span {
  font-weight: 500;
  font-size: 13px;
  line-height: 104%;
  color: var(--textFooter);
}
.footer__text-v {
  margin-bottom: 8px;
}
.footer__text-v p {
  font-weight: 700;
  font-size: 13px;
  line-height: 104%;
  color: var(--darkBlack);
}
.footer__text-v span {
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  color: var(--textFooter);
}
.footer__numbers {
  display: flex;
  gap: 12px;
}
.footer__numbers span {
  display: block;
  width: 1px;
  background-color: #dadada;
  height: 15px;
}
.footer__numbers a {
  display: flex;
  width: max-content;
  font-weight: 400;
  font-size: 15px;
  line-height: 104%;
  color: var(--tel);
  transition: all 0.3s ease-in-out;
}
.footer__numbers a:hover {
  color: #4cb984;
}
.footer__button-end {
  padding: 16px 26px;
  border-radius: 10px;
  background: var(--btnEnd);
  margin-top: 30px;
  font-weight: 700;
  font-size: 13px;
  line-height: 104%;
  color: var(--lightGreen);
  transition: all 0.3s ease-in-out;
}
.footer__button-end:hover {
  background: var(--btnEndHover);
}
.footer__bottom::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #e7e8e9;
}
@media (max-width: 650px) {
  .footer__bottom {
    display: none;
  }
}
.footer__all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
}
.footer__logos {
  display: flex;
  gap: clamp(0.625rem, -1.057rem + 3.59vw, 1.563rem);
}
.footer__logo-block {
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease-in-out;
}
.footer__logo-block p {
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  color: var(--logoText);
}
.footer__logo-block img {
  width: 22px;
  height: 22px;
}
.footer__block-about {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: clamp(1.813rem, 0.063rem + 4vw, 2.438rem);
  height: clamp(1.813rem, 0.063rem + 4vw, 2.438rem);
  background-color: white;
  border-radius: 10px;
  border: 1px solid white;
  transition: all 0.3s ease-in-out;
}
.footer__block-about svg {
  width: clamp(0.938rem, 0.063rem + 2vw, 1.25rem);
  height: clamp(0.938rem, 0.063rem + 2vw, 1.25rem);
  transition: all 0.3s ease-in-out;
  fill: #006A61;
}
.footer__block-about:nth-child(1):hover svg {
  fill: red;
}
.footer__block-about:nth-child(2):hover svg {
  fill: #62A2FB;
}
.footer__block-about:nth-child(3):hover svg {
  fill: #62A2FB;
}
.footer__block-about:nth-child(4):hover svg {
  fill: rgb(173, 171, 21);
}
.footer__block-about:nth-child(5):hover svg {
  fill: #37BBFE;
}
.footer__block-about:nth-child(6):hover svg {
  fill: green;
}
.footer__block-about:nth-child(7):hover svg {
  fill: black;
}

.activeAccord {
  height: 100%;
}
.activeAccord .footer__accordion svg {
  transform: rotate(180deg);
}
.activeAccord ul {
  visibility: visible;
  opacity: 1;
  max-height: 100%;
}

.modal-basket {
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-basket__modal {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  transform: translateY(-50px);
  padding: clamp(0.625rem, 0.153rem + 2.36vw, 1.875rem) clamp(0.313rem, -0.348rem + 3.3vw, 2.063rem);
  border-radius: 20px;
  background: var(--white);
  max-width: 833px;
  width: 100%;
  margin: 0 15px;
}
.modal-basket__modal h2 {
  font-weight: 700;
  font-size: clamp(0.75rem, 0.467rem + 1.42vw, 1.5rem);
  line-height: 104%;
  color: var(--black);
}
@media (max-width: 480px) {
  .modal-basket__modal {
    max-height: 400px;
    overflow: auto;
  }
}
.modal-basket__product {
  margin-top: clamp(1rem, 0.623rem + 1.89vw, 2rem);
  display: flex;
  gap: 11px;
}
@media (max-width: 480px) {
  .modal-basket__product {
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
}
.modal-basket__product img {
  display: block;
  max-width: clamp(5.563rem, 3.605rem + 9.79vw, 10.75rem);
  height: clamp(5.563rem, 3.605rem + 9.79vw, 10.75rem);
  object-fit: cover;
}
.modal-basket__info a {
  display: block;
  font-weight: 500;
  font-size: clamp(0.563rem, 0.35rem + 1.06vw, 1.125rem);
  line-height: 104%;
  color: var(--black);
}
.modal-basket__info > span {
  display: block;
  margin-top: clamp(0.75rem, 0.443rem + 1.53vw, 1.563rem);
  font-weight: 700;
  font-size: clamp(0.75rem, 0.467rem + 1.42vw, 1.5rem);
  line-height: 104%;
  color: var(--black);
}
.modal-basket__info > p {
  margin-top: clamp(0.938rem, 0.584rem + 1.77vw, 1.875rem);
  font-weight: 500;
  font-size: clamp(0.5rem, 0.311rem + 0.94vw, 1rem);
  line-height: 104%;
  color: var(--black);
}
.modal-basket__buttons {
  margin-top: clamp(0.625rem, 0.389rem + 1.18vw, 1.25rem);
  display: flex;
  gap: 10px;
  align-items: center;
  height: clamp(1.063rem, 0.685rem + 1.89vw, 2.063rem);
}
@media (max-width: 480px) {
  .modal-basket__buttons {
    height: auto;
    flex-direction: column;
  }
}
.modal-basket__buttons > button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
@media (max-width: 480px) {
  .modal-basket__buttons > button {
    padding: 10px 40px !important;
    width: 100%;
  }
}
.modal-basket__buttons > button:nth-child(2) {
  padding: 0 clamp(0.75rem, 0.42rem + 1.65vw, 1.625rem);
  background-color: #00746a;
  border-radius: 10px;
  font-weight: 500;
  font-size: clamp(0.5rem, 0.335rem + 0.83vw, 0.938rem);
  line-height: 104%;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.modal-basket__buttons > button:nth-child(2):hover {
  background-color: #109a8e;
}
.modal-basket__buttons > button:nth-child(3) {
  padding: 8px clamp(0.75rem, 0.42rem + 1.65vw, 1.625rem);
  border: 1px solid #006a61;
  border-radius: 10px;
  font-weight: 500;
  font-size: clamp(0.5rem, 0.335rem + 0.83vw, 0.938rem);
  line-height: 104%;
  color: var(--black);
  transition: all 0.3s ease-in-out;
}
.modal-basket__buttons > button:nth-child(3):hover {
  background-color: #006a61;
  color: white;
}
.modal-basket__counter {
  border-radius: 10px;
  width: clamp(3.375rem, 2.172rem + 6.01vw, 6.563rem);
  height: 33px;
  background-color: var(--counter);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .modal-basket__counter {
    width: 105px;
  }
}
.modal-basket__counter span {
  font-weight: 500;
  font-size: clamp(0.5rem, 0.311rem + 0.94vw, 1rem);
  line-height: 104%;
  text-align: center;
  color: var(--black);
  min-width: 17px;
}
.modal-basket__counter button {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: clamp(0.75rem, 0.467rem + 1.42vw, 1.5rem);
  line-height: 104%;
  text-align: center;
  color: var(--counterText);
}
.modal-basket__recommendations {
  margin-top: clamp(1.563rem, 0.973rem + 2.95vw, 3.125rem);
}
.modal-basket__recommendations h4 {
  font-weight: 700;
  font-size: clamp(0.75rem, 0.467rem + 1.42vw, 1.5rem);
  line-height: 104%;
  color: var(--black);
}
.modal-basket__products {
  margin-top: clamp(0.938rem, 0.584rem + 1.77vw, 1.875rem);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}
@media (max-width: 750px) {
  .modal-basket__products {
    grid-template-columns: repeat(1, 1fr);
  }
}
.modal-basket__product-r {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--orderPlacement);
  border-radius: 10px;
  display: flex;
  overflow: hidden;
}
.modal-basket__product-r img {
  max-width: clamp(3.688rem, 2.367rem + 6.6vw, 7.188rem);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  display: block;
}
.modal-basket__product-r-info {
  padding: 10px 8px 13px 15px;
  flex-grow: 2;
}
.modal-basket__product-r-info > span {
  font-weight: 700;
  font-size: clamp(0.625rem, 0.389rem + 1.18vw, 1.25rem);
  line-height: 104%;
  color: var(--black);
}
.modal-basket__product-r-info > p {
  font-weight: 400;
  font-size: clamp(0.438rem, 0.272rem + 0.83vw, 0.875rem);
  line-height: 104%;
  color: #c0c0c0;
}
.modal-basket__product-r-info > p span {
  text-decoration: line-through;
}
.modal-basket__bottom {
  margin-top: 4px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 34px;
  width: 100%;
}
.modal-basket__bottom button {
  border-radius: 100%;
  background-color: #fe8b2c;
  min-width: 34px;
  height: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-basket__left p {
  font-weight: 400;
  font-size: clamp(0.5rem, 0.311rem + 0.94vw, 1rem);
  line-height: 104%;
  color: var(--black);
  max-width: 110px;
}
.modal-basket__favotite {
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 3px;
}
.modal-basket.activeModalBasket {
  opacity: 1;
  visibility: visible;
}
.modal-basket.activeModalBasket .modal-basket__modal {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.basket {
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: end;
}
.basket__modal {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  transform: translateX(100px);
  max-width: clamp(20rem, 16.651rem + 16.75vw, 28.875rem);
  width: 100%;
  padding: clamp(4.063rem, 3.473rem + 2.95vw, 5.625rem) clamp(1.125rem, 0.96rem + 0.83vw, 1.563rem);
  border-radius: clamp(0.938rem, 0.82rem + 0.59vw, 1.25rem);
  background: var(--white);
}
.basket__modal h2 {
  font-weight: 700;
  font-size: clamp(1.063rem, 0.897rem + 0.83vw, 1.5rem);
  line-height: 104%;
  color: var(--black);
}
.basket__modal form {
  display: flex;
  align-items: center;
  border-radius: 11px;
  margin-top: clamp(1.25rem, 1.061rem + 0.94vw, 1.75rem);
  background: var(--basket1);
}
.basket__modal form input {
  border: 0;
  padding: 9px 9px 9px 22px;
  background: transparent;
  font-weight: 500;
  font-size: clamp(0.75rem, 0.656rem + 0.47vw, 1rem);
  line-height: 104%;
  color: var(--black);
  max-width: 186px;
  width: 100%;
}
.basket__modal form button {
  background-color: #8b8b8b;
  font-weight: 500;
  font-size: clamp(0.75rem, 0.656rem + 0.47vw, 1rem);
  line-height: 104%;
  color: #fff;
  padding: 9px 22px;
  border-radius: 11px;
  width: 100%;
  text-align: start;
  transition: all 0.4s ease-in-out;
}
.basket__modal form button:hover {
  background-color: #bebebe;
}
.basket__cards {
  margin-top: clamp(1.375rem, 1.186rem + 0.94vw, 1.875rem);
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  height: calc(100% - 100px);
}
@media (max-width: 480px) {
  .basket__cards {
    height: calc(100% - 130px);
  }
}
.basket__card {
  display: flex;
  gap: 8px;
}
.basket__card img {
  width: clamp(7.813rem, 6.704rem + 5.54vw, 10.75rem);
  height: clamp(7.813rem, 6.704rem + 5.54vw, 10.75rem);
  object-fit: cover;
  display: block;
}
.basket__info a {
  display: block;
  font-weight: 500;
  font-size: clamp(0.813rem, 0.695rem + 0.59vw, 1.125rem);
  line-height: 104%;
  color: var(--black);
  text-align: start;
}
.basket__info p {
  margin-top: clamp(0.75rem, 0.656rem + 0.47vw, 1rem);
  font-weight: 500;
  font-size: 15px;
  line-height: 104%;
  color: var(--black);
}
.basket__flex {
  margin-top: clamp(0.688rem, 0.57rem + 0.59vw, 1rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.basket__flex > span {
  font-weight: 700;
  font-size: clamp(1.063rem, 0.897rem + 0.83vw, 1.5rem);
  line-height: 104%;
  color: var(--black);
}
.basket__counter {
  border-radius: 10px;
  width: clamp(4.75rem, 4.066rem + 3.42vw, 6.563rem);
  height: 33px;
  background-color: var(--counter);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.basket__counter span {
  font-weight: 500;
  font-size: clamp(0.688rem, 0.57rem + 0.59vw, 1rem);
  line-height: 104%;
  text-align: center;
  color: var(--black);
  min-width: 17px;
}
.basket__counter button {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: clamp(1.125rem, 0.983rem + 0.71vw, 1.5rem);
  line-height: 104%;
  text-align: center;
  color: var(--counterText);
}
.basket__buttons {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  height: clamp(1.063rem, 0.685rem + 1.89vw, 2.063rem);
}
@media (max-width: 480px) {
  .basket__buttons {
    height: auto;
    flex-direction: column;
  }
}
.basket__buttons > button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
@media (max-width: 480px) {
  .basket__buttons > button {
    padding: 10px 40px !important;
    width: 100%;
  }
}
.basket__buttons > button:nth-child(1) {
  padding: 0 clamp(0.75rem, 0.42rem + 1.65vw, 1.625rem);
  background-color: #00746a;
  border-radius: 10px;
  font-weight: 500;
  font-size: clamp(0.5rem, 0.335rem + 0.83vw, 0.938rem);
  line-height: 104%;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.basket__buttons > button:nth-child(1):hover {
  background-color: #109a8e;
}
.basket__buttons > button:nth-child(2) {
  padding: 8px clamp(0.75rem, 0.42rem + 1.65vw, 1.625rem);
  border: 1px solid #006a61;
  border-radius: 10px;
  font-weight: 500;
  font-size: clamp(0.5rem, 0.335rem + 0.83vw, 0.938rem);
  line-height: 104%;
  color: var(--black);
  transition: all 0.3s ease-in-out;
}
.basket__buttons > button:nth-child(2):hover {
  background-color: #006a61;
  color: white;
}
.basket.activeBasket {
  opacity: 1;
  visibility: visible;
}
.basket.activeBasket .basket__modal {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.home {
  margin-top: 35px;
}
.home__advertisement {
  max-width: 1168px;
  width: 100%;
  margin: 0 auto;
  padding: clamp(0rem, -0.943rem + 4.72vw, 2.5rem);
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  background: url("../img/home_dec.png") no-repeat;
  background-position: 60% center;
  background-size: cover;
  width: 100%;
  height: 533px;
}
.home__advertisement img {
  width: clamp(14.5rem, 12.165rem + 11.67vw, 20.688rem);
}
.home__advertisement h1 {
  margin-top: 40px;
  font-weight: 500;
  font-size: clamp(1.938rem, 1.395rem + 2.71vw, 3.375rem);
  line-height: 104%;
  color: #22303e;
}
.home__advertisement p {
  margin-top: 12px;
  font-weight: 500;
  font-size: clamp(1.313rem, 0.912rem + 2vw, 2.375rem);
  line-height: 104%;
  color: #22303e;
}
.home__action {
  margin-top: clamp(2.563rem, 2.209rem + 1.77vw, 3.5rem);
}
.home__action h2 {
  font-weight: 700;
  font-size: clamp(1.5rem, 1.288rem + 1.06vw, 2.063rem);
  line-height: 104%;
  color: var(--darkGreen);
}
.home__cards {
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media (max-width: 1000px) {
  .home__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 530px) {
  .home__cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
.home__cosmetics {
  position: relative;
  width: clamp(15rem, 13.774rem + 6.13vw, 18.25rem);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 1020px) {
  .home__cosmetics {
    right: 0;
    margin: 0 auto;
  }
}
@media (max-width: 530px) {
  .home__cosmetics {
    height: 390px;
  }
}
.home__cosmetics::after {
  content: "";
  position: absolute;
  top: -25px;
  right: 10px;
  background: url("../img/cosmeticsBack.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: clamp(24.25rem, 22.599rem + 8.25vw, 28.625rem);
  z-index: -1;
}
@media (max-width: 1020px) {
  .home__cosmetics::after {
    right: 0;
  }
}
@media (max-width: 540px) {
  .home__cosmetics::after {
    top: 0;
  }
}
.home__cosmetics h4 {
  max-width: 166px;
  font-weight: 500;
  font-size: 27px;
  line-height: 104%;
  color: #fff;
}
.home__info-cos {
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 540px) {
  .home__info-cos {
    padding-left: 50px;
  }
}
.home__info-cos img {
  width: clamp(5.625rem, 4.496rem + 3.34vw, 6.938rem);
}

.card-product {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: clamp(13.938rem, -3.548rem + 27.98vw, 16.875rem);
  border: 2px solid #dde9e8;
  border-radius: 5px;
  background-color: var(--white);
}
@media (max-width: 1000px) {
  .card-product {
    width: 100%;
  }
}
.card-product img {
  height: 172px;
  display: block;
  object-fit: cover;
}
.card-product__info {
  width: 100%;
  padding: 11px clamp(0.75rem, 0.679rem + 0.35vw, 0.938rem);
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.card-product__rating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 5px;
  width: 41px;
  height: 17px;
  font-weight: 500;
  font-size: 12px;
  line-height: 104%;
  color: #525252;
  background-color: #f5f5f5;
}
.card-product__top h3 {
  margin-top: 10px;
}
.card-product__top h3 a {
  display: block;
  width: fit-content;
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: var(--darkBlue);
}
.card-product__bottom {
  margin-top: auto;
}
.card-product__bottom p {
  margin-top: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 104%;
  color: #012321;
}
.card-product__buttons {
  margin-top: 9px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.card-product__buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  max-width: 53px;
  width: 100%;
  height: 17px;
  font-weight: 500;
  font-size: 10px;
  line-height: 104%;
  color: #fff;
  background-color: #c0c0c0;
}
.card-product__price-block {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.card-product__price {
  display: flex;
  align-items: center;
  gap: 5px;
}
.card-product__price span:nth-child(1) {
  font-weight: 500;
  font-size: 18px;
  line-height: 104%;
  color: #fff;
  background: #006a61;
  border-radius: 5px;
  padding: 4px 10px;
}
.card-product__price span:nth-child(2) {
  font-weight: 500;
  font-size: 18px;
  line-height: 104%;
  text-decoration: line-through;
  color: #c0c0c0;
}
.card-product__ball {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
}
.card-product__ball span {
  font-weight: 500;
  font-size: 10px;
  line-height: 104%;
  text-align: right;
  color: #c0c0c0;
}
.card-product__ball svg {
  cursor: pointer;
}
.card-product__hint {
  width: 200px;
  position: absolute;
  left: -140px;
  bottom: 20px;
  z-index: 3;
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: #012321;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  transform: translateY(-10px);
  opacity: 0;
  visibility: hidden;
}
.card-product__svg:hover + .card-product__hint {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.card-product__basket {
  margin-top: 15px;
  background-color: #006a61;
  border-radius: 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  text-align: center;
  color: #fff;
  padding: 12px 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.card-product__basket:hover {
  background-color: #109a8e;
}
.card-product__discount {
  position: absolute;
  top: 0;
  left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 5px 5px;
  min-width: 41px;
  height: 17px;
  background-color: #f30c01;
  font-weight: 500;
  font-size: 12px;
  line-height: 104%;
  color: #fff;
}
.card-product__discount-t {
  padding: 4px 10px;
  position: absolute;
  top: 0;
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 5px 5px;
  height: 21px;
  background-color: #f30c01;
  font-weight: 500;
  font-size: 12px;
  line-height: 104%;
  color: #fff;
}
.card-product__suitability {
  padding: 4px 10px;
  position: absolute;
  top: 0;
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 5px 5px;
  height: 21px;
  background-color: #fe8b2c;
  font-weight: 500;
  font-size: 12px;
  line-height: 104%;
  color: #fff;
}
.card-product__stock {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  border-radius: 0 0 15px 15px;
  width: calc(100% - 30px);
  height: 34px;
  background-color: #808080;
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: #fff;
}

.card-product .activeCardButton {
  background-color: #fe8b2c;
}

.nostock .activeCardButton {
  border: 1px solid #ff7306;
  background-color: var(--white);
  color: var(--darkBlue);
}
.nostock .card-product__basket {
  background-color: #808080;
}

.shares {
  margin-top: clamp(3.125rem, 2.606rem + 2.59vw, 4.5rem);
}
.shares__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  z-index: 2;
}
.shares__top h2 {
  font-weight: 700;
  font-size: clamp(1.5rem, 1.288rem + 1.06vw, 2.063rem);
  line-height: 104%;
  color: var(--darkGreen);
}
.shares__link {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 104%;
  text-decoration: underline;
  text-align: center;
  color: var(--darkGreen);
}
.shares__link svg {
  fill: var(--darkGreen);
}
.shares__banner {
  position: relative;
  margin-top: clamp(1.875rem, 1.71rem + 0.83vw, 2.313rem);
}
@media (max-width: 950px) {
  .shares__banner img {
    width: 100%;
  }
}
.shares__info-block {
  position: absolute;
  top: 50%;
  right: 11%;
  z-index: 1;
  transform: translateY(-52%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: clamp(30.875rem, 29.861rem + 5.07vw, 33.563rem);
}
@media (max-width: 950px) {
  .shares__info-block {
    position: relative;
    top: inherit;
    right: inherit;
    transform: translateY(0);
    margin-top: -70px;
  }
}
@media (max-width: 570px) {
  .shares__info-block {
    margin-top: -60px;
  }
}
.shares__info-block::after {
  content: "";
  position: absolute;
  background: url("../img/shares__dec.png") no-repeat;
  background-size: cover;
  width: 711px;
  height: 100%;
  z-index: -1;
}
.shares__info-block span {
  padding: 7px 21px;
  font-weight: 500;
  font-size: 14px;
  line-height: 104%;
  color: #fff;
  background: #fe8b2c;
  border-radius: 16px;
}
.shares__info-block h3 {
  margin-top: 21px;
  font-weight: 700;
  font-size: 32px;
  line-height: 104%;
  color: #012321;
}
.shares__info-block p {
  margin-top: 5px;
  max-width: 412px;
  font-weight: 400;
  font-size: 16px;
  line-height: 104%;
  color: #000;
}
.shares__info-block a {
  width: fit-content;
  margin-top: 22px;
  display: block;
  border-radius: 5px;
  background-color: #fff;
  padding: 12px 31px;
  font-weight: 500;
  font-size: 14px;
  line-height: 104%;
  color: #000;
  transition: background-color 0.3s ease-in-out;
}
.shares__info-block a:hover {
  background-color: #f0eeee;
}

.interesting {
  margin-top: clamp(1.875rem, 1.002rem + 4.36vw, 4.188rem);
}
.interesting h2 {
  font-weight: 700;
  font-size: clamp(1.5rem, 1.288rem + 1.06vw, 2.063rem);
  line-height: 104%;
  color: var(--darkGreen);
}
.interesting__cards {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(0.938rem, -1.568rem + 4.72vw, 1.875rem);
  grid-template-areas: "one two three three" "four four five six" "four four seven seven";
}
@media (max-width: 850px) {
  .interesting__cards {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "seven seven" "one two" "three three" "five six" "four four";
  }
}
.interesting__card {
  display: flex;
  flex-direction: column;
  padding: 25px 12px 0 12px;
  border-radius: 20px;
  background-color: #f5f5f5;
  overflow: hidden;
}
.interesting__card h3 {
  max-width: 171px;
  font-weight: 700;
  font-size: 19px;
  line-height: 104%;
  color: #006a61;
}
.interesting__card h3:last-child {
  color: #fff;
}
.interesting__card img {
  margin-top: 6px;
  width: fit-content;
  mix-blend-mode: multiply;
}
@media (max-width: 850px) {
  .interesting__card img {
    margin: auto;
  }
}
.interesting__card:nth-child(1) {
  grid-area: one;
  height: 217px;
}
.interesting__card:nth-child(2) {
  grid-area: two;
  height: 217px;
}
.interesting__card:nth-child(3) {
  grid-area: three;
  height: 217px;
  display: flex;
  justify-content: space-between;
}
.interesting__card:nth-child(3) img {
  align-self: end;
  transform: translateY(-20%);
}
.interesting__card:nth-child(4) {
  grid-area: four;
  padding: 0 23px 44px 23px;
  flex-direction: column-reverse;
}
.interesting__card:nth-child(5) {
  grid-area: five;
}
.interesting__card:nth-child(6) {
  grid-area: six;
}
.interesting__card:nth-child(7) {
  grid-area: seven;
  padding: 0 30px 18px 30px;
  flex-direction: column-reverse;
  height: 185px;
  background: url("../img/interesting/back.png") no-repeat;
  background-size: cover;
  background-position: center;
}

.exclusives {
  margin-top: clamp(2.5rem, 2.217rem + 1.42vw, 3.25rem);
}
.exclusives h2 {
  font-weight: 700;
  font-size: clamp(1.5rem, 1.288rem + 1.06vw, 2.063rem);
  line-height: 104%;
  color: var(--darkGreen);
}
.exclusives__buttons {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}
.exclusives__buttons button {
  background: var(--white);
  padding: 11px clamp(1rem, 0.882rem + 0.59vw, 1.313rem);
  border-radius: 10px;
  font-weight: 500;
  font-size: clamp(0.813rem, 0.695rem + 0.59vw, 1.125rem);
  line-height: 104%;
  color: var(--black);
  background: var(--whiteButton);
}
.exclusives__cards {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media (max-width: 1000px) {
  .exclusives__cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 530px) {
  .exclusives__cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
.exclusives .activeButtons {
  background: var(--lightWhiteButton);
}
.exclusives__discount {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 70px 10px 70px clamp(0.625rem, -3.217rem + 7.23vw, 2.063rem);
  background: url("../img/exclusives__discount.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 395px;
}
@media (max-width: 1168px) {
  .exclusives__discount {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}
.exclusives__left p {
  font-weight: 500;
  font-size: 15px;
  line-height: 104%;
  color: #fff;
}
.exclusives__left h4 {
  margin-top: 10px;
  font-weight: 700;
  font-size: clamp(2rem, 1.434rem + 2.83vw, 2.938rem);
  line-height: 104%;
  text-transform: uppercase;
  color: #fff;
  max-width: 400px;
}
.exclusives__left a {
  margin-top: 31px;
  display: block;
  width: fit-content;
  padding: 17px 36px;
  font-weight: 700;
  font-size: 15px;
  line-height: 104%;
  text-align: center;
  color: #0d736b;
  border-radius: 15px;
  background-color: #ebf6fe;
  transition: background-color 0.3s ease-in-out;
}
.exclusives__left a:hover {
  background-color: #f0eeee;
}
.exclusives__right {
  font-weight: 400;
  font-size: 22px;
  line-height: 104%;
  color: #fff;
  max-width: 407px;
}
@media (max-width: 850px) {
  .exclusives__right {
    display: none;
  }
}
.exclusives__survey {
  margin-top: clamp(1.188rem, 0.999rem + 0.94vw, 1.688rem);
  padding: 50px clamp(0.625rem, -4.387rem + 9.43vw, 2.5rem);
  background: #fafafb;
  border-radius: 40px;
}
.exclusives__top-survey {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.exclusives__top-survey h5 {
  font-weight: 500;
  font-size: 33px;
  line-height: 104%;
  color: #000;
}
.exclusives__flex {
  margin-top: clamp(1.375rem, 0.715rem + 3.3vw, 3.125rem);
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.exclusives__flex img {
  justify-self: end;
}
@media (max-width: 850px) {
  .exclusives__flex {
    display: flex;
    flex-direction: column-reverse;
  }
}
.exclusives__hint {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 104%;
  text-align: right;
  color: #000;
}
@media (max-width: 850px) {
  .exclusives__hint {
    display: none;
  }
}
.exclusives__hint svg {
  cursor: pointer;
}
.exclusives__hint svg:hover + .exclusives__hint-block {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.exclusives__hint-block {
  width: 200px;
  position: absolute;
  left: 5px;
  bottom: 20px;
  z-index: 3;
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: #012321;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  text-align: start;
  transition: all 0.3s ease-in-out;
  transform: translateY(-10px);
  opacity: 0;
  visibility: hidden;
}
.exclusives__survey-block {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.exclusives__survey-block label {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 9px;
  font-weight: 400;
  font-size: 21px;
  line-height: 104%;
  color: #1f1f1f;
}
.exclusives__survey-block label input {
  display: none;
}
.exclusives__survey-block label input:checked + span {
  border: 1px solid #006A61;
  background-color: transparent;
}
.exclusives__survey-block label input:checked + span::after {
  opacity: 1;
  visibility: visible;
}
.exclusives__survey-block button {
  padding: 16px 26px;
  background-color: #006a61;
  border-radius: 15px;
  font-weight: 500;
  font-size: 19px;
  line-height: 104%;
  color: #fff;
  width: fit-content;
  transition: all 0.3s ease-in-out;
}
.exclusives__survey-block button:hover {
  background-color: #109a8e;
}
.exclusives__radio-custom {
  display: inline-block;
  position: relative;
  min-width: 25px;
  height: 25px;
  background-color: #91C8C4;
  border: 1px solid #91C8C4;
  border-radius: 50%;
  vertical-align: sub;
}
.exclusives__radio-custom::after {
  content: "";
  min-width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #006A61;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
}
.exclusives__advantages-block {
  margin-top: clamp(0.625rem, 0.13rem + 2.48vw, 1.938rem);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 26px;
}
@media (max-width: 950px) {
  .exclusives__advantages-block {
    grid-template-columns: repeat(1, 1fr);
  }
}
.exclusives__advantages {
  display: flex;
}
@media (max-width: 950px) {
  .exclusives__advantages {
    display: grid;
    grid-template-columns: 127px 1fr;
  }
}
.exclusives__advantages img {
  position: relative;
  z-index: -1;
  height: fit-content;
}
.exclusives__advantages-info {
  position: relative;
  padding: 20px;
  background: #f1f1f1;
  border-radius: 15px;
}
.exclusives__advantages-info::after {
  content: "";
  position: absolute;
  left: -25px;
  top: 60%;
  border: 20px solid transparent;
  border-right: 20px solid #f1f1f1;
  border-bottom: 20px solid #f1f1f1;
  transform: translateY(-50%);
  z-index: -1;
}
.exclusives__advantages-info h6 {
  font-weight: 700;
  font-size: 15px;
  line-height: 104%;
  color: #006a61;
}
.exclusives__advantages-info p {
  margin-top: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: 0.06em;
  color: #012321;
}

.feedback {
  margin-top: 47px;
}
.feedback h2 {
  font-weight: 700;
  font-size: clamp(1.5rem, 1.288rem + 1.06vw, 2.063rem);
  line-height: 104%;
  color: var(--darkGreen);
}
.feedback__cards {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media (max-width: 850px) {
  .feedback__cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 550px) {
  .feedback__cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
.feedback__card {
  min-height: 408px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dde9e8;
  border-radius: 5px;
  box-shadow: 0 4px 11px 0 rgba(0, 106, 97, 0.04);
}
@media (max-width: 850px) {
  .feedback__card:nth-child(3) {
    display: none;
  }
}
@media (max-width: 550px) {
  .feedback__card:nth-child(2) {
    display: none;
  }
}
.feedback__top {
  position: relative;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 200px;
  height: 100%;
}
.feedback__rating {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 3px;
  position: absolute;
  bottom: 0;
  left: 15px;
  background-color: var(--lightFeedback);
  border-radius: 5px 5px 0 0;
}
.feedback__info {
  display: flex;
  flex-direction: column;
  padding: 19px 15px;
  background-color: var(--lightFeedback);
  flex-grow: 2;
}
.feedback__info h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: var(--darkBlue);
}
.feedback__info p {
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: var(--darkBlue);
}
.feedback__info span {
  margin-top: auto;
  font-weight: 700;
  font-size: 14px;
  line-height: 104%;
  color: var(--darkBlue);
}

.partners {
  margin-top: clamp(1.25rem, 0.778rem + 2.36vw, 2.5rem);
}
.partners h2 {
  font-weight: 700;
  font-size: clamp(1.5rem, 1.288rem + 1.06vw, 2.063rem);
  line-height: 104%;
  color: var(--darkGreen);
}
.partners__logo {
  margin-top: clamp(0.813rem, 0.246rem + 2.83vw, 2.313rem);
  padding: 26px 0;
  background-color: #fffdfe;
  display: flex;
  overflow: auto;
}
.partners__form {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../img/form.png") no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 1187px;
  width: 100%;
  height: 175px;
  margin: 29px auto 0;
}
@media (max-width: 850px) {
  .partners__form {
    height: 278px;
  }
}
.partners__form-content {
  padding: 0 clamp(0.688rem, -9.169rem + 18.55vw, 4.375rem) 15px clamp(0rem, -8.353rem + 15.72vw, 3.125rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1187px;
  width: 100%;
  height: 175px;
}
@media (max-width: 850px) {
  .partners__form-content {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 278px;
    gap: 19px;
  }
}
.partners__right {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.partners__right p {
  display: flex;
  margin-top: 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  text-align: center;
  color: #fff;
}
@media (max-width: 850px) {
  .partners__right p {
    flex-direction: column;
  }
}
.partners__right a {
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  text-align: center;
  color: #fff;
  text-decoration: underline;
  text-align: start;
}
.partners__left h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 104%;
  text-transform: uppercase;
  color: #fff;
}
.partners__left p {
  font-weight: 500;
  font-size: 22px;
  line-height: 104%;
  color: #fff;
}
.partners__form-block {
  display: flex;
  gap: 15px;
  max-width: 455px;
  width: 100%;
}
.partners__form-block input {
  border-radius: 10px;
  width: 100%;
  border: 0;
  background-color: #fff;
  padding: 15px 17px;
  font-weight: 500;
  font-size: 15px;
  line-height: 104%;
  color: #6a6a6a;
}
.partners__form-block input::placeholder {
  font-weight: 500;
  font-size: 15px;
  line-height: 104%;
  color: #6a6a6a;
}
.partners__form-block button {
  padding: 18px clamp(0.875rem, 0.403rem + 2.36vw, 2.125rem);
  font-weight: 500;
  font-size: 15px;
  line-height: 104%;
  text-align: center;
  color: #000;
  background: #fff;
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
}
.partners__form-block button:hover {
  background-color: #f0eeee;
}

.obtaining {
  margin-top: clamp(1.25rem, 0.542rem + 3.54vw, 3.125rem);
}
.obtaining h2 {
  font-weight: 700;
  font-size: clamp(1.5rem, 1.288rem + 1.06vw, 2.063rem);
  line-height: 104%;
  color: var(--darkGreen);
}
.obtaining__map {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 40px;
  overflow: hidden;
  height: 100%;
}
@media (max-width: 1000px) {
  .obtaining__map {
    flex-direction: column;
  }
  .obtaining__map iframe {
    width: 100%;
  }
}
.obtaining__top {
  padding: 50px 58px 15px 30px;
}
.obtaining__top h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 104%;
  color: #006a61;
}
.obtaining__top ul {
  max-width: 282px;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.obtaining__top ul li {
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  color: #006a61;
}
.obtaining__top ul a {
  display: inline-block;
  font-size: 13px;
  line-height: 104%;
  color: #006a61;
  font-weight: 500;
  text-decoration: underline;
}
.obtaining__bottom {
  padding: 15px 58px 50px 30px;
  border-top: 1px solid #006a61;
}
@media (max-width: 1000px) {
  .obtaining__bottom {
    padding: 15px 58px 39px 30px;
  }
}
.obtaining__bottom h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 104%;
  color: #006a61;
}
.obtaining__bottom p {
  max-width: 282px;
  margin-top: 18px;
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  color: #006a61;
}
.obtaining__bottom a {
  margin-top: 10px;
  display: inline-block;
  font-size: 13px;
  line-height: 104%;
  color: #006a61;
  font-weight: 500;
  text-decoration: underline;
}
.obtaining__map-block {
  min-height: 100%;
}
.obtaining__map-block iframe {
  height: 100%;
}

.tags {
  margin-top: 50px;
}
.tags__top {
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 10px;
}
.tags__top h1 {
  font-weight: 500;
  font-size: clamp(1.5rem, 1.311rem + 0.94vw, 2rem);
  line-height: 104%;
  color: var(--darkBlack);
}
.tags__top p {
  font-weight: 400;
  font-size: 12px;
  line-height: 104%;
  color: var(--black);
}
@media (max-width: 550px) {
  .tags__top p {
    display: none;
  }
}
.tags__slider-block {
  margin-top: 10px;
  padding: 8px clamp(0.938rem, 0.702rem + 1.18vw, 1.563rem);
  border-radius: 10px;
  background-color: var(--tagBack);
  display: flex;
  align-items: center;
  gap: 10px;
}
.tags__slider {
  cursor: pointer;
  overflow: hidden;
}
.tags__content .tags__slide {
  width: auto;
  display: block;
  padding: 7px 10px;
  border-radius: 100px;
  background-color: var(--tagColor);
  font-weight: 400;
  font-size: 12px;
  line-height: 104%;
  color: var(--tagText);
}
.tags__buttons {
  display: flex;
  align-items: center;
  gap: clamp(1.25rem, 1.038rem + 1.06vw, 1.813rem);
}
.tags__buttons button svg {
  fill: black;
}
.tags__buttons button.swiper-button-disabled svg {
  fill: #9f9ea3;
}
.tags__buttons button:last-child svg {
  transform: rotate(180deg);
}
.tags__banner {
  margin-top: clamp(0.938rem, 0.584rem + 1.77vw, 1.875rem);
  border-radius: 10px;
  width: 100%;
}

.filters {
  margin-top: 30px;
}
.filters h2 {
  font-weight: 500;
  font-size: clamp(1.25rem, 1.156rem + 0.47vw, 1.5rem);
  line-height: 104%;
  color: var(--titleFilter);
}
.filters__filter-block {
  margin-top: clamp(0.938rem, 0.82rem + 0.59vw, 1.25rem);
}
.filters__top {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;
  z-index: 5;
}
@media (max-width: 750px) {
  .filters__top {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
  .filters__top > .filters__filter:not(:nth-child(1), :nth-child(2)) {
    display: none;
  }
}
@media (max-width: 480px) {
  .filters__top {
    grid-template-columns: repeat(1, 1fr);
  }
}
.filters__bottom {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
}
@media (max-width: 1140px) {
  .filters__bottom {
    grid-template-columns: repeat(auto-fill, minmax(206px, 1fr));
  }
}
@media (max-width: 750px) {
  .filters__bottom {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    margin-top: 5px;
  }
  .filters__bottom > .filters__filter:not(:nth-child(1), :nth-child(2)) {
    display: none;
  }
}
@media (max-width: 480px) {
  .filters__bottom {
    grid-template-columns: repeat(1, 1fr);
  }
}
.filters__filter {
  position: relative;
}
.filters__filter button {
  display: flex;
  padding: 24px 10px;
  border: 1px solid var(--borderFilter);
  background-color: var(--filterBack);
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  color: var(--black);
  text-align: start;
}
.filters__filter button span {
  display: block;
  background: url("../img/svg/arr.svg") no-repeat;
  width: 11px;
  height: 6px;
  transition: all 0.3s ease-in-out;
}
.filters__filter button::first-letter {
  text-transform: capitalize;
}
.filters__filter > button:first-of-type {
  position: relative;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  min-width: 100%;
  width: max-content;
  overflow: hidden;
}
.filters__filter-info {
  z-index: 1;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  transform: translateY(-50px);
}
.filters__product-button {
  padding: 22px 10px;
  border: 1px solid #00857b;
  border-radius: 5px;
  background-color: #00857b;
  font-weight: 500;
  font-size: 15px;
  line-height: 104%;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.filters__product-button:hover {
  background-color: #109a8e;
}
@media (max-width: 750px) {
  .filters__product-button {
    display: none;
  }
}
.filters__button-mob {
  display: none;
  margin-top: 5px;
  padding: 22px 10px;
  border: 1px solid #00857b;
  border-radius: 5px;
  background-color: #00857b;
  font-weight: 500;
  font-size: 15px;
  line-height: 104%;
  color: #fff;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.filters__button-mob:hover {
  background-color: #109a8e;
}
@media (max-width: 750px) {
  .filters__button-mob {
    display: block;
  }
}
.filters .activeFilter {
  z-index: 10;
}
.filters .activeFilter > button:first-of-type span {
  transform: rotate(-180deg);
}
.filters .activeFilter .filters__filter-info {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.filters__cards-buttons {
  margin-top: clamp(0.938rem, 0.348rem + 2.95vw, 2.5rem);
  display: flex;
  align-items: center;
  gap: 15px;
  overflow: auto;
}
.filters__cards-buttons button {
  border-radius: 10px;
  padding: 10px 20px;
  background: var(--filterBack);
  font-weight: 500;
  font-size: 18px;
  line-height: 104%;
  text-align: center;
  color: var(--black);
  min-width: fit-content;
}
.filters__cards-content {
  margin-top: 21px;
}
.filters__cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media (max-width: 1000px) {
  .filters__cards {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 850px) {
  .filters__cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .filters__cards .card-product:not(:nth-child(-n+4)) {
    display: none;
  }
}
@media (max-width: 550px) {
  .filters__cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
.filters__cards:nth-child(3) {
  margin-top: clamp(2rem, 1.693rem + 1.53vw, 2.813rem);
}
.filters .activeButtonCard {
  background-color: var(--cardBack);
}

.personal-cabinet {
  margin-top: 50px;
}
.personal-cabinet__main {
  display: flex;
  gap: 54px;
}

.panel {
  display: block;
  border-radius: 10px;
  background: var(--panel);
  padding: 0 7px 15px 7px;
  height: fit-content;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 950px) {
  .panel {
    display: none;
  }
}
.panel__top {
  border-radius: 100%;
  width: 215px;
  height: 215px;
}
.panel__list {
  padding: 23px 17px 0;
}
.panel__list li {
  display: flex;
}
.panel__list li:not(:last-child, :first-child) {
  margin-top: 15px;
}
.panel__list li:last-child {
  margin-top: 67px;
}
.panel__list a {
  font-weight: 500;
  font-size: 18px;
  line-height: 104%;
  color: var(--listCabinetA);
  transition: all 0.3s ease-in-out;
}
.panel__list a:hover {
  color: var(--activePanel);
}
.panel .activeHref {
  color: var(--activePanel);
}

.cabinet {
  width: 100%;
}
.cabinet__content {
  margin-top: clamp(0rem, -6.264rem + 10.55vw, 1.438rem);
}
@media (max-width: 950px) {
  .cabinet__personal {
    margin-top: 30px;
  }
}
.cabinet__personal h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 104%;
  color: var(--cabinetTitle);
}
.cabinet__top-i {
  margin-top: 25px;
  display: flex;
  gap: 20px;
}
.cabinet__top-i > .cabinet__input:nth-child(-n+2) {
  flex: 1 1 195px;
  min-width: 150px;
}
.cabinet__top-i > .cabinet__input:nth-child(3) {
  flex: 1 1 416px;
  min-width: 300px;
}
@media (max-width: 950px) {
  .cabinet__top-i {
    margin-top: 20px;
    flex-direction: column;
    gap: 15px;
  }
  .cabinet__top-i > .cabinet__input {
    flex: auto !important;
  }
}
.cabinet__bottom-i {
  margin-top: 30px;
  display: flex;
  gap: 20px;
}
.cabinet__bottom-i > .cabinet__input:nth-child(1) {
  flex: 1 1 100px;
  min-width: 70px;
}
.cabinet__bottom-i > .cabinet__input:nth-child(2) {
  flex: 1 1 127px;
  min-width: 69px;
}
.cabinet__bottom-i > .cabinet__input:nth-child(3) {
  flex: 1 1 143px;
  min-width: 70px;
}
.cabinet__bottom-i > .cabinet__input:nth-child(5) {
  flex: 1 1 416px;
  min-width: 300px;
}
@media (max-width: 950px) {
  .cabinet__bottom-i {
    margin-top: 15px;
    flex-direction: column;
    gap: 15px;
  }
  .cabinet__bottom-i > .cabinet__input {
    width: 100%;
    flex: auto !important;
  }
  .cabinet__bottom-i > .cabinet__input:nth-child(-n+3) {
    display: none;
  }
}
.cabinet__content .cabinet__mob-inp {
  display: none;
}
@media (max-width: 950px) {
  .cabinet__content .cabinet__mob-inp {
    display: flex;
  }
}
.cabinet__input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.cabinet__input label {
  font-weight: 700;
  font-size: 16px;
  line-height: 104%;
  color: var(--darkGreen);
}
.cabinet__input input {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 15px;
  background-color: var(--tagBack);
  font-weight: 400;
  font-size: 16px;
  line-height: 104%;
  color: var(--black);
  width: 100%;
}
.cabinet__info-block {
  margin-top: clamp(1.875rem, 0.605rem + 6.35vw, 4.375rem);
}
.cabinet__info {
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.cabinet__info > .cabinet__program {
  display: block;
}
@media (max-width: 950px) {
  .cabinet__info > .cabinet__program {
    display: none;
  }
}
.cabinet__info-block h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 104%;
  color: var(--cabinetTitle);
}
.cabinet__program h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 104%;
  color: var(--cabinetTitle);
}
.cabinet__content .cabinet__mob-p {
  display: none;
}
@media (max-width: 950px) {
  .cabinet__content .cabinet__mob-p {
    display: block;
  }
}
.cabinet__card-program {
  height: 129px;
  margin-top: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 15px;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--tagBack);
  z-index: 1;
  overflow: hidden;
}
.cabinet__card-program::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: url("../img/card.png") no-repeat;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.3);
  background-size: cover;
  width: 100%;
  height: 127px;
  z-index: -1;
}
@media (max-width: 420px) {
  .cabinet__card-program::after {
    opacity: 0.4;
  }
}
.cabinet__card-c {
  align-self: flex-end;
}
.cabinet__card-c > p {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: var(--black);
}
.cabinet__card-c > p:nth-child(2) {
  margin-top: 5px;
}
.cabinet__card-c div {
  max-width: 201px;
  margin-top: 15px;
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  color: var(--black);
}
.cabinet__card-c div span {
  color: var(--activePanel);
}
.cabinet__pets h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 104%;
  color: var(--cabinetTitle);
}
.cabinet__flex {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.cabinet__pet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("../img/decPet.png") no-repeat;
  background-size: contain;
  max-width: 128px;
  width: 100%;
  height: clamp(6.188rem, -1.983rem + 13.76vw, 8.063rem);
}
@media (max-width: 950px) {
  .cabinet__pet {
    max-width: 100%;
    width: clamp(5.313rem, 3.503rem + 9.05vw, 8.875rem);
    height: clamp(5.313rem, 3.503rem + 9.05vw, 8.875rem);
  }
}
.cabinet__pet img {
  width: clamp(1.875rem, -5.206rem + 11.93vw, 3.5rem);
}
@media (max-width: 950px) {
  .cabinet__pet img {
    width: clamp(2.5rem, 1.802rem + 3.49vw, 3.875rem);
  }
}
.cabinet__pet h4 {
  margin-top: 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: #2b2b2b;
}

.myorder {
  margin-top: clamp(1.813rem, 1.341rem + 2.36vw, 3.063rem);
}
.myorder__table {
  display: grid;
  grid-template-columns: 13.08% 41.2% 12.02% 11.58% 10.7% 11.41%;
  background-color: var(--tagBack);
  border: 1px solid #fff;
}
@media (max-width: 950px) {
  .myorder__table {
    grid-template-columns: repeat(3, 1fr);
  }
  .myorder__table .myorder__cell:not(:nth-child(-n+3)) {
    display: none;
  }
}
.myorder__table:nth-child(1) {
  border-radius: 10px 10px 0 0;
  background-color: #008d83;
  border: 0;
}
.myorder__table:nth-child(1) .myorder__cell {
  color: white;
}
.myorder__table:nth-child(1) .myorder__cell::after {
  background-color: #fff;
}
.myorder__cell {
  position: relative;
  min-width: 100%;
  padding: clamp(0.625rem, 0.308rem + 1.59vw, 1.25rem);
  font-weight: 500;
  font-size: clamp(0.875rem, 0.748rem + 0.63vw, 1.125rem);
  line-height: 104%;
  color: var(--colorTable);
}
.myorder__cell:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: black;
  min-width: 1px;
  height: calc(100% - 7px);
}
@media (max-width: 950px) {
  .myorder__cell:nth-child(n+3)::after {
    background-color: transparent;
    min-width: 0;
  }
}
.myorder a {
  display: block;
  width: 100%;
  padding: 20px;
  border-radius: 0 0 10px 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 104%;
  text-align: center;
  color: var(--buttonColorTable);
  background-color: var(--buttonTable);
}

.orders {
  margin-top: clamp(0rem, -6.264rem + 10.55vw, 1.438rem);
}
.orders h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 104%;
  color: var(--cabinetTitle);
}
.orders__buttons {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.orders__buttons button {
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  padding: 10px clamp(0.125rem, -0.3rem + 2.12vw, 1.25rem);
  background-color: var(--orderButton);
  font-weight: 400;
  font-size: 16px;
  line-height: 104%;
  color: var(--black);
}
.orders__table-block {
  margin-top: 17px;
}
@media (max-width: 650px) {
  .orders__table-block {
    display: none;
  }
}
.orders__content .orders__top {
  border-radius: 10px 10px 0 0;
  background-color: #008d83;
  border: 0;
}
.orders__content .orders__top .orders__cell {
  color: white;
}
.orders__content .orders__top .orders__cell::after {
  background-color: #fff;
}
.orders__accardion {
  border: 1px solid #fff;
}
.orders__accardion > .orders__table {
  cursor: pointer;
}
.orders__table, .orders__accardion-table {
  display: grid;
  grid-template-columns: 15.64% 46.68% 13.74% 12.44% 11.49%;
  background-color: var(--tagBack);
}
.orders__accardion-content {
  overflow: hidden;
  max-height: 0;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--tagBack);
}
.orders__cell {
  position: relative;
  min-width: 100%;
  padding: 20px 9px;
  font-weight: 500;
  font-size: clamp(0.75rem, -0.884rem + 2.75vw, 1.125rem);
  line-height: 104%;
  color: var(--colorTable);
}
.orders__cell:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: black;
  min-width: 1px;
  height: calc(100% - 7px);
}
.orders__accardion-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px;
  min-width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: var(--colorTable);
}
.orders__accardion-cell span {
  width: 100%;
}
.orders .activeOrderButton {
  border: 1px solid #008d83;
  background-color: #008d83;
  color: #fafafa;
}
.orders__accardion-mob {
  margin-top: 30px;
  display: none;
  flex-direction: column;
  gap: 30px;
}
@media (max-width: 650px) {
  .orders__accardion-mob {
    display: flex;
  }
}
.orders__accardion-table-mob {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 17px 10px;
  background-color: var(--mobAccardion);
  border-radius: 10px;
}
.orders__accardion-table-mob h4 {
  display: flex;
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  color: var(--black);
}
.orders__accardion-table-mob h4 span {
  display: none;
}
.orders__accardion-table-mob span {
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  color: var(--black);
}
.orders__accardion-table-mob a {
  text-decoration: underline;
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  color: var(--black);
}
.orders__accardion-product-mob {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 14px;
}
.orders__accardion-product-mob img {
  width: 52px;
  height: 70px;
  object-fit: cover;
}
.orders__accardion-product-mob h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: var(--colorTable);
}
.orders__accardion-info-mob {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.orders__accardion-info-mob h5 {
  font-weight: 700;
  font-size: 13px;
  line-height: 104%;
  color: var(--black);
}
.orders__accardion-info-mob span {
  font-weight: 700;
  font-size: 13px;
  line-height: 104%;
  text-align: right;
  color: #006a61;
}
.orders__accardion-content-mob {
  background-color: var(--mobAccardion);
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  max-height: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.orders .hiddenBottom {
  display: none;
}
.orders .activeRepeat {
  border-radius: 10px 10px 0 0;
}
.orders .activeRepeat h4 span {
  display: block;
}

.loyalty {
  margin-top: clamp(0rem, -6.264rem + 10.55vw, 1.438rem);
}
.loyalty h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 104%;
  color: var(--cabinetTitle);
}
.loyalty__line-block {
  margin-top: 9px;
}
.loyalty__ongoing {
  margin-top: 56px;
  position: relative;
  border-radius: 20px;
  background-color: #00a18b;
  max-width: 820px;
  width: 100%;
  height: 168px;
  padding: 15px clamp(0.625rem, -0.625rem + 6.25vw, 3.938rem) 0 clamp(0.625rem, -0.059rem + 3.42vw, 2.438rem);
}
.loyalty__ongoing::after {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  background: #b4d9d7;
  transform: rotate(4deg);
  width: 100%;
  height: 100%;
  border-radius: 20px;
  z-index: -1;
}
.loyalty__ongoing h2 {
  font-weight: 500;
  font-size: clamp(0.625rem, 0.389rem + 1.18vw, 1.25rem);
  line-height: 104%;
  text-align: center;
  color: #fff;
}
.loyalty__line {
  position: relative;
  background: linear-gradient(360deg, #c0d7e1 0%, #adcad8 100%);
  height: 28px;
  width: 100%;
  border-radius: 100px;
  overflow: hidden;
}
.loyalty__line span {
  position: absolute;
  top: 0;
  left: 0;
  width: 24.62%;
  height: 100%;
  background: linear-gradient(360deg, #36d235 1.5%, #52df48 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #94c4c1;
  border-radius: 100px;
  font-weight: 500;
  font-size: clamp(0.5rem, 0.311rem + 0.94vw, 1rem);
  line-height: 104%;
  color: #fff;
}
.loyalty__info-line {
  margin-top: 11px;
  display: grid;
  grid-template-columns: repeat(3, 11fr);
  gap: 10px;
}
.loyalty__info-line h3 {
  font-weight: 500;
  font-size: clamp(0.813rem, 0.695rem + 0.59vw, 1.125rem);
  line-height: 104%;
  color: #fff;
}
.loyalty__title p {
  margin-top: 5px;
  font-weight: 500;
  font-size: clamp(0.625rem, 0.531rem + 0.47vw, 0.875rem);
  line-height: 104%;
  color: #fff;
}
.loyalty__cards {
  margin-top: clamp(3.063rem, 2.85rem + 1.06vw, 3.625rem);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
@media (max-width: 850px) {
  .loyalty__cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
.loyalty__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 850px) {
  .loyalty__card {
    align-items: start;
  }
}
.loyalty__card h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 104%;
  color: var(--black);
}
.loyalty__card p {
  height: 100%;
  margin-top: 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: var(--black);
  padding: 20px 32px 20px 20px;
  background-color: var(--loyaltyCard);
}

.news {
  margin-top: 50px;
}
.news h1 {
  font-weight: 700;
  font-size: clamp(1.5rem, 1.288rem + 1.06vw, 2.063rem);
  line-height: 104%;
  color: var(--darkGreen);
}
.news .shares {
  margin-top: 23px;
}
.news__top {
  display: none;
}
.news__filters {
  margin-top: clamp(1.25rem, 0.708rem + 2.71vw, 2.688rem);
  padding: 30px 18px;
  border-radius: 15px;
  background-color: var(--backNews);
}
.news__filters h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 104%;
  color: var(--titleFilter);
}
.news__buttons {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 750px) {
  .news__buttons {
    grid-template-columns: repeat(2, 1fr);
  }
}
.news__buttons button {
  display: block;
  padding: 23px 10px;
  border: 1px solid #e1e1e1;
  background-color: var(--buttonNews);
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  color: var(--black);
}
@media (max-width: 750px) {
  .news__buttons button:nth-child(3) {
    grid-column: 1/3;
  }
}
.news__cards {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
@media (max-width: 850px) {
  .news__cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.news__card {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.news__img {
  position: relative;
}
.news__img img {
  display: block;
}
.news__img span {
  position: absolute;
  bottom: 15px;
  right: clamp(1.125rem, 0.842rem + 1.42vw, 1.875rem);
  border-radius: 100px;
  font-weight: 500;
  font-size: 13px;
  line-height: 104%;
  color: #000;
  background: #fff;
  padding: 6px 16px;
}
.news__info {
  width: calc(100% - clamp(1.25rem, -11.447rem + 23.9vw, 6rem));
  background: var(--buttonNews);
  border-radius: 0 0 15px 15px;
}
@media (max-width: 850px) {
  .news__info {
    background: transparent;
    width: 100%;
  }
}
.news__info span {
  display: block;
  width: fit-content;
  position: relative;
  font-weight: 500;
  font-size: clamp(0.625rem, 0.507rem + 0.59vw, 0.938rem);
  line-height: 104%;
  color: #fff;
  background: #fe8b2c;
  border-radius: 10px;
  padding: 7px 20px;
  margin-top: -21px;
  margin-left: 31px;
}
.news__info h3 {
  margin-top: clamp(0.688rem, 0.287rem + 2vw, 1.75rem);
  font-weight: 500;
  font-size: clamp(0.875rem, 0.639rem + 1.18vw, 1.5rem);
  line-height: 104%;
  color: var(--darkBlue);
  padding: 0 37px 15px;
}
.news__form {
  margin-top: clamp(1.75rem, 1.255rem + 2.48vw, 3.063rem);
}
.news .activeButton {
  border: 1px solid #f9d359;
}

.found__content {
  display: flex;
  padding-left: calc((100% - 1168px) / 2);
}
@media (max-width: 750px) {
  .found__content {
    padding-left: 0;
    flex-direction: column;
    align-items: center;
  }
}
.found__content img {
  width: calc(100% - clamp(20rem, 9.234rem + 22.97vw, 26rem));
  object-fit: cover;
}
@media (max-width: 750px) {
  .found__content img {
    margin-top: 30px;
    min-width: 762px;
    width: 100%;
    object-position: center;
  }
}
.found__info {
  padding-left: 15px;
  width: clamp(20rem, 9.234rem + 22.97vw, 26rem);
  flex-shrink: 0;
  margin-top: 28px;
}
@media (max-width: 750px) {
  .found__info {
    width: 100%;
    padding: 0 15px;
  }
}
.found__info h1 {
  margin-top: 20px;
  font-weight: 700;
  font-size: 32px;
  line-height: 104%;
  color: var(--darkGreen);
}
.found__info p {
  margin-top: 20px;
  max-width: 246px;
  font-weight: 500;
  font-size: 14px;
  line-height: 104%;
  color: var(--black);
}
.found__top {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.found__top li:not(:last-child) a::after {
  content: "-";
  display: block;
}
.found__top a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 104%;
  color: #969fa6;
}
.found__content-b {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media (max-width: 1000px) {
  .found__content-b {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 850px) {
  .found__content-b {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 550px) {
  .found__content-b {
    grid-template-columns: repeat(1, 1fr);
  }
}

.notfound__content {
  display: flex;
  padding-left: calc((100% - 1168px) / 2);
}
@media (max-width: 750px) {
  .notfound__content {
    padding-left: 0;
    flex-direction: column;
    align-items: center;
  }
}
.notfound__content img {
  width: calc(100% - clamp(23.188rem, 11.973rem + 23.92vw, 29.438rem));
  object-fit: cover;
}
@media (max-width: 750px) {
  .notfound__content img {
    margin-top: 30px;
    min-width: 762px;
    width: 100%;
    object-position: center;
  }
}
.notfound__info {
  padding-left: 15px;
  width: clamp(23.188rem, 11.973rem + 23.92vw, 29.438rem);
  flex-shrink: 0;
  margin-top: 28px;
}
@media (max-width: 750px) {
  .notfound__info {
    width: 100%;
    padding: 0 15px;
  }
}
.notfound__info h1 {
  margin-top: 20px;
  font-weight: 700;
  font-size: 32px;
  line-height: 104%;
  color: var(--darkGreen);
}
.notfound__info p {
  margin-top: 20px;
  max-width: 264px;
  font-weight: 500;
  font-size: 18px;
  line-height: 104%;
  color: var(--black);
}
.notfound__top {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.notfound__top li:not(:last-child) a::after {
  content: "-";
  display: block;
}
.notfound__top a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 104%;
  color: #969fa6;
}
.notfound__href {
  display: block;
  margin-top: clamp(0.938rem, 0.631rem + 1.53vw, 1.75rem);
  border-radius: 5px;
  padding: 12px 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 104%;
  text-align: center;
  color: #fff;
  background-color: #006a61;
  width: fit-content;
  transition: all 0.3s ease-in-out;
}
.notfound__href:hover {
  background-color: #109a8e;
}

.allegiance__banner {
  margin: 0 auto;
  background: url("../img/program.png") no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 1200px;
  width: 100%;
  height: 375px;
}
.allegiance__banner-content {
  padding-top: 53px;
}
.allegiance__banner-content h1 {
  font-weight: 700;
  font-size: clamp(2.188rem, 1.904rem + 1.42vw, 2.938rem);
  line-height: 104%;
  text-transform: uppercase;
  color: #fff;
}
.allegiance__banner-content p {
  margin-top: clamp(0.813rem, 0.695rem + 0.59vw, 1.125rem);
  font-weight: 400;
  font-size: clamp(1rem, 0.858rem + 0.71vw, 1.375rem);
  line-height: 104%;
  color: #fff;
  max-width: 400px;
}
.allegiance__balls {
  margin-top: 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.allegiance__balls h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 104%;
  color: var(--allegianceColor);
  text-align: center;
}
@media (max-width: 750px) {
  .allegiance__balls h2 {
    max-width: 298px;
  }
}
.allegiance__cards {
  margin-top: 39px;
  display: flex;
  justify-content: center;
}
@media (max-width: 950px) {
  .allegiance__cards {
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: clamp(0.125rem, -0.129rem + 1.27vw, 0.625rem);
  }
}
.allegiance__card {
  padding: 52px 32px;
}
.allegiance__card h3 {
  max-width: 275px;
  font-weight: 700;
  font-size: 24px;
  line-height: 104%;
  color: #fff;
}
.allegiance__card p {
  max-width: 275px;
  margin-top: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 104%;
  color: #fff;
}
.allegiance__card:nth-child(1) {
  background: url("../img/kashBack.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: clamp(23.75rem, 4.685rem + 32.11vw, 28.125rem);
  height: 218px;
}
.allegiance__card:nth-child(2) {
  background: url("../img/pay.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: clamp(18.125rem, -5.298rem + 39.45vw, 23.5rem);
  height: 198px;
}
.allegiance__card:nth-child(3) {
  background: url("../img/ball.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: clamp(18.125rem, -4.753rem + 38.53vw, 23.375rem);
  height: 198px;
}
@media (max-width: 950px) {
  .allegiance__card:nth-child(1) {
    max-width: 489px;
    width: 100%;
    height: 236px;
  }
  .allegiance__card:nth-child(2) {
    max-width: 482px;
    width: 100%;
    height: 253px;
  }
  .allegiance__card:nth-child(3) {
    max-width: 482px;
    width: 100%;
    height: 255px;
  }
}
.allegiance__participation {
  margin: 0 auto;
  padding: 0 clamp(1.375rem, -6.475rem + 16.75vw, 5.75rem) 0 clamp(0.75rem, 0.278rem + 2.36vw, 2rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("../img/where.png") no-repeat;
  background-size: cover;
  background-position: 30% center;
  max-width: 1162px;
  width: 100%;
  height: 194px;
}
@media (max-width: 950px) {
  .allegiance__participation {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 39px;
    height: 297px;
  }
}
.allegiance__participation a {
  padding: clamp(0.938rem, 0.867rem + 0.35vw, 1.125rem) clamp(2.313rem, 2.124rem + 0.94vw, 2.813rem);
  font-weight: 500;
  font-size: 15px;
  line-height: 104%;
  color: #000;
  background-color: #fff;
  border-radius: 10px;
}
.allegiance__text {
  display: flex;
  align-items: center;
}
.allegiance__text span {
  font-weight: 500;
  font-size: clamp(5rem, 4.693rem + 1.53vw, 5.813rem);
  line-height: 104%;
  color: #fff;
}
.allegiance__texts {
  display: flex;
  flex-direction: column;
}
.allegiance__texts span:nth-child(1) {
  font-weight: 400;
  font-size: clamp(2.188rem, 2.07rem + 0.59vw, 2.5rem);
  line-height: 104%;
  text-transform: uppercase;
  color: #fff;
}
.allegiance__texts span:nth-child(2) {
  font-weight: 500;
  font-size: clamp(1.625rem, 1.507rem + 0.59vw, 1.938rem);
  line-height: 104%;
  color: #fff;
}
.allegiance__cards-b {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 17px;
}
@media (max-width: 950px) {
  .allegiance__cards-b {
    grid-template-columns: repeat(1, 1fr);
  }
}
.allegiance__card-b {
  display: flex;
  align-items: center;
  gap: clamp(0.625rem, 0.177rem + 2.24vw, 1.813rem);
}
.allegiance__card-b:nth-child(1) {
  padding-left: 10px;
  background: url("../img/card1.png") no-repeat;
  background-size: cover;
  height: 208px;
  justify-content: end;
}
@media (max-width: 950px) {
  .allegiance__card-b:nth-child(1) {
    height: 170px;
    border-radius: 10px;
  }
}
.allegiance__card-b:nth-child(1) img {
  width: clamp(6.875rem, 5.366rem + 7.55vw, 10.875rem);
}
.allegiance__card-b:nth-child(3) {
  padding-left: 22px;
  background: url("../img/card2.png") no-repeat;
  background-size: cover;
  height: 208px;
  flex-direction: row-reverse;
  justify-content: start;
}
@media (max-width: 950px) {
  .allegiance__card-b:nth-child(3) {
    border-radius: 10px;
    height: 170px;
  }
}
.allegiance__card-b:nth-child(3) img {
  width: clamp(6.25rem, 4.717rem + 7.67vw, 10.313rem);
}
.allegiance__card-info h3 {
  font-weight: 700;
  font-size: 26px;
  line-height: 104%;
  color: #fff;
}
.allegiance__card-info p {
  margin-top: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 104%;
  color: #fff;
  max-width: 195px;
}
.allegiance__status {
  margin-top: 71px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
@media (max-width: 950px) {
  .allegiance__status {
    display: none;
  }
}
.allegiance__status img {
  height: fit-content;
}
.allegiance__status-top h5 {
  font-weight: 700;
  font-size: 32px;
  line-height: 104%;
  color: var(--textStatus);
}
.allegiance__status-top p {
  margin-top: 12px;
  font-weight: 500;
  font-size: 24px;
  line-height: 104%;
  color: var(--allegianceColor);
}
.allegiance__status-top p span {
  color: #006a61;
}
.allegiance__status-bottom {
  margin-top: clamp(1.25rem, 0.896rem + 1.77vw, 2.188rem);
}
.allegiance__status-bottom h6 {
  font-weight: 500;
  line-height: 104%;
  font-size: 32px;
  color: #006a61;
}
.allegiance__status-bottom p {
  font-weight: 500;
  font-size: 24px;
  line-height: 104%;
  color: var(--allegianceColor);
}
.allegiance__status-bottom p span {
  color: #006a61;
}
.allegiance__left {
  text-align: right;
}
.allegiance__right {
  text-align: left;
}
.allegiance__right h5 {
  color: #ffb023;
}
.allegiance__right h6 {
  color: #ffb023;
}
.allegiance__right p span {
  color: #ffb023;
}
.allegiance__mob {
  display: none;
}
@media (max-width: 950px) {
  .allegiance__mob {
    display: block;
    text-align: left;
  }
}

.outlet {
  margin-top: 28px;
}
.outlet h1 {
  margin-top: 20px;
  font-weight: 500;
  font-size: 32px;
  line-height: 104%;
  color: var(--titleoutlet);
}
.outlet__banner {
  background: url("../img/outlet.png") no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 1131px;
  width: 100%;
  height: 398px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px auto 0;
  padding: 29px;
}
.outlet__banner h2 {
  font-weight: 700;
  font-size: 47px;
  line-height: 104%;
  text-transform: uppercase;
  color: #fff;
}
.outlet__banner p {
  margin-top: 18px;
  font-weight: 400;
  font-size: 22px;
  line-height: 104%;
  color: #fff;
  max-width: 690px;
}

.delivery__banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: clamp(0.938rem, -0.064rem + 2.91vw, 2.063rem);
  background: url("../img/delivery.png") no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 1200px;
  width: 100%;
  height: 375px;
}
.delivery__banner h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 104%;
  color: #fff;
}
.delivery__banner p {
  margin-top: 15px;
  font-weight: 400;
  font-size: 20px;
  line-height: 104%;
  color: #fff;
  max-width: clamp(20.375rem, 2.242rem + 52.75vw, 40.75rem);
}
.delivery__content p {
  margin-top: 49px;
  max-width: 971px;
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: var(--black);
}
@media (max-width: 850px) {
  .delivery__content p {
    margin-top: 30px;
  }
}
.delivery__tabs-content {
  display: flex;
  flex-direction: column;
}
@media (max-width: 850px) {
  .delivery__tabs-content {
    margin-top: 30px;
    gap: 10px;
  }
}
.delivery__tab-content-block button {
  display: none;
}
@media (max-width: 850px) {
  .delivery__tab-content-block button {
    display: flex;
    width: 100%;
  }
}
.delivery__way {
  margin-top: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.delivery__way h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 104%;
  color: var(--allegianceColor);
}
.delivery__buttons {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 9px;
}
@media (max-width: 850px) {
  .delivery__buttons {
    display: none;
  }
}
.delivery__button-tab {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 17px;
  padding: 7px;
  text-align: start;
  border: 1px solid #626262;
  background: #fff;
  border-radius: 10px;
  height: 102px;
  color: var(--colorPay);
  font-weight: 400;
  font-size: 16px;
  line-height: 104%;
}
.delivery__price {
  margin-top: 30px;
}
.delivery__price h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 104%;
  color: var(--allegianceColor);
}
@media (max-width: 420px) {
  .delivery__table-block {
    overflow: auto;
    width: 100%;
  }
}
.delivery__table {
  margin-top: 30px;
  border-collapse: collapse;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  width: 100%;
}
@media (max-width: 420px) {
  .delivery__table {
    width: 600px;
  }
}
.delivery__table tr {
  transition: all 0.2s ease-in-out;
}
.delivery__table tr:nth-child(1) {
  background-color: #ebd6b5;
}
.delivery__table tr:nth-child(1) th {
  padding: 11px clamp(0.313rem, -0.522rem + 2.43vw, 1.25rem);
  border: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 104%;
  color: #6b6a67;
}
.delivery__table tr:hover .delivery__white {
  background-color: #ddeeed;
}
.delivery__table tr:hover .delivery__green {
  background-color: #69c36a;
}
.delivery__table tr:hover .delivery__yellow {
  background-color: #dfd980;
}
.delivery__table tr:hover .delivery__gray {
  background-color: #b5c8c1;
}
.delivery__table tr td, .delivery__table tr th {
  padding: 12px clamp(0.313rem, -0.522rem + 2.43vw, 1.25rem);
  border: 1px solid #7c7c7c;
  font-weight: 500;
  font-size: 15px;
  line-height: 104%;
  color: #000;
}
.delivery__white {
  background-color: white;
  transition: all 0.2s ease-in-out;
}
.delivery__green {
  background-color: #7bcd67;
  transition: all 0.2s ease-in-out;
}
.delivery__yellow {
  background-color: #ffe782;
  transition: all 0.2s ease-in-out;
}
.delivery__gray {
  transition: all 0.2s ease-in-out;
  background-color: #d1d4cd;
}
.delivery__map {
  margin-top: 29px;
}
@media (max-width: 1100px) {
  .delivery__map {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}
.delivery__pay-p {
  margin-top: 30px;
}
.delivery__pay-p h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 104%;
  color: var(--allegianceColor);
}
.delivery__pay-block {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 28px;
}
@media (max-width: 850px) {
  .delivery__pay-block {
    grid-template-columns: repeat(1, 1fr);
  }
}
.delivery__pay {
  display: flex;
  align-items: center;
  gap: 30px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: var(--allegianceColor);
  padding: 23px 20px;
  border: 1px solid var(--payBack);
  background-color: var(--payB);
}
.delivery__pay img:nth-child(2) {
  display: none;
}
.delivery .activeTab {
  border: 2px solid #006a61;
}
.delivery .hiddenTab {
  display: none;
}

.cart {
  position: relative;
}
.cart__add-button {
  position: absolute;
  right: 0;
  top: 37%;
  background: url("../img/basket.png") no-repeat;
  background-size: cover;
  min-width: 122px;
  min-height: 178px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}
@media (max-width: 1000px) {
  .cart__add-button {
    display: none;
  }
}
.cart__add-button h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 104%;
  color: #fff;
}
.cart__add-button span {
  margin-top: 10px;
  font-weight: 500;
  font-size: 43px;
  line-height: 60%;
  color: #fff;
}
.cart__href {
  margin-top: 28px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.cart__href > li {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 104%;
  color: #969fa6;
}
.cart__href > li > a {
  font-weight: 400;
  font-size: 12px;
  line-height: 104%;
  color: #969fa6;
}
.cart__href > li:not(:last-child)::after {
  content: "-";
  display: block;
}
.cart__href > li:not(:last-child, :first-child) > a {
  display: flex;
  align-items: center;
  gap: 5px;
}
.cart__href > li:not(:last-child, :first-child) > a::after {
  content: "";
  display: block;
  background: url("../img/svg/arr.svg") no-repeat;
  background-size: contain;
  width: 11px;
  height: 6px;
  transform: rotate(-90deg);
  transition: all 0.3s ease-in-out;
}
.cart__href > li:not(:last-child, :first-child):hover > a::after {
  transform: rotate(0);
}
.cart__href > li:not(:last-child, :first-child):hover .cart__menu {
  opacity: 1;
  visibility: visible;
  z-index: 3;
  transform: translateX(-50%) translateY(0);
}
.cart__menu {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%) translateY(30px);
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: var(--tagColor);
  padding: 10px 20px;
  min-width: 150px;
  width: 100%;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
}
.cart__menu li {
  width: 100%;
}
.cart__menu a {
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 104%;
  color: var(--tagText);
}
.cart__top {
  margin-top: 20px;
}
.cart__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.cart__title h1 {
  font-weight: 500;
  font-size: clamp(1.5rem, 1.406rem + 0.47vw, 1.75rem);
  line-height: 104%;
  color: var(--allegianceColor);
  max-width: 685px;
}
.cart__favorite {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 104%;
  color: #9f9ea3;
}
.cart__favorite svg {
  fill: #9F9EA3;
}
@media (max-width: 750px) {
  .cart__favorite {
    display: none;
  }
}
.cart__top-rating {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.cart__top-rating a:nth-child(2) {
  font-weight: 500;
  font-size: 12px;
  line-height: 104%;
  color: #c0c0c0;
}
.cart__top-rating a:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 104%;
  color: #009afc;
}
.cart__top-star {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 104%;
  color: #525252;
}
.cart__main {
  margin-top: clamp(0.875rem, 0.45rem + 2.12vw, 2rem);
  display: flex;
}
.cart__main > img {
  max-width: clamp(21.063rem, -6.174rem + 45.87vw, 27.313rem);
  width: 100%;
  height: 400px;
  object-fit: cover;
}
@media (max-width: 950px) {
  .cart__main > img {
    max-width: 460px;
    margin: 0 auto;
  }
}
@media (max-width: 950px) {
  .cart__main {
    flex-direction: column;
  }
}
.cart__characteristics-block {
  position: relative;
  z-index: 2;
  margin-left: clamp(0.625rem, -4.822rem + 9.17vw, 1.875rem);
}
.cart__characteristics-block h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 104%;
  color: var(--black);
}
@media (max-width: 950px) {
  .cart__characteristics-block {
    display: none;
  }
}
.cart__characteristics {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cart__item {
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 98%;
  color: var(--dd);
}
.cart__item dd {
  margin: 0;
}
.cart__manufacturer {
  margin-top: 20px;
}
.cart__manufacturer h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: var(--black);
}
.cart__manufacturer a {
  margin-top: 10px;
  display: block;
  width: fit-content;
}
.cart__info-product {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(clamp(0rem, -19.065rem + 32.11vw, 4.375rem), -50px);
}
@media (max-width: 950px) {
  .cart__info-product {
    height: 466px;
    transform: translate(0, -30px);
  }
}
.cart__info-product::after {
  content: "";
  position: absolute;
  top: 0;
  background: url("../img/dec-card.png") no-repeat;
  background-size: cover;
  width: 453px;
  height: 526px;
  z-index: -1;
}
.cart__main-info {
  transform: translate(10px, 40px);
  width: clamp(17.5rem, 10.419rem + 11.93vw, 19.125rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cart__main-info > p {
  margin-top: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 104%;
  color: #636363;
  text-align: center;
}
.cart__info-p, .cart__weight, .cart__basket, .cart__balls {
  width: 100%;
}
.cart__info-p h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 104%;
  color: #363636;
}
.cart__info-p p {
  margin-top: 5px;
  font-weight: 700;
  font-size: 45px;
  line-height: 104%;
  color: #363636;
}
.cart__info-p p span:nth-child(1) {
  font-weight: 400;
}
.cart__info-p p span:nth-child(2) {
  font-weight: 400;
  font-size: 32px;
  text-decoration: line-through;
  color: #006a61;
}
.cart__weight {
  margin-top: 18px;
}
.cart__weight h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 104%;
  color: #363636;
}
.cart__buttons-top {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.cart__buttons-top button {
  border: 1px solid #525252;
  border-radius: 10px;
  max-width: 60px;
  width: 100%;
  height: 35px;
  background-color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: #525252;
}
.cart__basket {
  user-select: none;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 10px;
  background-color: #00857b;
  font-weight: 700;
  font-size: 20px;
  line-height: 104%;
  color: #fff;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.cart__basket:hover {
  background-color: #109a8e;
}
.cart__counter {
  border: 1px solid #525252;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  max-width: 85px;
  width: 100%;
  height: 43px;
  justify-content: center;
  align-items: center;
}
.cart__counter span {
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: #525252;
  min-width: 17px;
  text-align: center;
}
.cart__counter button {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 104%;
  color: #00857b;
}
.cart__balls {
  margin-top: 10px;
  position: relative;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px 0;
  background-color: #636363;
  font-weight: 500;
  font-size: 12px;
  line-height: 104%;
  color: #fff;
}
.cart__balls button:hover + .cart__ball-die {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.cart__ball-die {
  width: 200px;
  position: absolute;
  left: 0;
  bottom: 30px;
  z-index: 3;
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: #012321;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  transform: translateY(-10px);
  opacity: 0;
  visibility: hidden;
}
.cart__description {
  margin-top: clamp(1.875rem, 1.38rem + 2.48vw, 3.188rem);
}
.cart__description h2 {
  font-weight: 700;
  font-size: clamp(1.5rem, 1.311rem + 0.94vw, 2rem);
  line-height: 104%;
  color: var(--darkGreen);
}
.cart__description p {
  margin-top: clamp(1.25rem, 1.014rem + 1.18vw, 1.875rem);
  max-width: 971px;
  font-weight: 500;
  font-size: 18px;
  line-height: 104%;
  color: var(--allegianceColor);
}
.cart__description button {
  display: none;
  margin-top: 10px;
  font-size: 18px;
  line-height: 104%;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: #006a61;
}
@media (max-width: 850px) {
  .cart__description button {
    display: block;
  }
}
.cart__specifications {
  margin-top: clamp(1.875rem, 1.403rem + 2.36vw, 3.125rem);
}
.cart__specifications h2 {
  font-weight: 700;
  font-size: clamp(1.5rem, 1.311rem + 0.94vw, 2rem);
  line-height: 104%;
  color: var(--darkGreen);
}
.cart__specifications-block {
  margin-top: clamp(1.25rem, 1.014rem + 1.18vw, 1.875rem);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 11px;
}
@media (max-width: 850px) {
  .cart__specifications-block {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}
.cart__specifications-info {
  padding: 30px 20px 19px;
  position: relative;
}
.cart__specifications-info::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: var(--backCard);
  transform: skewX(2deg);
  border-radius: 20px;
}
@media (max-width: 850px) {
  .cart__specifications-info::after {
    transform: skewX(5deg);
  }
}
.cart__specifications-info dl {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cart__specifications-item {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
}
.cart__specifications-item dt {
  font-weight: 400;
  font-size: 16px;
  line-height: 98%;
  color: var(--black);
}
.cart__specifications-item dd {
  font-weight: 400;
  font-size: 16px;
  line-height: 98%;
  color: var(--colorCart);
}
.cart__reviews {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 2.4fr 1fr;
  gap: 27px;
}
@media (max-width: 850px) {
  .cart__reviews {
    grid-template-columns: repeat(1, 1fr);
  }
}
.cart__title-reviews {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media (max-width: 850px) {
  .cart__title-reviews {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.cart__title-reviews h2 {
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 700;
  font-size: clamp(1.5rem, 1.311rem + 0.94vw, 2rem);
  line-height: 104%;
  color: var(--darkGreen);
}
.cart__title-reviews h2 span {
  font-weight: 500;
  font-size: 14px;
  line-height: 104%;
  color: #525252;
}
.cart__title-reviews button {
  width: fit-content;
  padding: 17px 37px;
  border-radius: 10px;
  background-color: #006a61;
  font-weight: 500;
  font-size: 15px;
  line-height: 104%;
  text-align: center;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.cart__title-reviews button:hover {
  background-color: #109a8e;
}
.cart__reviews-block {
  margin-top: clamp(1rem, 0.599rem + 2vw, 2.063rem);
}
.cart__reviews-comments {
  margin-top: clamp(1.5rem, 0.887rem + 3.07vw, 3.125rem);
}
.cart__reviews-comment {
  position: relative;
}
.cart__reviews-comment p {
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: var(--colorReviews);
  max-width: 720px;
}
.cart__reviews-comment:not(:last-child) {
  padding-bottom: 20px;
}
.cart__reviews-comment:not(:first-child) {
  padding-top: 20px;
}
.cart__reviews-comment:not(:first-child)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  max-width: 540px;
  width: 100%;
  height: 1px;
  background-color: #9f9ea3;
}
@media (max-width: 850px) {
  .cart__reviews-comment:not(:first-child)::after {
    max-width: 100%;
  }
}
.cart__user h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  color: var(--black);
}
.cart__right-add {
  margin-top: clamp(0rem, -10.859rem + 20.44vw, 4.063rem);
}
.cart__ratings {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;
}
.cart__ratings::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 540px;
  width: 100%;
  height: 1px;
  background-color: #9f9ea3;
}
@media (max-width: 850px) {
  .cart__ratings {
    padding: 31px 0 20px;
  }
  .cart__ratings::after {
    max-width: 100%;
  }
  .cart__ratings::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: 1px;
    background-color: #9f9ea3;
  }
}
.cart__rating-product {
  display: flex;
  align-items: center;
  gap: 4px;
}
.cart__ratings {
  font-weight: 700;
  font-size: 22px;
  line-height: 104%;
  color: #000;
}
.cart__grades {
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cart__grade {
  display: grid;
  grid-template-columns: 54px auto;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 104%;
  color: var(--colorAdd);
}
.cart__grade:nth-child(1) .cart__grade-line span {
  width: 36%;
}
.cart__grade:nth-child(2) .cart__grade-line span {
  width: 25%;
}
.cart__grade:nth-child(3) .cart__grade-line span {
  width: 8%;
}
.cart__grade-line {
  position: relative;
  max-width: 228px;
  width: 100%;
  height: 7px;
  background-color: var(--line);
  border-radius: 100px;
  overflow: hidden;
}
.cart__grade-line span {
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #006a61;
  border-radius: 100px;
}
.cart__review-append {
  margin-top: clamp(1.938rem, 1.702rem + 1.18vw, 2.563rem);
}
.cart__review-append h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 104%;
  color: var(--darkGreen);
}
.cart__review-append form {
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 300px;
  width: 100%;
}
@media (max-width: 850px) {
  .cart__review-append form {
    gap: 30px;
  }
}
.cart__review-append form label {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.cart__review-append form label span {
  font-weight: 700;
  font-size: 16px;
  line-height: 104%;
  color: var(--darkGreen);
}
.cart__review-append form label input {
  border-radius: 10px;
  padding: 12px;
  border: 1px solid #9f9ea3;
  background-color: var(--white);
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: var(--black);
}
.cart__review-append form label textarea {
  resize: none;
  width: 100%;
  height: 82px;
  border-radius: 10px;
  padding: 12px;
  border: 1px solid #9f9ea3;
  background-color: var(--white);
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: var(--black);
}
.cart__review-append form button {
  border-radius: 10px;
  background-color: #00746a;
  font-weight: 500;
  font-size: 15px;
  line-height: 104%;
  text-align: center;
  color: #fff;
  padding: 13px 21px;
  width: fit-content;
  transition: all 0.3s ease-in-out;
}
.cart__review-append form button:hover {
  background-color: #109a8e;
}
.cart__review-append-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.cart__review-append-rating span {
  font-weight: 700;
  font-size: 16px;
  line-height: 104%;
  color: var(--darkGreen);
}
.cart__review-append-stars {
  display: flex;
  align-items: center;
}
.cart__review-append-stars svg {
  cursor: pointer;
  fill: #d8d8d8;
  transition: all 0.2s ease-in-out;
  width: fit-content;
}
.cart__review-append-stars svg:not(:last-child) {
  padding-right: 10px;
}
.cart__similar {
  margin-top: clamp(1.813rem, 1.081rem + 3.66vw, 3.75rem);
}
.cart__similar h2 {
  font-weight: 700;
  font-size: clamp(1.5rem, 1.311rem + 0.94vw, 2rem);
  line-height: 104%;
  color: var(--darkGreen);
}
.cart__similar-cards {
  margin-top: clamp(1.25rem, 1.014rem + 1.18vw, 1.875rem);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}
@media (max-width: 1000px) {
  .cart__similar-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 850px) {
  .cart__similar-cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .cart__similar-cards .card-product:not(:nth-child(-n+2)) {
    display: none;
  }
}
@media (max-width: 550px) {
  .cart__similar-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
.cart .highlighted {
  fill: #006a61;
}
.cart .activeFavirite {
  color: red;
}
.cart .activeFavirite svg {
  fill: red;
}
.cart .activeWeight {
  border: 1px solid #ff7306;
  color: #ff7306;
}

.contacts {
  margin-top: 50px;
}
.contacts h1 {
  font-weight: 500;
  font-size: 32px;
  line-height: 104%;
  color: var(--titleoutlet);
}
.contacts__main {
  margin-top: clamp(1.063rem, 0.284rem + 3.89vw, 3.125rem);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
@media (max-width: 850px) {
  .contacts__main {
    grid-template-columns: repeat(1, 1fr);
    gap: 27px;
  }
}
.contacts__ofice, .contacts__warehouse {
  display: flex;
  gap: clamp(0.375rem, 0.045rem + 1.65vw, 1.25rem);
}
.contacts__ofice img, .contacts__warehouse img {
  display: block;
  height: fit-content;
}
.contacts__ofice img:nth-child(2), .contacts__warehouse img:nth-child(2) {
  display: none;
}
.contacts__info p {
  font-weight: 500;
  font-size: 18px;
  line-height: 104%;
  color: var(--allegianceColor);
}
.contacts__info p span {
  font-weight: 700;
}
.contacts__info a {
  display: block;
  margin-top: 9px;
  font-weight: 500;
  font-size: 18px;
  line-height: 104%;
  color: var(--allegianceColor);
}
.contacts__content .contacts__top {
  margin-top: 20px;
}
.contacts__map {
  margin-top: 30px;
}

.about__banner {
  position: relative;
  background: url("../img/about.png") no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 1200px;
  height: 375px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: clamp(0.938rem, -2.07rem + 5.66vw, 2.063rem);
  z-index: 2;
}
.about__banner h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 104%;
  color: #fff;
}
.about__banner p {
  margin-top: 15px;
  font-weight: 400;
  font-size: 20px;
  line-height: 104%;
  color: #fff;
  max-width: 652px;
}
.about__banner img {
  position: absolute;
  right: clamp(1.313rem, -7.04rem + 15.72vw, 4.438rem);
  bottom: clamp(1.188rem, -0.483rem + 3.14vw, 1.813rem);
  z-index: -1;
}
.about__info {
  margin-top: clamp(1.875rem, 1.333rem + 2.71vw, 3.313rem);
}
.about__info h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 104%;
  text-align: center;
  color: var(--allegianceColor);
}
.about__info p {
  margin-top: clamp(2.563rem, 2.327rem + 1.18vw, 3.188rem);
  font-weight: 500;
  font-size: 16px;
  line-height: 132%;
  color: var(--allegianceColor);
}
.about__flex {
  margin-top: 42px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 10px;
}
@media (max-width: 850px) {
  .about__flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 560px) {
  .about__flex {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
}
.about__company {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
}
.about__company:nth-child(1) {
  gap: 21px;
}
.about__company:nth-child(3) {
  gap: 34px;
}
@media (max-width: 850px) {
  .about__company:nth-child(3) {
    display: none;
  }
}
.about__company img {
  display: block;
}
.about__company p {
  margin-top: auto;
  font-weight: 500;
  font-size: clamp(1rem, 0.953rem + 0.24vw, 1.125rem);
  line-height: 104%;
  color: var(--aboutLogo);
}
.about__group {
  margin-top: clamp(2.188rem, 1.834rem + 1.77vw, 3.125rem);
}
.about__group h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 104%;
  text-align: center;
  color: var(--allegianceColor);
}
.about__company-group {
  margin-top: clamp(0.938rem, 0.348rem + 2.95vw, 2.5rem);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 62px;
  align-items: center;
}
@media (max-width: 850px) {
  .about__company-group {
    grid-template-columns: repeat(4, 1fr);
  }
  .about__company-group img:nth-child(+n+5) {
    display: none;
  }
}
@media (max-width: 550px) {
  .about__company-group {
    grid-template-columns: repeat(2, 1fr);
  }
  .about__company-group img:nth-child(+n+3) {
    display: none;
  }
}

.placement {
  margin-top: 50px;
}
.placement h1 {
  font-weight: 500;
  font-size: 32px;
  line-height: 104%;
  color: var(--titlePlacement);
}
.placement__main-content {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1.9fr 1fr;
  gap: clamp(1.25rem, -7.771rem + 16.98vw, 4.625rem);
}
@media (max-width: 850px) {
  .placement__main-content {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }
}
.placement__vhod-block {
  position: relative;
  height: 213px;
}
@media (max-width: 850px) {
  .placement__vhod-block {
    display: none;
  }
}
.placement__vhod-block::after {
  content: "";
  position: absolute;
  top: 0;
  left: -35px;
  background: url("../img/vhod.png") no-repeat;
  background-size: cover;
  width: 792px;
  height: 213px;
  z-index: -1;
}
@media (max-width: 850px) {
  .placement__vhod-block::after {
    background-position: center;
    max-width: 792px;
    left: inherit;
  }
}
.placement__mob {
  display: none;
}
@media (max-width: 850px) {
  .placement__mob {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.placement__vhod {
  display: flex;
  flex-direction: column;
  gap: 17px;
  height: 100%;
  width: fit-content;
  padding: 47px 0 0 30px;
}
@media (max-width: 850px) {
  .placement__vhod {
    padding: 47px 0 0;
  }
}
.placement__vhod h2 {
  font-weight: 700;
  font-size: clamp(1.375rem, 1.139rem + 1.18vw, 2rem);
  line-height: 104%;
  color: #fff;
}
.placement__flex {
  display: flex;
  align-items: center;
  gap: clamp(0.313rem, -0.277rem + 2.95vw, 1.875rem);
}
.placement__flex a {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 10px clamp(1.063rem, 0.355rem + 3.54vw, 2.938rem);
  border-radius: 100px;
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 104%;
  text-align: center;
  color: #000;
}
.placement__flex p {
  max-width: 412px;
  font-weight: 400;
  font-size: clamp(0.813rem, 0.742rem + 0.35vw, 1rem);
  line-height: 104%;
  color: #fff;
}
@media (max-width: 850px) {
  .placement__left {
    order: 2;
  }
}
.placement__form {
  margin-top: 53px;
}
.placement__form h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 104%;
  color: var(--black);
}
.placement__form h3 {
  margin-top: 30px;
  font-weight: 700;
  font-size: 20px;
  line-height: 104%;
  color: var(--allegianceColor);
}
.placement__inps-top {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: "one one two two three three" "four four four five five five" "six six six six six six";
  gap: 20px;
}
@media (max-width: 850px) {
  .placement__inps-top {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "one one" "two two" "three three" "four five" "six six";
  }
}
.placement__inps-top input {
  display: block;
  border: 1px solid var(--activePanel);
  border-radius: 5px;
  background: var(--filterBack);
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: var(--logoText);
  padding: 10px 20px;
  width: 100%;
}
.placement__inps-top input:nth-child(1) {
  grid-area: one;
}
.placement__inps-top input:nth-child(2) {
  grid-area: two;
}
.placement__inps-top input:nth-child(3) {
  grid-area: three;
}
.placement__inps-top input:nth-child(4) {
  grid-area: four;
}
.placement__inps-top input:nth-child(5) {
  grid-area: five;
}
.placement__inps-top input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: var(--logoText);
}
.placement__inps-top textarea {
  grid-area: six;
  resize: none;
  display: block;
  height: 88px;
  border: 1px solid var(--activePanel);
  border-radius: 5px;
  background: var(--filterBack);
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: var(--logoText);
  padding: 10px 20px;
  width: 100%;
}
.placement__inps-top textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: var(--logoText);
}
.placement__inps-bottom {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.placement__inps-bottom input {
  display: block;
  border: 1px solid var(--activePanel);
  border-radius: 5px;
  background: var(--filterBack);
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: var(--logoText);
  padding: 10px 20px;
  width: 100%;
}
.placement__inps-bottom input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: var(--logoText);
}
.placement__buttons {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 43px;
}
@media (max-width: 850px) {
  .placement__buttons {
    grid-template-columns: repeat(1, 1fr);
  }
}
.placement__pay h4, .placement__delivery h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 104%;
  color: var(--allegianceColor);
}
.placement__buttons-click {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.placement__buttons-click button {
  border: 1px solid var(--activePanel);
  border-radius: 5px;
  background: var(--filterBack);
  color: var(--logoText);
  text-align: start;
  font-weight: 500;
  font-size: 16px;
  line-height: 104%;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 20px;
}
@media (max-width: 850px) {
  .placement__buttons-click button {
    max-width: 100%;
  }
}
.placement__buttons-click button img:nth-child(2) {
  display: none;
}
.placement__right {
  margin-top: 12px;
  padding: 20px 24px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  background: var(--orderPlacement);
  border-radius: 10px;
  height: fit-content;
}
.placement__right button {
  font-weight: 700;
  font-size: 16px;
  line-height: 104%;
  color: #fff;
  padding: 16px 0;
  width: 100%;
  background-color: #006a61;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.placement__right button:hover {
  background-color: #109a8e;
}
.placement__right p {
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 104%;
  color: var(--black);
  max-width: 243px;
}
.placement__unavailable {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 104%;
  color: var(--black);
  border-radius: 10px;
  background-color: var(--backPlacement);
  padding: 7px 20px;
}
.placement__unavailable svg {
  fill: var(--svgPlacement);
}
.placement__basket {
  position: relative;
  margin-top: 30px;
  padding-top: 30px;
}
.placement__basket::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--darkGreen);
}
.placement__top-basket {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.placement__top-basket h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 104%;
  color: var(--black);
}
.placement__top-basket p {
  font-weight: 400;
  font-size: 13px;
  line-height: 104%;
  color: var(--black);
}
.placement__product {
  padding-top: 10px;
}
.placement__product, .placement__discoint {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.placement__product span, .placement__discoint span {
  font-weight: 400;
  font-size: 15px;
  line-height: 104%;
  color: var(--black);
}
.placement__product p, .placement__discoint p {
  font-weight: 400;
  font-size: 15px;
  line-height: 104%;
  color: var(--black);
}
.placement__discoint {
  margin-top: 5px;
}
.placement__discoint p {
  color: #f30c01;
}
.placement__total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 20px;
  padding-top: 20px;
}
.placement__total::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--darkGreen);
}
.placement__total span {
  font-weight: 500;
  font-size: 18px;
  line-height: 104%;
  color: var(--black);
}
.placement__total p {
  font-weight: 700;
  font-size: 16px;
  line-height: 104%;
  color: var(--black);
}
.placement .activeButtonPlacement {
  border: 1px solid #ff7306;
}

.noPage {
  display: block;
  background: url("../img/404.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 477px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.noPage__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.noPage p {
  font-weight: 500;
  font-size: 25px;
  line-height: 104%;
  color: #fff;
}
.noPage p:nth-child(3) {
  max-width: 394px;
  font-weight: 500;
  font-size: 23px;
  line-height: 104%;
  text-align: center;
  color: #fff;
}
.noPage p:nth-child(3) a {
  text-decoration: underline;
  font-weight: 500;
  font-size: 23px;
  line-height: 104%;
  text-align: center;
  color: #fff;
}
.noPage h1 {
  font-weight: 500;
  font-size: 180px;
  line-height: 104%;
  color: #fff;
}
.noPage form {
  margin-top: 36px;
  display: flex;
  gap: 11px;
  max-width: 392px;
  width: 100%;
}
.noPage form input {
  padding: 14px 27px;
  border-radius: 12px;
  background-color: #fff;
  font-weight: 500;
  font-size: 13px;
  line-height: 104%;
  color: #525252;
  border: 0;
  max-width: 231px;
  width: 100%;
}
.noPage form button {
  border-radius: 12px;
  background-color: #01847c;
  font-weight: 500;
  font-size: 13px;
  line-height: 104%;
  color: #fff;
  padding: 14px 33px;
  transition: all 0.3s ease-in-out;
}
.noPage form button:hover {
  background-color: #109a8e;
}