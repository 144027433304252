.shares {
  margin-top: clamp(3.125rem, calc(2.606rem + 2.59vw), 4.5rem);

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    position: relative;
    z-index: 2;

    h2 {
      font-weight: 700;
      font-size: clamp(1.5rem, calc(1.288rem + 1.06vw), 2.063rem);
      line-height: 104%;
      color: $darkGreen;
    }

  }

  &__link {
    display: flex;
    align-items: center;
    gap: 10px;

    font-weight: 500;
    font-size: 14px;
    line-height: 104%;
    text-decoration: underline;
    text-align: center;
    color: $darkGreen;

    svg {
      fill: $darkGreen;
    }
  }

  &__banner {
    position: relative;
    margin-top: clamp(1.875rem, calc(1.71rem + 0.83vw), 2.313rem);

      img {
        @media (max-width: 950px) {
          width: 100%;
        }
      }
  }

  &__info-block {
    position: absolute;
    top: 50%;
    right: 11%;
    z-index: 1;
    transform: translateY(-52%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: clamp(30.875rem, calc(29.861rem + 5.07vw), 33.563rem);

      @media (max-width: 950px) {
        position: relative;
        top: inherit;
        right: inherit;
        transform: translateY(0);
        margin-top: -70px;
      }

      @media (max-width: 570px) {
        margin-top: -60px;
      }

      &::after {
        content: '';
        position: absolute;
        background: url('../img/shares__dec.png') no-repeat;
        background-size: cover;
        width: 711px;
        height: 100%;
        z-index: -1;
      }

    span {
      padding: 7px 21px;
      font-weight: 500;
      font-size: 14px;
      line-height: 104%;
      color: #fff;
      background: #fe8b2c;
      border-radius: 16px;
    }

    h3 {
      margin-top: 21px;
      font-weight: 700;
      font-size: 32px;
      line-height: 104%;
      color: #012321;
    }

    p {
      margin-top: 5px;
      max-width: 412px;
      font-weight: 400;
      font-size: 16px;
      line-height: 104%;
      color: #000;
    }

    a {
      width: fit-content;
      margin-top: 22px;
      display: block;
      border-radius: 5px;
      background-color: #fff;
      padding: 12px 31px;
      font-weight: 500;
      font-size: 14px;
      line-height: 104%;
      color: #000;

      transition: background-color .3s ease-in-out;

        &:hover {
          background-color: #f0eeee;
        }
    }

  }

}

.interesting {
  margin-top: clamp(1.875rem, calc(1.002rem + 4.36vw), 4.188rem);

  h2 {
    font-weight: 700;
    font-size: clamp(1.5rem, calc(1.288rem + 1.06vw), 2.063rem);
    line-height: 104%;
    color: $darkGreen;
  }

  &__cards {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: clamp(0.938rem, calc(-1.568rem + 4.72vw), 1.875rem);
    grid-template-areas:
      'one two three three'
      'four four five six'
      'four four seven seven'
    ;

      @media (max-width: 850px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
        'seven seven'
        'one two'
        'three three'
        'five six'
        'four four'
        ;
      }
  }

  &__card {
    display: flex;
    flex-direction: column;

    padding: 25px 12px 0 12px;
    border-radius: 20px;
    background-color: #f5f5f5;

    overflow: hidden;

      h3 {
        max-width: 171px;
        font-weight: 700;
        font-size: 19px;
        line-height: 104%;
        color: #006a61;

          &:last-child {
            color: #fff;
          }
      }

      img {
        margin-top: 6px;
        width: fit-content;
        mix-blend-mode: multiply;

        @media (max-width: 850px) {
          margin: auto;
        }
      }

      &:nth-child(1) {
        grid-area: one;
        height: 217px;
      }

      &:nth-child(2) {
        grid-area: two;
        height: 217px;
      }

      &:nth-child(3) {
        grid-area: three;
        height: 217px;

        display: flex;
        justify-content: space-between;

          img {
            align-self: end;
            transform: translateY(-20%);
          }
      }

      &:nth-child(4) {
        grid-area: four;
        padding: 0 23px 44px 23px;
        flex-direction: column-reverse;
      }

      &:nth-child(5) {
        grid-area: five;
      }

      &:nth-child(6) {
        grid-area: six;
      }

      &:nth-child(7) {
        grid-area: seven;
        padding: 0 30px 18px 30px;
        flex-direction: column-reverse;
        height: 185px;

        background: url('../img/interesting/back.png') no-repeat;
        background-size: cover;
        background-position: center;
      }

  }

}
